import { useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { grabFirstGQLDataResult } from '../../utils/helpers';

const useInfiniteScrollQuery = (query, { variables = {}, restOptions }, limit = 9) => {
  const [fetchMoreDataLoading, setFetchMoreDataLoading] = useState(false);
  const skip = useRef(0);
  const { data, error, loading, fetchMore, refetch } = useQuery(query, {
    variables: { limit, skip: 0, ...variables },
    ...restOptions,
  });
  const fetchMoreData = async () => {
    setFetchMoreDataLoading(true);
    await fetchMore({
      variables: { limit, skip: skip.current, ...variables },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const fetchMoreResultData = grabFirstGQLDataResult(fetchMoreResult);
        const prevResultData = grabFirstGQLDataResult(prevResult);
        if (!fetchMoreResult) {
          return prevResult;
        }
        // eslint-disable-next-line no-param-reassign
        fetchMoreResult[Object.keys(fetchMoreResult)[0]].data = [...prevResultData.data, ...fetchMoreResultData.data];
        return fetchMoreResult;
      },
    });
    setFetchMoreDataLoading(false);
  };
  useEffect(() => {
    if (data && grabFirstGQLDataResult(data).data.length !== skip) {
      skip.current = grabFirstGQLDataResult(data).data.length;
    }
  }, [data]);
  useEffect(() => {
    if (variables.searchQuery) skip.current = 0;
  }, [variables.searchQuery]);
  return [grabFirstGQLDataResult(data), error, loading || fetchMoreDataLoading, refetch, fetchMoreData];
};

export default useInfiniteScrollQuery;
