import { gql } from '@apollo/client';

const actionItemCounters = gql`
  query actionItemCounters($providerId: ID!, $patientId: ID) {
    actionItemCounters(providerId: $providerId, patientId: $patientId) {
      note_approved
      note_rejected
      documenting
    }
  }
`;

export default actionItemCounters;
