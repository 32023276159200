import { gql } from '@apollo/client';

const VISIT_FOR_EDIT_FRAGMENT = gql`
  fragment visitForEdit on Visit {
    id
    visitDate
    reason
    complaint
  }
`;

export default VISIT_FOR_EDIT_FRAGMENT;
