// import MsalAuthWrapper from '../../components/auth/MsalAuthWrapper';
import ApolloAuthenticator from '../../components/auth/ApolloAuthenticator';
import MsalAuthWrapper from '../../components/auth/MsalAuthWrapper';

export default function Authenticator() {
  return (
    <MsalAuthWrapper>
      <ApolloAuthenticator />
    </MsalAuthWrapper>
  );
}
