import { gql } from '@apollo/client';

const PROVIDER_TABLE_ITEM_FRAGMENT = gql`
  fragment providerTableItem on Provider {
    id
    displayName
    totalPatients
    totalVisits
    totalActionItems
  }
`;

export default PROVIDER_TABLE_ITEM_FRAGMENT;
