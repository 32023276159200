import { confirmAlert } from '@uplab/react-confirm-alert';
import useEditPatient from '../../hooks/patiens/useEditPatient';
import usePatientByIdForEditQuery from '../../hooks/patiens/usePatientByIdForEditQuery';
import { formatRFC3339Date } from '../../utils/date';
import FormModal from '../common/FormModal';
import PatientForm from './PatientForm';
import { ReactComponent as EditPatientTitleIcon } from '../../assets/icons/edit-patient-title.svg';

const EditPatientModal = ({ show, onClose, providerId, patientId }) => {
  const [data, loading, error] = usePatientByIdForEditQuery({
    variables: { patientId, providerId },
  });

  const [editPatient, { error: editPatientError }] = useEditPatient({
    onCompleted: () => {
      onClose();
    },
  });
  const onSubmit = async (newData) => {
    await editPatient({
      variables: {
        data: { ...newData, dob: newData.dob ? formatRFC3339Date(newData.dob) : undefined },
        providerId,
        patientId,
      },
    });
  };

  return (
    <FormModal
      isEdit
      show={show}
      onClose={onClose}
      onSubmit={onSubmit}
      TitleIcon={EditPatientTitleIcon}
      Form={PatientForm}
      initialValues={data}
      loading={loading}
      error={error || editPatientError}
    />
  );
};

export default EditPatientModal;

export function showEditPatientModal({ providerId, patientId }) {
  confirmAlert({
    customUI: ({ onClose }) => (
      <EditPatientModal onClose={onClose} show providerId={providerId} patientId={patientId} />
    ),
  });
}
