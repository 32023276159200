import { gql } from '@apollo/client';
import VISITS_TABLE_ITEM from '../fragments/visitsTableItem';

const visitsTableQuery = gql`
  query visits(
    $limit: Int!
    $skip: Int!
    $patientId: ID
    $providerId: ID
    $statuses: [VisitStatus]
    $orderBy: VisitOrderFields
    $orderDirection: OrderDirection
  ) {
    visits(
      limit: $limit
      skip: $skip
      patientId: $patientId
      providerId: $providerId
      statuses: $statuses
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      count
      data {
        ...visitsTableItem
      }
    }
  }
  ${VISITS_TABLE_ITEM}
`;

export default visitsTableQuery;
