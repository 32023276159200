import { gql } from '@apollo/client';
import { HISTORIC_METRICS_FRAGMENT } from '../fragments/metrics';

const historicMetrics = gql`
  query numberOfHistoricalMetrics(
    $providerId: ID
    $scribeId: ID
    $dateRange: DateRange
    $metricsEvents: [HistoricMetricsEvents]
  ) {
    numberOfHistoricalMetrics(
      providerId: $providerId
      scribeId: $scribeId
      dateRange: $dateRange
      metricsEvents: $metricsEvents
    ) {
      ...historicMetrics
    }
  }
  ${HISTORIC_METRICS_FRAGMENT}
`;

export default historicMetrics;
