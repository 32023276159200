import faker from 'faker';
import { orderBy } from 'lodash';

const messages = [
  'Provider 2 requested amendments for the note of patient Richie Jackson',
  'Provider 1 approved the document of patient Tony Hawk',
  'Provider 1 submitted a new document of patient Tony Hawk',
  'Provider 1 submitted a new document of patient Rodney Mullen',
];

const notificationFactory = () => ({
  _id: faker.datatype.uuid(),
  message: faker.random.arrayElement(messages),
  date: faker.date.recent(),
});

const notifications = orderBy([...new Array(10)].map(notificationFactory), ['date'], ['desc']);
export default notifications;
