import { useEffect, useState } from 'react';
import { grabFirstGQLDataResult } from '../../utils/helpers';
import useCachedQuery from './useCachedQuery';
import useMethod from './useMethod';

export default function usePaginatedQuery(query, rawOptions) {
  const { itemsPerPage = 10, initialPage = 1, ...options } = rawOptions;
  const [limit, setLimit] = useState(itemsPerPage);
  const [page, setPage] = useState(initialPage);

  useEffect(() => {
    setLimit(itemsPerPage);
    setPage(1);
  }, [itemsPerPage]);

  const skip = (page - 1) * limit;
  const res = useCachedQuery(query, { ...options, variables: { ...options.variables, limit, skip, page } });

  const { data, count = 0 } = grabFirstGQLDataResult(res.data) || {};

  const totalPages = Math.ceil(count / limit);

  const goToNPage = useMethod((n) => {
    setPage(() => {
      if (n > totalPages) return totalPages;
      if (n < 1) return 1;
      return n;
    });
  });
  const goToNextPage = useMethod(() => {
    setPage((p) => {
      const nextPage = p + 1;
      if (nextPage > totalPages) return totalPages;
      return nextPage;
    });
  });
  const goToPrevPage = useMethod(() => {
    setPage((p) => {
      const prevPage = p - 1;
      if (prevPage < 1) return 1;
      return prevPage;
    });
  });

  return { ...res, page, totalPages, goToNextPage, goToPrevPage, goToNPage, limit, setLimit, skip, count, data };
}
