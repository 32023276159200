import { gql } from '@apollo/client';
import { CURRENT_USER_FRAGMENT } from '../../hooks/auth/useMeQuery';

const editUserMutation = gql`
  mutation updateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      ...currentUser
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

export default editUserMutation;
