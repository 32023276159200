import { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import CustomToggle from './CustomToggle';
import RecordingItem from './RecordingItem';

const ProviderFeedback = ({ recordings, providerId, isStatusHistory }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className={isStatusHistory ? '' : 'mb-4'}>
      <CustomToggle
        isSmallToggleButton={isStatusHistory}
        eventKey="Provider’s Feedback"
        onToggle={() => {
          setIsOpen((o) => !o);
        }}
        isOpen={isOpen}
      >
        Provider’s Feedback
      </CustomToggle>
      <Accordion.Collapse eventKey="Provider’s Feedback" in={isOpen}>
        <Card>
          <Card.Body style={{ padding: '0px' }}>
            {recordings.map((r, i) => (
              <RecordingItem key={r.id} r={r} number={i + 1} providerId={providerId} />
            ))}
          </Card.Body>
        </Card>
      </Accordion.Collapse>
    </div>
  );
};

export default ProviderFeedback;
