import { gql } from '@apollo/client';
import REFRESH_RECORDING_STATUS_FRAGMENT from '../fragments/refetchRecordingStatus';

const refreshRecordingStatus = gql`
  query recording($recordingId: ID!, $providerId: ID!) {
    recording(recordingId: $recordingId, providerId: $providerId) {
      ...refreshRecordingStatus
    }
  }
  ${REFRESH_RECORDING_STATUS_FRAGMENT}
`;

export default refreshRecordingStatus;
