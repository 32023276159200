import { gql } from '@apollo/client';
import SOAP_FOR_PATIENT_NOTE_FRAGMENT from '../fragments/soapForPatientNote';

const soapForPatientNoteQuery = gql`
  query soapNotesByPatientId($limit: Int!, $skip: Int!, $patientId: ID!, $providerId: ID!) {
    soapNotesByPatientId(limit: $limit, skip: $skip, patientId: $patientId, providerId: $providerId) {
      count
      data {
        ...soapForPatientNote
      }
    }
  }
  ${SOAP_FOR_PATIENT_NOTE_FRAGMENT}
`;

export default soapForPatientNoteQuery;
