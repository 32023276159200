import faker from 'faker';
import visitStatuses from '../constants/visitStatuses';

const providerActionItemsDataFactory = () => ({
  id: faker.datatype.uuid(),
  patient: faker.name.findName(),
  latestVisit: { status: visitStatuses.DOCUMENTING },
  lastUpdatedAt: faker.date.recent(),
});

const providerActionItemsData = [...new Array(10)].map(providerActionItemsDataFactory);

export default providerActionItemsData;
