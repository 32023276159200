import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router-dom';
import useDebouncedValue from '../../hooks/common/useDebouncedValue';
import Table from '../../components/common/Table';
// import data from '../../__mock__/providersData';
import Page from '../../components/common/Page';
import SearchInput from '../../components/common/SearchInput';
import paths from '../../router/paths';
import usePaginatedQuery from '../../hooks/common/usePaginatedQuery';
import providersTableQuery from '../../graphql/queries/providersTable';
import PageLoading from '../../components/common/PageLoading';
import ErrorAlert from '../../components/common/ErrorAlert';
import TablePagination from '../../components/common/Pagination';

const columns = [
  { id: 'name', title: 'Provider', cell: (row) => row.displayName },
  { id: 'totalPatients', title: 'Patients', cell: (row) => row.totalPatients },
  { id: 'totalVisits', title: 'Visits', cell: (row) => row.totalVisits },
  { id: 'totalActionItems', title: 'Action Items', cell: (row) => row.totalActionItems },
];

const keyExtractor = (row) => row.id;

const Providers = () => {
  const history = useHistory();

  const [searchQuery, searchInputValue, setSearchInputValue] = useDebouncedValue();

  const [excludeWithoutActionItems, setExcludeWithoutActionItems] = useState(false);

  const { data, error, loading, totalPages, page, goToNPage } = usePaginatedQuery(providersTableQuery, {
    itemsPerPage: 10,
    fetchPolicy: 'cache-and-network',
    variables: { searchQuery, excludeWithoutActionItems },
  });

  return (
    <Page title="Providers">
      <Row className="mb-4">
        <Col md={4}>
          <SearchInput
            value={searchInputValue}
            onChange={(v) => {
              setSearchInputValue(v);
            }}
          />
        </Col>
        <Col className="d-flex justify-content-end mt-4 mt-md-0">
          {excludeWithoutActionItems ? (
            <Button variant="outline-primary" size="sm" onClick={() => setExcludeWithoutActionItems(false)}>
              Show Completed
            </Button>
          ) : (
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => {
                setExcludeWithoutActionItems(true);
              }}
            >
              Hide Completed
            </Button>
          )}
        </Col>
      </Row>
      {loading && !data ? <PageLoading /> : null}
      {error ? <ErrorAlert error={error} /> : null}

      {Array.isArray(data) ? (
        <Table
          loading={loading}
          columns={columns}
          data={data}
          keyExtractor={keyExtractor}
          onClickRow={(row) => history.push(generatePath(paths.providerDashboard, { providerId: row.id }))}
          noData="No providers found"
        />
      ) : null}

      <TablePagination {...{ totalPages, page, goToNPage }} />
    </Page>
  );
};

export default Providers;
