import { Spinner } from 'react-bootstrap';
import c from './PageLoading.module.scss';

export default function PageLoading() {
  return (
    <div className={c.container}>
      <Spinner className={c.spinner} animation="border" variant="secondary" />
    </div>
  );
}
