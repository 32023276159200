import { toast } from 'react-toastify';
import roles from '../../constants/roles';
import useAssignRole from '../../hooks/users/useAssignRole';
import Button from '../common/Button';
import { confirm } from '../common/ConfirmAlert';

const AssignScribeOrAdminRole = ({ userId, isScribe, refetch }) => {
  const [assignRole, { loading }] = useAssignRole({
    onCompleted: () => {
      toast.success('Role successfully assigned');
      refetch();
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const onAssign = () => {
    assignRole({ variables: { userId, role: isScribe ? roles.ADMIN : roles.SCRIBE } });
  };

  const onClick = (e) => {
    e.stopPropagation();
    confirm({
      title: 'Assign role',
      text: `Do you really want to assign this user ${isScribe ? 'an Admin' : 'a Scribe'} role?`,
      confirmButtonText: 'Assign',
      onConfirm: onAssign,
    });
  };
  return (
    <Button
      onClick={onClick}
      variant="link"
      size="sm"
      spinnerVariant="primary"
      loading={loading}
      style={{ textDecoration: 'none', padding: '0px' }}
    >
      {isScribe ? 'Assign admin role' : 'Assign scribe role'}
    </Button>
  );
};

export default AssignScribeOrAdminRole;
