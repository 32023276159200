import { useMsal } from '@azure/msal-react';
import React from 'react';

export default function useLogout() {
  const { instance } = useMsal();
  return React.useCallback(() => {
    // TODO: logout from graphql ?
    // TODO: clear apollo cache ?
    instance.logout({ postLogoutRedirectUri: window.location.origin });
  }, [instance]);
}
