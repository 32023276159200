import { useEffect, useMemo } from 'react';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { EventType, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import settings from '../../constants/settings';
import AuthLayout from '../layout/AuthLayout';
import paths from '../../router/paths';
import FullscreenLoading from '../common/FullscreenLoading';
import ErrorAlertComponent from '../common/ErrorAlert';

export const authorities = {
  RESET_PASSWORD: `https://${settings.azure.ad.tenantName}.b2clogin.com/${settings.azure.ad.tenantName}.onmicrosoft.com/${settings.azure.ad.resetPasswordPolicy}`,
  SIGN_IN_UP: `https://${settings.azure.ad.tenantName}.b2clogin.com/${settings.azure.ad.tenantName}.onmicrosoft.com/${settings.azure.ad.loginPolicy}`,
};
const authRequest = {
  scopes: settings.azure.ad.scopes,
};
const b2cErrorCodes = {
  CANCEL: 'AADB2C90091',
  FORGOTTEN_PASSWORD: 'AADB2C90118',
};
export function ErrorAlert({ error }) {
  // const { instance } = useMsal();
  const errorId = error.errorMessage.slice(0, error.errorMessage.search(':'));
  if (Object.values(b2cErrorCodes).includes(errorId)) return <FullscreenLoading />;
  // const history = useHistory();
  // useEffect(() => {
  //   if (errorId === b2cErrorCodes.CANCEL) {
  //     instance.loginRedirect(authRequest);
  //   }
  //   if (errorId === b2cErrorCodes.FORGOTTEN_PASSWORD) {
  //     instance
  //       .loginRedirect({
  //         authority: `https://${settings.azure.ad.tenantName}.b2clogin.com/${settings.azure.ad.tenantName}.onmicrosoft.com/${settings.azure.ad.resetPasswordPolicy}`,
  //       })
  //       .then(() => {});
  //   }
  // }, [errorId, history, instance]);
  return (
    <ErrorAlertComponent error title={error.name}>
      {error.errorMessage}
      <Link to={paths.logout}>
        <Button variant="primary" className="mt-3">
          Logout
        </Button>
      </Link>
    </ErrorAlertComponent>
  );
}

const configuration = {
  system: {
    iframeHashTimeout: 10000,
  },
  auth: {
    clientId: settings.azure.ad.appId,
    authority: authorities.SIGN_IN_UP,
    knownAuthorities: Object.values(authorities),
    redirectUri: `${window.location.origin}${paths.authenticator}`,
    // redirectUri: `${window.location.origin}`,
  },
  cache: {
    cacheLocation: 'sessionStorage',
  },
};

export const msalInstance = new PublicClientApplication(configuration);

export default function MsalAuthWrapper({ children, authenticationRequest = authRequest }) {
  const { instance } = useMsal();
  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        const errorMessage = event.error?.errorMessage || '';
        const errorId = errorMessage.slice(0, errorMessage.search(':'));
        if (errorId === b2cErrorCodes.FORGOTTEN_PASSWORD) {
          instance.loginRedirect({
            authority: authorities.RESET_PASSWORD,
          });
        }
        if (errorId === b2cErrorCodes.CANCEL) {
          instance.loginRedirect(authRequest);
        }
      }
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        if (event.payload.idTokenClaims.tfp === settings.azure.ad.resetPasswordPolicy) {
          toast.success('Password successfully changed. Please login');
          setTimeout(() => {
            instance.logout({ account: event.payload.account }).then(() => instance.loginRedirect(authRequest));
          }, 2000);
        }
      }
    });
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  const loadingComponent = useMemo(
    // eslint-disable-next-line react/no-unstable-nested-components
    () => () =>
      (
        <AuthLayout>
          <FullscreenLoading />
        </AuthLayout>
      ),
    []
  );

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authenticationRequest}
      errorComponent={ErrorAlert}
      loadingComponent={loadingComponent}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
}
