class SidebarController {
  changeIsShowSidebar = null;

  openSidebar = () => {
    if (typeof this.changeIsShowSidebar === 'function') {
      this.changeIsShowSidebar(true);
    }
  };

  closeSidebar = () => {
    if (typeof this.changeIsShowSidebar === 'function') {
      this.changeIsShowSidebar(false);
    }
  };

  setChangeIsShowSidebar = (setIsShowSidebar) => {
    this.changeIsShowSidebar = setIsShowSidebar;
  };

  resetChangeIsShowSidebar = () => {
    this.changeIsShowSidebar = null;
  };
}

export default new SidebarController();
