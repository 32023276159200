import { NavLink, generatePath, useParams, useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { useRef } from 'react';
import Table from '../common/Table';
import VisitStatus from '../visits/VisitStatus';
import actionItems from '../../graphql/queries/actionItems';
import paths from '../../router/paths';
import usePaginatedQuery from '../../hooks/common/usePaginatedQuery';
import TablePagination from '../common/Pagination';
import ErrorAlert from '../common/ErrorAlert';
import { customDateFormat } from '../../utils/date';
import visitStatuses from '../../constants/visitStatuses';
import Loading from '../common/Loading';

const keyExtractor = (item) => item.id;

const actionItemStatuses = [visitStatuses.DOCUMENTING, visitStatuses.NOTE_REJECTED, visitStatuses.NOTE_APPROVED];

const ActionItems = ({ title = 'Action Items' }) => {
  const { providerId } = useParams();
  const history = useHistory();
  const { data, error, loading, totalPages, page, goToNPage } = usePaginatedQuery(actionItems, {
    itemsPerPage: 10,
    fetchPolicy: 'cache-and-network',
    variables: { providerId, statuses: actionItemStatuses },
  });

  const columns = useRef([
    { id: 'patient', title: 'Patient', cell: (row) => row.patient.fullName },
    {
      id: 'status',
      title: 'Status',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ status }) => (status ? <VisitStatus status={status} /> : 'N/A'),
    },
    {
      id: 'updatedAt',
      title: 'Last update',
      cell: (row) => customDateFormat(new Date(row.updatedAt)),
    },
  ]);
  if (!providerId && columns.current.length !== 4) {
    columns.current = [
      { id: 'provider', title: 'Provider', cell: (row) => row.provider.displayName },
      ...columns.current,
    ];
  }

  return (
    <div>
      <h3 className="mb-4">{title}</h3>
      {error ? <ErrorAlert error={error} /> : null}
      {!Array.isArray(data) && loading ? <Loading isTable /> : null}
      {Array.isArray(data) ? (
        <Table
          loading={loading}
          columns={columns.current}
          data={data}
          keyExtractor={keyExtractor}
          onClickRow={(row) => {
            history.push(
              // eslint-disable-next-line comma-dangle
              generatePath(paths.visitOverview, { providerId: providerId || row.provider.id, visitId: row.id })
            );
          }}
          noData="No action items found"
          hidePagination
        />
      ) : null}
      <TablePagination {...{ totalPages, page, goToNPage }} />
      <Row>
        <Col>
          <NavLink to={providerId ? generatePath(paths.documents, { providerId }) : paths.allDocuments}>
            <Button variant="outline-secondary" size="sm">
              View all
            </Button>
          </NavLink>
        </Col>
        {providerId ? (
          <Col className="d-flex justify-content-end">
            <NavLink to={generatePath(paths.patients, { providerId })}>
              <Button variant="outline-secondary" size="sm">
                View patients
              </Button>
            </NavLink>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default ActionItems;
