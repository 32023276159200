import InfiniteScroll from 'react-infinite-scroll-component';
import { Row, Spinner } from 'react-bootstrap';
import NoteTemplateCard from './NoteTemplateCard';

const NoteTemplatesInfiniteScroll = ({ data, count, fetchMoreData, scrollableTarget, isModal, fill }) => (
  <InfiniteScroll
    style={{ overflow: 'hidden' }}
    dataLength={data.length}
    next={fetchMoreData}
    hasMore={data.length !== count}
    loader={
      <Row>
        <Spinner style={{ marginLeft: '50%', marginTop: '30px' }} animation="border" />
      </Row>
    }
    scrollableTarget={scrollableTarget}
  >
    <Row className="mt-3">
      {data.map((note) => (
        <NoteTemplateCard key={note.id} note={note} isModal={isModal} fill={fill} />
      ))}
    </Row>
  </InfiniteScroll>
);

export default NoteTemplatesInfiniteScroll;
