import { Button } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router-dom';
import VisitStatus from './VisitStatus';
import OverviewCard from '../common/OverviewCard';
import { getVisitReasonLabel } from '../../utils/visit';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as HistoryClock } from '../../assets/icons/history-clock.svg';

import { showEditVisitModal } from './EditVisitModal';
import { showVisitStatusHistoryModal } from './VisitStatusHistoryModal';
import useRemoveVisit from '../../hooks/visits/useRemoveVisit';
import visitStatuses from '../../constants/visitStatuses';
import paths from '../../router/paths';
import { customDateFormat } from '../../utils/date';

export default function VisitOverviewCard({ visit, providerId, loading }) {
  const history = useHistory();
  const removeVisit = useRemoveVisit(
    { visitId: visit?.id, providerId },
    {
      onCompleted: () => {
        history.push(generatePath(paths.patientOverview, { providerId, patientId: visit.patient.id }));
      },
    }
  );
  const visitRows = [
    { label: 'Reason', text: getVisitReasonLabel(visit?.reason) },
    { label: 'Chief Complaint', text: visit?.complaint, margin: true },
  ];
  return (
    <OverviewCard
      headerRight={<VisitStatus status={visit?.status} />}
      subtitle={visit?.visitDate ? customDateFormat(new Date(visit?.visitDate)) : 'NONE'}
      rows={visitRows}
      loading={loading}
      title={
        <>
          Visit{' '}
          <Button
            type="button"
            variant="link"
            disabled={loading}
            size="sm"
            className="pb-1 pt-0 pe-1 ps-1"
            onClick={() => {
              showEditVisitModal({ providerId: visit?.providerId, visitId: visit.id });
            }}
          >
            <EditIcon className="text-primary" />
          </Button>
          <Button
            variant="link"
            size="sm"
            disabled={loading}
            className="pb-1 pt-0 pe-1 ps-1"
            onClick={() => {
              showVisitStatusHistoryModal({ providerId, visitId: visit.id });
            }}
          >
            <HistoryClock />
          </Button>
        </>
      }
      buttonBottom={
        visit?.status === visitStatuses.SCHEDULED ? (
          <Button onClick={removeVisit} style={{ textDecoration: 'none', padding: '0px' }} variant="link">
            Delete visit
          </Button>
        ) : null
      }
    />
  );
}
