import { gql } from '@apollo/client';
import PATIENT_DETAILS_FRAGMENT from '../fragments/patientDetails';

const patientById = gql`
  query patientById($providerId: ID!, $patientId: ID!) {
    patientById(providerId: $providerId, patientId: $patientId) {
      ...patientDetails
    }
  }
  ${PATIENT_DETAILS_FRAGMENT}
`;

export default patientById;
