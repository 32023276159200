import { Card } from 'react-bootstrap';
import Loading from './Loading';

const OverviewCard = ({ title, headerRight, subtitle, rows = [], buttonBottom, loading }) => (
  <Card>
    <Card.Body>
      <div className="d-flex justify-content-between">
        <Card.Title>
          <h5>{title}</h5>
        </Card.Title>
        {headerRight}
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          {subtitle ? (
            <Card.Subtitle className="mb-3">
              <h6 className="text-muted">{subtitle}</h6>
            </Card.Subtitle>
          ) : null}

          {rows.map(({ label, text, margin }) => (
            <Card.Text key={label} className={margin ? 'mb-3' : 'mb-0'}>
              {label}
              {': '}
              <b>{text}</b>
            </Card.Text>
          ))}

          {buttonBottom}
        </div>
      )}
    </Card.Body>
  </Card>
);

export default OverviewCard;
