import { Button, Col, Row, useAccordionButton } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import c from './CustomToggle.module.scss';

function CustomToggle({ children, eventKey, onToggle, isOpen, isSmallToggleButton, ...rest }) {
  const decoratedOnClick = useAccordionButton(eventKey, onToggle);

  return (
    <Row>
      <Col xs={12}>
        <Button
          className={c.providerFeedbackToggle}
          style={{ width: '100%' }}
          type="button"
          variant="primary"
          size={isSmallToggleButton ? 'sm' : 'lg'}
          onClick={decoratedOnClick}
          {...rest}
        >
          <Row>
            <Col className="d-flex justify-content-start">{children}</Col>
            <Col className="d-flex justify-content-end" xs="2">
              {isOpen ? <ChevronUp /> : <ChevronDown />}
            </Col>
          </Row>
        </Button>
      </Col>
    </Row>
  );
}

export default CustomToggle;
