import { gql } from '@apollo/client';

const NOTIFICATION = gql`
  fragment notification on Notification {
    id
    read
    date
    body
    payload
  }
`;

export default NOTIFICATION;
