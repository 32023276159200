/* eslint-disable react/jsx-wrap-multilines */
import { Button, Card, Col, Row } from 'react-bootstrap';
import { orderBy } from 'lodash';
import { generatePath, useHistory } from 'react-router-dom';
import { ReactComponent as AddDateIcon } from '../../assets/icons/calendar-add.svg';
import paths from '../../router/paths';
import { formatDurationSeconds } from '../../utils/helpers';
import VisitStatus from '../visits/VisitStatus';
import Table from '../common/Table';
import { showNewVisitModal } from '../visits/NewVisitModal';
import usePaginatedQuery from '../../hooks/common/usePaginatedQuery';
import visitsTableQuery from '../../graphql/queries/visitsTable';
import TablePagination from '../common/Pagination';
import { visitReasonTitleMapping } from '../../constants/visitReasons';
import ErrorAlert from '../common/ErrorAlert';
import VisitTableDeleteButton from '../visits/VisitTableDeleteButton';
import { customDateFormat } from '../../utils/date';

const keyExtractor = (row) => row.id;
const columns = [
  {
    id: 'visitDate',
    title: 'Date',
    cell: ({ visitDate }) => customDateFormat(new Date(visitDate)),
  },
  {
    id: 'reason',
    title: 'Reason',
    cell: ({ reason }) => visitReasonTitleMapping[reason],
  },
  {
    id: 'complaint',
    title: 'Chief Complaint',
    cell: ({ complaint }) => complaint,
  },
  {
    id: 'status',
    title: 'Status',
    cell: ({ status }) => <VisitStatus status={status} />,
  },
  {
    id: 'recordingsDurationSeconds',
    title: 'Time',
    cell: ({ recordingsDurationSeconds }) => formatDurationSeconds(recordingsDurationSeconds),
  },
  {
    id: 'button',
    title: '',
    cell: ({ status, id, providerId }) => <VisitTableDeleteButton status={status} id={id} providerId={providerId} />,
  },
];
export default function PatientVisitsCard({ providerId, patientId }) {
  const history = useHistory();

  const { data, loading, error, totalPages, page, goToNPage, refetch } = usePaginatedQuery(visitsTableQuery, {
    itemsPerPage: 10,
    fetchPolicy: 'cache-and-network',
    variables: {
      patientId,
      orderBy: 'visitDate',
      orderDirection: 'desc',
    },
  });

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <Row>
            <Col lg="2" sm="5" xs="4">
              <strong>Visits</strong>
            </Col>
            <Col className="d-flex justify-content-end">
              <Button
                size="sm"
                onClick={() => {
                  showNewVisitModal({ providerId, patientId, onCompleted: refetch });
                }}
              >
                <AddDateIcon /> Schedule New Visit
              </Button>
            </Col>
          </Row>
        </Card.Title>
        {error ? <ErrorAlert error={error} /> : null}
        <Table
          isNumbered
          columns={columns}
          data={orderBy(data, ['date'], ['asc'])}
          keyExtractor={keyExtractor}
          onClickRow={(row) => {
            // TODO: replace with row.providerId
            history.push(generatePath(paths.visitOverview, { providerId, visitId: row.id }));
          }}
          loading={loading}
          noData="No Visits found"
        />
        <TablePagination {...{ page, totalPages, goToNPage }} />
      </Card.Body>
    </Card>
  );
}
