import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CurrentProviderContext from './context';

export default function useSetProviderIdFromRouteParamsEffect() {
  const { providerId: routerProviderId } = useParams();
  const ctx = useContext(CurrentProviderContext);
  const { providerId, setProviderId } = ctx;

  useEffect(() => {
    if (routerProviderId && routerProviderId !== providerId) {
      setProviderId(routerProviderId);
    }
  }, [routerProviderId, providerId, setProviderId]);
}
