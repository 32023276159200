import { gql, useQuery } from '@apollo/client';
import { grabFirstGQLDataResult } from '../../utils/helpers';
import SELECTED_PROVIDER_FRAGMENT from './selectedProviderFragment';

const PROVIDER_BY_ID_QUERY = gql`
  query provider($providerId: ID!) {
    provider(providerId: $providerId) {
      ...selectedProvider
    }
  }
  ${SELECTED_PROVIDER_FRAGMENT}
`;

const useProviderById = (providerId) => {
  const { loading, data, error } = useQuery(PROVIDER_BY_ID_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: !providerId,
    variables: { providerId },
  });
  return { loading, data: grabFirstGQLDataResult(data), error };
};

export default useProviderById;
