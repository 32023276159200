import { gql } from '@apollo/client';
import USER_TABLE_ITEM_FRAGMENT from '../fragments/userTableItem';

const activateUser = gql`
  mutation activateUser($userId: ID!) {
    activateUser(userId: $userId) {
      ...userTableItem
    }
  }
  ${USER_TABLE_ITEM_FRAGMENT}
`;

export default activateUser;
