import { gql } from '@apollo/client';
import USER_TABLE_ITEM_FRAGMENT from '../fragments/userTableItem';

const userById = gql`
  query userById($userId: ID!) {
    userById(userId: $userId) {
      ...userTableItem
    }
  }
  ${USER_TABLE_ITEM_FRAGMENT}
`;

export default userById;
