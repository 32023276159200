import { gql } from '@apollo/client';
import PROVIDER_TABLE_ITEM_FRAGMENT from '../fragments/providerTableItem';

const providersTableQuery = gql`
  query providersTable($limit: Int!, $skip: Int!, $searchQuery: String, $excludeWithoutActionItems: Boolean) {
    scribeProviders(
      limit: $limit
      skip: $skip
      searchQuery: $searchQuery
      excludeWithoutActionItems: $excludeWithoutActionItems
    ) {
      count
      data {
        ...providerTableItem
      }
    }
  }
  ${PROVIDER_TABLE_ITEM_FRAGMENT}
`;

export default providersTableQuery;
