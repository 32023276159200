import { gql } from '@apollo/client';
import VISIT_DETAILS_FRAGMENT from '../fragments/visit';

const markVisitAsCompletedMutation = gql`
  mutation markVisitAsCompleted($visitId: ID!, $providerId: ID!) {
    markVisitAsCompleted(visitId: $visitId, providerId: $providerId) {
      ...visitDetails
    }
  }
  ${VISIT_DETAILS_FRAGMENT}
`;

export default markVisitAsCompletedMutation;
