import { Container } from 'react-bootstrap';
import c from './AppLayout.module.scss';
import Sidebar from '../sidebar/Sidebar';
import Navbar from '../common/Navbar';

// const AppLayout = ({ children }) => (
//   <>
//     <Navbar />
//     <Row style={{ marginRight: '0px' }}>
//       <Col md="auto" className="pe-0">
//         <Sidebar />
//       </Col>

//       <Col>
//         <Container className={cn('position-relative')}>{children}</Container>
//       </Col>
//     </Row>
//   </>
// );

const AppLayout = ({ children }) => (
  <>
    <Navbar />
    <div className="d-flex">
      <div className={c.sidebarContainer}>
        <Sidebar />
      </div>

      <div className={c.contentContainer}>
        <Container fluid className="position-relative">
          {children}
        </Container>
      </div>
    </div>
  </>
);

export default AppLayout;
