const visitReasons = {
  NEW_PATIENT: 'new_patient',
  FOLLOW_UP: 'follow_up',
  POST_OP: 'post_op',
  MEDICATION_REFILL: 'medication_refill',
};

export const visitReasonTitleMapping = {
  [visitReasons.NEW_PATIENT]: 'New patient visit',
  [visitReasons.FOLLOW_UP]: 'Follow up visit',
  [visitReasons.POST_OP]: 'Post op visit',
  [visitReasons.MEDICATION_REFILL]: 'Medication refill',
};

export default visitReasons;
