import soapForPatientNoteQuery from '../../graphql/queries/soapForPatientNote';
import useInfiniteScrollQuery from '../../hooks/common/useInfiniteScrollQuery';
import NoteTemplatesModalBody from './NoteTemplatesModalBody';

const FillWithPatientNotes = ({ scrollableTarget, fill, patientId, providerId }) => {
  const [data, error, loading, , fetchMoreData] = useInfiniteScrollQuery(
    soapForPatientNoteQuery,
    {
      variables: { patientId, providerId },
      restOptions: { fetchPolicy: 'cache-and-network' },
    },
    12
  );
  return (
    <NoteTemplatesModalBody
      count={data?.count}
      data={data?.data}
      loading={loading}
      error={error}
      fetchMoreData={fetchMoreData}
      scrollableTarget={scrollableTarget}
      fill={fill}
    />
  );
};

export default FillWithPatientNotes;
