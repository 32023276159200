import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';

const defaultOptions = { maxWait: 4000, leading: false, trailing: true };
export default function useDebouncedValue(delay = 500, options = defaultOptions) {
  const [value, setValue] = useState('');

  const [debouncedValue, setDebouncedValue] = useState('');
  const ref = useRef(debounce(setDebouncedValue, delay, options));

  useEffect(() => {
    ref.current = debounce(setDebouncedValue, delay, options);
  }, [delay, options]);

  useEffect(() => {
    ref.current(value);
  }, [value]);

  return [debouncedValue, value, setValue];
}
