import { gql } from '@apollo/client';

const SCRIBE = gql`
  fragment scribe on Scribe {
    id
    displayName
    numberOfDocumented
    numberOfProviders
    deactivated
    email
    user {
      role
      createdAt
    }
  }
`;

export default SCRIBE;
