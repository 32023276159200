import { formatDuration, intervalToDuration, minutesToSeconds } from 'date-fns';
import { get } from 'lodash';
import { cardTitle, valueFormatDuration, valueFormatPercentage } from '../constants/metrics';

export function getMessageFromGraphQLError(error) {
  if (error.networkError) {
    const networkErrorMessage = get(error, 'networkError.result.errors[0].message', '');
    const contextCreationFailedPrefix = 'Context creation failed: ';
    if (networkErrorMessage.includes(contextCreationFailedPrefix)) {
      return networkErrorMessage.replace(contextCreationFailedPrefix, '');
    }
    return get(error, 'networkError.result.errors[0].message', error.message);
  }
  if (error.graphQLErrors) {
    return error.graphQLErrors.map((err) => err.message).join('\n');
  }
  return error.message;
}

export function grabFirstGQLDataResult(data) {
  if (!data) return undefined;
  return Object.values(data)[0];
}

export function formatDurationSeconds(durationSeconds) {
  if (!durationSeconds) return '0m';
  if (durationSeconds < minutesToSeconds(1)) return '<1m';
  const formatDistanceLocale = { xMinutes: '{{count}}m', xHours: '{{count}}h' };
  const shortEnLocale = { formatDistance: (token, count) => formatDistanceLocale[token].replace('{{count}}', count) };
  const duration = intervalToDuration({ start: 0, end: durationSeconds * 1000 });
  const v = formatDuration(duration, { format: ['hours', 'minutes'], locale: shortEnLocale });
  return v;
}

const formatDurationForMetricsCards = (durationSeconds) => {
  if (!durationSeconds) return '0h';

  if (durationSeconds < minutesToSeconds(1)) return '1m';

  const formatDistanceLocale = { xMinutes: '{{count}}m', xHours: '{{count}}h' };
  const shortEnLocale = { formatDistance: (token, count) => formatDistanceLocale[token].replace('{{count}}', count) };
  const duration = intervalToDuration({ start: 0, end: durationSeconds * 1000 });
  const v = formatDuration(duration, { format: ['hours', 'minutes'], locale: shortEnLocale });
  return v;
};

export const createMetricsCards = (metricsData) =>
  metricsData.map((metrics) => {
    const card = { id: metrics.name, title: cardTitle[metrics.name] };
    if (metrics.value === null) {
      return { ...card, value: 'N/A' };
    }
    if (valueFormatDuration.includes(metrics.name)) {
      return { ...card, value: formatDurationForMetricsCards(metrics.value) };
    }
    if (valueFormatPercentage.includes(metrics.name)) {
      return { ...card, value: `${metrics.value || metrics.value === 0 ? `${Math.round(metrics.value)}%` : 'N/A'}` };
    }
    return { ...card, value: metrics.value };
  });
