/* eslint-disable import/prefer-default-export */
import visitReasons from '../constants/visitReasons';

export const getVisitReasonLabel = (r) => {
  const reasons = {
    [visitReasons.NEW_PATIENT]: 'New patient visit',
    [visitReasons.FOLLOW_UP]: 'Follow up visit',
    [visitReasons.POST_OP]: 'Post op visit',
    [visitReasons.MEDICATION_REFILL]: 'Medication refill',
  };
  return reasons[r] || 'N/A';
};
