import { Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import Dropdown from './Dropdown';
import { ReactComponent as UserIcon } from '../../assets/icons/sidebar/user.svg';
import { ReactComponent as ChevronUpIcon } from '../../assets/icons/chevron-up.svg';
import paths from '../../router/paths';
import useMeQuery from '../../hooks/auth/useMeQuery';
import { showEditUserModal } from '../users/EditUserModal';
import { authorities } from '../auth/MsalAuthWrapper';

export default function UserDropdown() {
  const [currentUser, , refetch] = useMeQuery();

  const { instance } = useMsal();

  return (
    <Dropdown
      title={currentUser.displayName}
      Icon={UserIcon}
      RightIcon={ChevronUpIcon}
      items={[
        {
          key: 'editProfile',
          title: 'Edit Profile',
          onClick: () => {
            showEditUserModal({ user: currentUser, refetch });
          },
        },
        { key: 'support', title: 'Contact Support' },
        {
          key: 'reset-pass',
          title: 'Reset Password',
          onClick: () => {
            instance.loginRedirect({
              authority: authorities.RESET_PASSWORD,
            });
          },
        },
        { key: 'logout', className: 'text-primary', as: Link, to: paths.logout, title: 'Logout' },
      ]}
    />
  );
}
