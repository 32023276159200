import { confirmAlert } from '@uplab/react-confirm-alert';
import { toast } from 'react-toastify';
import { ReactComponent as EditNoteTemplateTitleIcon } from '../../assets/icons/edit-note-templates-title.svg';
import FormModal from '../common/FormModal';
import NoteTemplatesForm from './NoteTemplateForm';
import useNoteTemplateById from '../../hooks/noteTemplates/useNoteTemplateById';
import useEditNoteTemplate from '../../hooks/noteTemplates/useEditNoteTemplate';

const EditNoteTemplate = ({ show, onClose, noteTemplateId }) => {
  const [data, loading, error] = useNoteTemplateById({
    variables: { id: noteTemplateId },
  });
  const [editNoteTemplate, { error: editNoteTemplateError }] = useEditNoteTemplate({
    onCompleted: () => {
      onClose();
    },
  });
  const onSubmit = async (newData) => {
    await editNoteTemplate({ variables: { data: newData, id: noteTemplateId } });
    toast.success('Note template successfully edited');
  };
  return (
    <FormModal
      loading={loading}
      isEdit
      initialValues={data}
      show={show}
      error={error || editNoteTemplateError}
      onClose={onClose}
      onSubmit={onSubmit}
      TitleIcon={EditNoteTemplateTitleIcon}
      Form={NoteTemplatesForm}
    />
  );
};

export default EditNoteTemplate;

export function showEditNoteTemplateModal({ providerId, noteTemplateId }) {
  confirmAlert({
    customUI: ({ onClose }) => (
      <EditNoteTemplate onClose={onClose} show providerId={providerId} noteTemplateId={noteTemplateId} />
    ),
  });
}
