import { isEmpty } from 'lodash';
import { Accordion, Alert, Card } from 'react-bootstrap';
import { formatDurationSeconds } from '../../utils/helpers';
import Loading from '../common/Loading';
import RecordingItem from './RecordingItem';
import c from './VisitRecordings.module.scss';

export default function VisitRecordings({ recordings, recordingsDurationSeconds, providerId, loading }) {
  return (
    <Card>
      <Card.Body className="pb-0">
        <Card.Title className="d-flex justify-content-between align-items-center">
          <h5>Recordings</h5>
          {loading && !recordings ? (
            ''
          ) : (
            <h6 className="text-muted">{`Total time: ${formatDurationSeconds(recordingsDurationSeconds) || '0m'}`}</h6>
          )}
        </Card.Title>
        {loading && !recordings ? (
          <Loading />
        ) : (
          <Accordion className={c.accordion}>
            {isEmpty(recordings) ? (
              <Alert variant="info" className="mb-0">
                There are no recordings uploaded yet
              </Alert>
            ) : (
              <>
                {recordings.map((r, i) => (
                  <RecordingItem key={r.id} r={r} number={i + 1} providerId={providerId} />
                ))}
              </>
            )}
          </Accordion>
        )}
      </Card.Body>
    </Card>
  );
}
