import { gql } from '@apollo/client';
import VISIT_DETAILS_FRAGMENT from '../fragments/visit';

const visit = gql`
  query visit($providerId: ID, $visitId: ID!) {
    visit(providerId: $providerId, visitId: $visitId) {
      ...visitDetails
    }
  }
  ${VISIT_DETAILS_FRAGMENT}
`;

export default visit;
