import { confirmAlert } from '@uplab/react-confirm-alert';
import { toast } from 'react-toastify';
import useAssignRole from '../../hooks/users/useAssignRole';
import FormModal from '../common/FormModal';
import AssignRoleForm from './AssignRoleForm';

const AssignRoleModal = ({ show, onClose, userId, onCompleted }) => {
  const [assignRole, { error }] = useAssignRole({
    onCompleted: () => {
      if (typeof onCompleted === 'function') {
        onCompleted();
      }
      onClose();
      toast.success('Role successfully changed');
    },
  });
  const onSubmit = async ({ role }) => {
    await assignRole({ variables: { role, userId } });
  };
  return (
    <FormModal
      error={error}
      show={show}
      onClose={onClose}
      onSubmit={onSubmit}
      titleText="Invite User"
      Form={AssignRoleForm}
    />
  );
};

export default AssignRoleModal;

export function showAssignRoleModal({ userId, onCompleted }) {
  confirmAlert({
    customUI: ({ onClose }) => <AssignRoleModal onClose={onClose} show userId={userId} onCompleted={onCompleted} />,
  });
}
