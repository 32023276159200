/* eslint-disable jsx-a11y/no-static-element-interactions */
import { CSSTransition } from 'react-transition-group';
import c from './Backdrop.module.scss';
import SidebarController from '../../services/SidebarController';

const Backdrop = ({ isShowSidebarForSmallScreen }) => (
  <CSSTransition
    in={isShowSidebarForSmallScreen}
    timeout={300}
    unmountOnExit
    classNames={{
      enter: c.backdropEnter,
      enterActive: c.backdropEnterActive,
      exit: c.backdropExit,
      exitActive: c.backdropExitActive,
    }}
  >
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
    <div
      onClick={() => {
        SidebarController.closeSidebar();
      }}
      className={c.backdrop}
    />
  </CSSTransition>
);

export default Backdrop;
