import { Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import { useRef } from 'react';
import FormikGroup from '../common/formik/FromikGroup';
import FormikFormControl from '../common/formik/FormikFormControl';
import FormikFormSelect from '../common/formik/FormikFormSelect';
import FormikFormDate from '../common/formik/FormikFormDate';
import prepareInitialValues from '../../utils/form';
import Button from '../common/Button';
import { dobToDate } from '../../utils/date';

const gender = ['male', 'female'];

const schema = yup.object().shape({
  firstName: yup
    .string()
    .label('First Name')
    .matches(/^[a-zA-Z]+$/, 'The first name should include only Latin letters')
    .required()
    .max(40),
  lastName: yup
    .string()
    .label('Last Name')
    .matches(/^[a-zA-Z]+$/, 'The last name should include only Latin letters')
    .required()
    .max(40),
  patientNumber: yup.string().label('Patient Number').required(),
  dob: yup.date().label('DOB').max(new Date(), 'date should not be in the future').required(),
  gender: yup.mixed().oneOf(gender).required(),
});

const defaultValues = {
  firstName: '',
  lastName: '',
  patientNumber: '',
  dob: '',
  gender: '',
};

const PatientForm = ({ onSubmit, onClose, initialValues }) => {
  const initialValuesRef = useRef();
  if (!initialValuesRef.current) {
    initialValuesRef.current = prepareInitialValues(
      initialValues ? { ...initialValues, dob: dobToDate(initialValues.dob) } : {},
      // eslint-disable-next-line comma-dangle
      defaultValues
    );
  }
  return (
    <Formik validationSchema={schema} initialValues={initialValuesRef.current} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <FormikForm>
          <Row>
            <Col md="6">
              <FormikGroup label="First Name">
                <FormikFormControl type="text" required name="firstName" />
              </FormikGroup>
            </Col>
            <Col className="mt-4 mt-md-0" md="6">
              <FormikGroup label="Last Name">
                <FormikFormControl type="text" required name="lastName" />
              </FormikGroup>
            </Col>
          </Row>
          <FormikGroup className="mt-4" label="Patient Number">
            <FormikFormControl type="text" required name="patientNumber" />
          </FormikGroup>
          <FormikGroup className="mt-4" label="DOB">
            <FormikFormDate type="date" required name="dob" />
          </FormikGroup>
          <FormikGroup className="mt-4" label="Gender">
            <FormikFormSelect required name="gender">
              <option aria-label="empty"> </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </FormikFormSelect>
          </FormikGroup>
          <div className="mt-5">
            <Row>
              <Col className="d-flex justify-content-end">
                <Button className="me-2" onClick={onClose} variant="secondary">
                  Cancel
                </Button>
                <Button loading={isSubmitting} type="submit" variant="primary">
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default PatientForm;
