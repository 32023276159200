import { ApolloProvider } from '@apollo/client';
import { ConfirmAlertContainer } from '@uplab/react-confirm-alert';
import { ToastContainer } from 'react-toastify';
import { MsalProvider } from '@azure/msal-react';
import RootRouter from './router/RootRouter';
// import MsalAuthWrapper from './components/auth/MsalAuthWrapper';
import apolloClient from './graphql/client';
import 'react-toastify/dist/ReactToastify.css';
import { msalInstance } from './components/auth/MsalAuthWrapper';

const App = () => (
  <MsalProvider instance={msalInstance}>
    <ApolloProvider client={apolloClient}>
      <RootRouter />
      <ConfirmAlertContainer />
      <ToastContainer />
    </ApolloProvider>
  </MsalProvider>
);

export default App;
