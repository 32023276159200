import { useQuery } from '@apollo/client';
import patientSelectOption from '../../graphql/queries/patientSelectOption';

const usePatientSelectOption = ({ searchQuery, providerId }) => {
  const { data } = useQuery(patientSelectOption, {
    variables: { limit: 10, skip: 0, searchQuery, providerId },
    skip: !providerId,
    fetchPolicy: 'cache-and-network',
  });

  return { patientOptions: data?.patientsByProviderId.data };
};

export default usePatientSelectOption;
