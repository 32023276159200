import classNames from 'classnames';
import { Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import useCopyToClipboard from '../../hooks/common/useCopyToClipboard';
import c from './SOAPCardText.module.scss';

const SOAPCardText = ({ text }) => {
  const clickRef = useCopyToClipboard(
    text,
    () => {
      toast.success('Text copied');
    },
    () => {
      toast.error('Text not copied');
    }
  );
  return (
    <Card.Text ref={clickRef} title="copy text" className={classNames('mt-4', c.SOAPText)}>
      {text}
    </Card.Text>
  );
};

export default SOAPCardText;
