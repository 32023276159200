import { useMsal, useAccount } from '@azure/msal-react';
import { defer } from 'lodash';
import { useState } from 'react';
import settings from '../../constants/settings';
import useLoginMutation from './useLoginMutation';
import useMethod from '../common/useMethod';

const o = {};
const useLoginApolloWithAzure = ({ onCompleted }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const loginMutation = useLoginMutation();
  const { instance: msalInstance, accounts } = useMsal();
  const account = useAccount(accounts[0] || o);

  const loginApolloWithAzure = useMethod(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const request = {
        scopes: settings.azure.ad.scopes,
        account,
      };
      const result = await msalInstance.acquireTokenSilent(request);

      if (!result || !result.accessToken) throw new Error('Unexpected error: no access token acquired');

      const user = await loginMutation(result.accessToken);
      defer(() => {
        if (typeof onCompleted === 'function') onCompleted(user);
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      setError(e);
    } finally {
      setIsLoading(false);
    }
  });

  return { loginApolloWithAzure, isLoading, error };
};

// if (e instanceof InteractionRequiredAuthError) {
//   // fallback to interaction when silent call fails
//   return msalInstance.acquireTokenRedirect(request);
// }

export default useLoginApolloWithAzure;
