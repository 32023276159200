import { Modal, Alert } from 'react-bootstrap';
import { confirmAlert } from '@uplab/react-confirm-alert';
import useVisitStatusHistory from '../../hooks/visits/useVisitStatusHistory';
import VisitStatusHistoryCard from './VisitStatusHistoryCard';
import LoadingModal from '../common/LoadingModal';
import ErrorAlert from '../common/ErrorAlert';

const VisitStatusHistoryModal = ({ show, onClose, visitId, providerId }) => {
  const [data, loading, error] = useVisitStatusHistory({ variables: { visitId, providerId } });
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Status History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && !Array.isArray(data) ? <LoadingModal /> : null}
        {error ? <ErrorAlert error={error} /> : null}
        {!loading && !error && data?.length === 0 ? (
          <Alert variant="info" className="mb-0">
            No visit status history found
          </Alert>
        ) : null}
        {Array.isArray(data)
          ? data.map((history) => (
              <VisitStatusHistoryCard key={history.id} historyData={history} providerId={providerId} />
            ))
          : null}
      </Modal.Body>
    </Modal>
  );
};

export default VisitStatusHistoryModal;

export function showVisitStatusHistoryModal({ visitId, providerId }) {
  confirmAlert({
    customUI: ({ onClose }) => (
      <VisitStatusHistoryModal onClose={onClose} show visitId={visitId} providerId={providerId} />
    ),
  });
}
