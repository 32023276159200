import { generatePath } from 'react-router-dom';
import MenuItem from './MenuItem';
import ProviderSelect from './ProviderSelect';
import c from './Sidebar.module.scss';
// import providerId from '../../__mock__/providerId';
import useCurrentProvider from '../../modules/currentProvider/useCurrentProvider';
import SidebarController from '../../services/SidebarController';

export default function ProviderRoutesSection({ items }) {
  // const { provider, providerId, loading } = useCurrentProvider();
  const { provider, loading } = useCurrentProvider();
  const providerId = provider?.id;

  return (
    <div>
      <div className={c.providerSelectContainer}>
        <ProviderSelect providerId={providerId} title={loading && !provider ? 'Loading...' : provider?.displayName} />
      </div>
      {providerId
        ? items.map(({ title, path, Icon, exact }) => (
            <MenuItem
              onClick={() => {
                SidebarController.closeSidebar();
              }}
              key={title}
              title={title}
              path={generatePath(path, { providerId })}
              Icon={Icon}
              exact={exact}
            />
          ))
        : null}
    </div>
  );
}
