import { Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import { useRef } from 'react';
import Button from '../common/Button';
import FormikGroup from '../common/formik/FromikGroup';
import FormikFormTextArea from '../common/formik/FormikFormTextArea';
import prepareInitialValues from '../../utils/form';
import FormikFormControl from '../common/formik/FormikFormControl';

const schema = yup.object().shape({
  name: yup.string().label('Template Name').required(),
  subjective: yup.string().label('Template Name').required(),
  objective: yup.string().label('Template Name').required(),
  assessment: yup.string().label('Template Name').required(),
  plan: yup.string().label('Template Name').required(),
});

const defaultValues = {
  name: '',
  subjective: '',
  objective: '',
  assessment: '',
  plan: '',
};

const NoteTemplatesForm = ({ onSubmit, onClose, initialValues = {}, isEdit }) => {
  const initialValuesRef = useRef();
  if (!initialValuesRef.current) {
    initialValuesRef.current = prepareInitialValues(initialValues, defaultValues);
  }
  return (
    <Formik validationSchema={schema} initialValues={initialValuesRef.current} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <FormikForm>
          <FormikGroup label="Template Name">
            <FormikFormControl required name="name" />
          </FormikGroup>
          <FormikGroup className="mt-4" label="Subjective">
            <FormikFormTextArea rows={2} required name="subjective" />
          </FormikGroup>
          <FormikGroup className="mt-4" label="Objective">
            <FormikFormTextArea rows={2} required name="objective" />
          </FormikGroup>
          <FormikGroup className="mt-4" label="Assessment">
            <FormikFormTextArea rows={2} required name="assessment" />
          </FormikGroup>
          <FormikGroup className="mt-4" label="Plan">
            <FormikFormTextArea rows={2} required name="plan" />
          </FormikGroup>
          <div className="mt-5">
            <Row>
              <Col className="d-flex justify-content-end">
                <Button className="me-2" onClick={onClose} variant="secondary">
                  Cancel
                </Button>
                <Button loading={isSubmitting} type="submit" variant="primary">
                  {isEdit ? 'Save' : 'Create'}
                </Button>
              </Col>
            </Row>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default NoteTemplatesForm;
