import { Container, Navbar as BSNavbar, Image, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { List } from 'react-bootstrap-icons';
import Logo from '../../assets/images/logo.svg';
import SidebarController from '../../services/SidebarController';
import c from './Navbar.module.scss';

const Navbar = () => (
  <BSNavbar className={classNames('mb-4', 'd-md-none', 'shadow', c.navbarForMobile)}>
    <Container fluid>
      <Button
        variant="link"
        className={classNames('d-md-none', c.menuButton)}
        onClick={() => {
          SidebarController.openSidebar();
        }}
      >
        <List color="#ffffff" />
      </Button>
      <BSNavbar.Brand className={c.logo}>
        <Image src={Logo} alt="logo" />
      </BSNavbar.Brand>
    </Container>
  </BSNavbar>
);

export default Navbar;
