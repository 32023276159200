import { toast } from 'react-toastify';
import useDeactivateOrActivateUser from '../../hooks/users/useDeactivateOrActivateUser';
import { grabFirstGQLDataResult } from '../../utils/helpers';
import Button from '../common/Button';
import { confirm } from '../common/ConfirmAlert';

const UserTableActionButtons = ({ id, deactivated, refetch, ...rest }) => {
  const [deactivateOrActivateUser, { loading }] = useDeactivateOrActivateUser(deactivated, {
    onCompleted: (response) => {
      toast.success(
        `Account successfully ${grabFirstGQLDataResult(response).deactivated ? 'deactivated' : 'activated'}`
      );
      refetch();
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
  return (
    <Button
      size="sm"
      spinnerVariant="primary"
      style={{ textDecoration: 'none', padding: '0px' }}
      loading={loading}
      onClick={(e) => {
        e.stopPropagation();
        confirm({
          title: deactivated ? 'Activate' : 'Deactivate',
          text: `Do you really want to ${deactivated ? 'activate' : 'deactivate'} this user?`,
          confirmButtonText: deactivated ? 'Activate' : 'Deactivate',
          onConfirm: () => {
            deactivateOrActivateUser({ variables: { userId: id } });
          },
        });
      }}
      variant="link"
      {...rest}
    >
      {deactivated ? 'Activate' : 'Deactivate'}
    </Button>
  );
};

export default UserTableActionButtons;
