import { gql } from '@apollo/client';

const providerSelectOption = gql`
  query providerSelectOption(
    $limit: Int!
    $skip: Int!
    $searchQuery: String
    $excludeWithoutActionItems: Boolean
    $scribeId: ID
  ) {
    scribeProviders(
      limit: $limit
      skip: $skip
      searchQuery: $searchQuery
      excludeWithoutActionItems: $excludeWithoutActionItems
      scribeId: $scribeId
    ) {
      data {
        id
        displayName
      }
    }
  }
`;

export default providerSelectOption;
