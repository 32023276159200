import { useField } from 'formik';
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

const FormikFormTextArea = ({ name, required, ...rest }) => {
  const [field, meta] = useField(name);
  const { onBlur, onChange, value } = field;
  const { error, touched } = meta;
  return (
    <>
      <TextareaAutosize
        name={name}
        value={value}
        onChange={onChange}
        className={`form-control ${touched && error ? 'is-invalid' : ''}`}
        onBlur={onBlur}
        required={required}
        minRows={3}
        {...rest}
      />
      {touched && error && (
        <div>
          <small className="text-danger">{error}</small>
        </div>
      )}
    </>
  );
};

export default FormikFormTextArea;
