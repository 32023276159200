import { format } from 'date-fns';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import DateButtons from '../../components/common/DateButtons';
import Page from '../../components/common/Page';
import { DATE_FORMAT_FOR_DATE_RANGE } from '../../constants/date';
import { getTimeRangeThisWeek } from '../../utils/date';
import useHistoricMetrics from '../../hooks/metrics/useHistoricMetrics';
import useRealTimeMetrics from '../../hooks/metrics/useRealTimeMetrics';
import { createMetricsCards } from '../../utils/helpers';
import HistoricMetrics from '../../components/metrics/HistoricMetrics';
import RealTimeMetrics from '../../components/metrics/RealTimeMetrics';

const Statistics = () => {
  const [dateInterval, setDateInterval] = useState({ id: 'this-week', dateInterval: getTimeRangeThisWeek() });
  const [historicMetricsCards, setHistoricMetricsCards] = useState([]);
  const [realTimeMetricsCards, setRealTimeMetricsCards] = useState([]);
  const [, loadingHistoricMetrics, errorHistoricMetrics] = useHistoricMetrics({
    variables: {
      dateRange: dateInterval.dateInterval,
    },
    onCompleted: ({ numberOfHistoricalMetrics }) => {
      setHistoricMetricsCards(createMetricsCards(numberOfHistoricalMetrics));
    },
  });
  const [, loadingRealTimeMetrics, errorRealTimeMetrics] = useRealTimeMetrics({
    onCompleted: ({ realTimeMetrics }) => {
      setRealTimeMetricsCards(createMetricsCards(realTimeMetrics));
    },
  });
  return (
    <Page
      title="Statistics"
      subtitle={
        dateInterval.dateInterval
          ? `${format(dateInterval.dateInterval.startDate, DATE_FORMAT_FOR_DATE_RANGE)} - ${format(
              dateInterval.dateInterval.endDate,
              DATE_FORMAT_FOR_DATE_RANGE
            )}`
          : 'all time'
      }
    >
      <Row className="mt-3">
        <HistoricMetrics error={errorHistoricMetrics} loading={loadingHistoricMetrics} metrics={historicMetricsCards} />
      </Row>
      <Row className="mt-3">
        <Col>
          <DateButtons setDateInterval={setDateInterval} initialDateInterval={dateInterval} />
        </Col>
      </Row>
      <Row>
        <RealTimeMetrics error={errorRealTimeMetrics} loading={loadingRealTimeMetrics} metrics={realTimeMetricsCards} />
      </Row>
    </Page>
  );
};

export default Statistics;
