export const historicMetricsEvents = {
  VISIT_STUCK_IN_RECORDING: 'VISIT_STUCK_IN_RECORDING',
  VISIT_DOCUMENTED: 'VISIT_DOCUMENTED',
  VISIT_DOCUMENTED_RECORDINGS_DURATION: 'VISIT_DOCUMENTED_RECORDINGS_DURATION',
  VISIT_DOCUMENTED_WAITING_TIME: 'VISIT_DOCUMENTED_WAITING_TIME',
  VISIT_AMEND_UNIQUE: 'VISIT_AMEND_UNIQUE',
  VISIT_SECOND_AMEND_UNIQUE: 'VISIT_SECOND_AMEND_UNIQUE',
  VISIT_COMPLETED: 'VISIT_COMPLETED',
  ACCURACY_RATE: 'ACCURACY_RATE',
};

export const realTimeMetricsEvents = {
  VISIT_STUCK_IN_DOCUMENTATION_LIVE: 'VISIT_STUCK_IN_DOCUMENTATION_LIVE',
  VISIT_SECOND_AMEND_UNIQUE_LIVE: 'VISIT_SECOND_AMEND_UNIQUE_LIVE',
  VISIT_STUCK_IN_AMEND_LIVE: 'VISIT_STUCK_IN_AMEND_LIVE',
  DOCUMENTS_APPROVED: 'DOCUMENTS_APPROVED',
};

export const cardTitle = {
  VISIT_STUCK_IN_RECORDING: 'visits stuck in Recording',
  VISIT_DOCUMENTED: 'visits documented',
  VISIT_DOCUMENTED_RECORDINGS_DURATION: 'documented',
  VISIT_DOCUMENTED_WAITING_TIME: 'avg. waiting time in Document status',
  VISIT_AMEND_UNIQUE: 'unique visits submitted for amendments',
  VISIT_SECOND_AMEND_UNIQUE: 'unique visits submitted for amendments more than once',
  VISIT_COMPLETED: 'notes completed',
  ACCURACY_RATE: 'accuracy rate',
  VISIT_STUCK_IN_RECORDING_LIVE: 'visits currently stuck in Recording',
  VISIT_STUCK_IN_DOCUMENTATION_LIVE: 'visits waiting on Documentation > 24h',
  VISIT_SECOND_AMEND_UNIQUE_LIVE: 'pending visits submitted for Amendments more than once',
  VISIT_STUCK_IN_AMEND_LIVE: 'currently waiting on Amendments for > 24h',
  DOCUMENTS_APPROVED: 'Documents Approved but not Complete',
};

export const valueFormatDuration = [
  historicMetricsEvents.VISIT_DOCUMENTED_RECORDINGS_DURATION,
  historicMetricsEvents.VISIT_DOCUMENTED_WAITING_TIME,
];

export const valueFormatPercentage = [historicMetricsEvents.ACCURACY_RATE];

export default cardTitle;
