// import CurrentProviderContext from './context';
import useSetProviderIdFromRouteParamsEffect from './useSetProviderIdFromRouteParamsEffect';
import useCurrentProvider from './useCurrentProvider';
import ErrorAlert from '../../components/common/ErrorAlert';
import PageLoading from '../../components/common/PageLoading';

export default function ProviderRouteAccessGate({ children }) {
  useSetProviderIdFromRouteParamsEffect();
  const { provider, error, loading } = useCurrentProvider();

  if (error) {
    return (
      <div className="mt-5">
        <ErrorAlert error={error} />
      </div>
    );
  }
  if (loading) {
    return <PageLoading />;
  }
  if (!provider) {
    return (
      <div className="mt-5">
        <ErrorAlert error={{ message: 'Provider not found' }} />
      </div>
    );
  }
  return children;
}

export function withProviderRouteAccessGate(C) {
  const c = (props) => (
    <ProviderRouteAccessGate>
      <C {...props} />
    </ProviderRouteAccessGate>
  );
  c.displayName = 'withProviderRouteAccessGate()';
  return c;
}
