const visitStatuses = {
  SCHEDULED: 'scheduled',
  RECORDING: 'recording',
  DOCUMENTING: 'documenting',
  NOTE_SUBMITTED: 'note_submitted',
  NOTE_REJECTED: 'note_rejected',
  NOTE_APPROVED: 'note_approved',
  COMPLETED: 'completed',
};

export default visitStatuses;
