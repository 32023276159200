import { Route, Switch } from 'react-router-dom';
import paths from './paths';
import AppLayout from '../components/layout/AppLayout';
import Users from '../pages/admin/Users';
import Statistics from '../pages/admin/Statistics';
import ProviderOverview from '../pages/admin/ProviderOverview';
import ScribeOverview from '../pages/admin/ScribeOverview';

const AdminRouter = () => (
  <AppLayout>
    <Switch>
      <Route path={paths.users} exact component={Users} />
      <Route path={paths.statistics} exact component={Statistics} />
      <Route path={paths.providerOverview} exact component={ProviderOverview} />
      <Route path={paths.scribeOverview} exact component={ScribeOverview} />
    </Switch>
  </AppLayout>
);

export default AdminRouter;
