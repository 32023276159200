import { format } from 'date-fns';
import AssignRoleButton from '../components/users/AssignRoleButton';
import AssignScribeOrAdminRole from '../components/users/AssignScribeOrAdminRole';
import UsersStatus from '../components/users/UsersStatus';
import UserTableActionButtons from '../components/users/UserTableActionButtons';
import { DATE_FORMAT } from './date';
import roles from './roles';
import userStatuses from './userStatuses';
import c from '../components/users/UserTableColumns.module.scss';

const globalColumns = [
  { id: 'displayName', title: 'Name', cell: (row) => row.displayName },
  { id: 'email', title: 'Email', cell: (row) => row.email },
  {
    id: 'status',
    title: 'Status',
    cell: ({ deactivated }) => <UsersStatus status={deactivated ? userStatuses.INACTIVE : userStatuses.ACTIVE} />,
  },
];

export const providersColumns = [
  ...globalColumns,
  { id: 'patients', title: 'Patients', cell: (row) => row.totalPatients },
  { id: 'scribes', title: 'Scribes', cell: (row) => row.numberOfScribes },
  {
    id: 'createdAt',
    title: 'Registration date',
    cell: (row) => format(new Date(row.user.createdAt), DATE_FORMAT),
  },
  {
    id: 'actions',
    title: 'Actions',
    cell: ({ id, deactivated }, { refetch }) => (
      <UserTableActionButtons id={id} deactivated={deactivated} refetch={refetch} />
    ),
  },
];

export const scribesColumns = [
  ...globalColumns,
  { id: 'providers', title: 'Providers', cell: (row) => row.numberOfProviders },
  { id: 'documented', title: 'Documented', cell: (row) => row.numberOfDocumented },
  { id: 'createdAt', title: 'Registration date', cell: (row) => format(new Date(row.user.createdAt), DATE_FORMAT) },
  {
    id: 'actions',
    title: 'Actions',
    cell: ({ id, deactivated }, { refetch }) => (
      <>
        <UserTableActionButtons id={id} deactivated={deactivated} refetch={refetch} />
        <span className={c.secondButton}>
          <AssignScribeOrAdminRole isScribe userId={id} refetch={refetch} />
        </span>
      </>
    ),
  },
];

const adminsColumns = [
  ...globalColumns,
  { id: 'createdAt', title: 'Registration date', cell: (row) => format(new Date(row.createdAt), DATE_FORMAT) },
  {
    id: 'lastLogin',
    title: 'Last login',
    cell: (row) => (row.lastLoginDate ? format(new Date(row.lastLoginDate), DATE_FORMAT) : 'N/A'),
  },
  {
    id: 'actions',
    title: 'Actions',
    cell: ({ id, deactivated }, { refetch }) => (
      <>
        <UserTableActionButtons id={id} deactivated={deactivated} refetch={refetch} />
        <span className={c.secondButton}>
          <AssignScribeOrAdminRole userId={id} refetch={refetch} />
        </span>
      </>
    ),
  },
];

const guestColumns = [
  ...globalColumns.filter((role) => role.id !== 'status'),
  {
    id: 'actions',
    title: 'Actions',
    cell: ({ id, deactivated }, { refetch }) => (
      <>
        <AssignRoleButton userId={id} refetch={refetch} />
        <span className={c.secondButton}>
          <UserTableActionButtons id={id} deactivated={deactivated} refetch={refetch} />
        </span>
      </>
    ),
  },
];

const usersTableColumns = [
  { id: roles.PROVIDER, column: providersColumns },
  { id: roles.SCRIBE, column: scribesColumns },
  { id: roles.ADMIN, column: adminsColumns },
  { id: roles.GUEST, column: guestColumns },
];

export default usersTableColumns;
