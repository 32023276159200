class DraftStorage {
  LOCAL_STORAGE_KEY = 'DRAFT_STORAGE';

  drafts = {};

  constructor() {
    this._hydrate();
  }

  reset = () => {
    this.drafts = {};
    this._persist();
  };

  updateDraft = ({ id, values }) => {
    this.drafts[id] = { id, values, date: new Date() };
    this._persist();
  };

  deleteDraft = (id) => {
    this.drafts[id] = undefined;
    this._persist();
  };

  getDraft = (id) => this.drafts[id];

  _persist = () => {
    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(this.drafts));
  };

  _hydrate = () => {
    const serializedDrafts = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (serializedDrafts) {
      this.drafts = JSON.parse(serializedDrafts);
    }
  };
}

export default new DraftStorage();
