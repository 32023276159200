import classNames from 'classnames';
import { Card } from 'react-bootstrap';
import usePhrases from '../../hooks/visits/usePhrases';
import c from './Transcript.module.scss';
import ErrorAlert from './ErrorAlert';
import Loading from './Loading';

const Transcript = ({ providerId, recordingId }) => {
  const [phrases, loading, error] = usePhrases({ variables: { providerId, recordingId } });
  if (loading && !phrases) {
    return (
      <Card.Body>
        <Loading count={10} />
      </Card.Body>
    );
  }
  if (error && !phrases) {
    return <ErrorAlert error={error} />;
  }
  return (
    <Card.Body>
      {error ? <ErrorAlert error={error} /> : null}
      {phrases.length === 0 ? 'Transcript is empty' : null}
      {phrases.map((phrase) => (
        <div className={classNames(c.row, phrase.speaker === 1 ? c.speaker1 : c.speaker2)} key={phrase.id}>
          <Card className={classNames(c.msg, 'p-1')} bg={phrase.speaker > 1 ? 'dark' : 'primary'} text="white">
            <span>{phrase.text}</span>
          </Card>
        </div>
      ))}
    </Card.Body>
  );
};

export default Transcript;
