import { useHistory } from 'react-router-dom';
import providers from '../../../graphql/queries/providers';
import usePaginatedQuery from '../../../hooks/common/usePaginatedQuery';
import { providersColumns } from '../../../constants/userTableColumns';
import TableWithLoadingAndErrorAlert from '../TableWithLoadingAndErrorAlert';

const ProvidersTable = ({ filter, keyExtractor, handleClickRow, activeTab }) => {
  const history = useHistory();
  const { data, loading, error, totalPages, page, refetch, goToNPage } = usePaginatedQuery(providers, {
    itemsPerPage: 10,
    fetchPolicy: 'cache-and-network',
    variables: {
      userStatus: filter === 'ANY' ? null : filter,
    },
  });
  return (
    <TableWithLoadingAndErrorAlert
      error={error}
      loading={loading && !data}
      data={data}
      tableProps={{
        loading,
        keyExtractor,
        columns: providersColumns,
        noData: 'No users found',
        onClickRow: (row) => {
          handleClickRow(row.id, activeTab, history);
        },
        context: { refetch },
      }}
      tablePaginationProps={{ page, totalPages, goToNPage }}
    />
  );
};

export default ProvidersTable;
