import { gql } from '@apollo/client';

const PHRASE_FRAGMENT = gql`
  fragment phrase on Phrase {
    id
    text
    speaker
  }
`;

export default PHRASE_FRAGMENT;
