/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Link } from 'react-router-dom';
import { Image, Button } from 'react-bootstrap';
import cn from 'classnames';
import { X } from 'react-bootstrap-icons';
import { useState, useEffect } from 'react';
import Logo from '../../assets/images/logo.svg';
import paths from '../../router/paths';
import c from './Sidebar.module.scss';
import MenuItem from './MenuItem';
import useMeQuery from '../../hooks/auth/useMeQuery';
import { adminSidebarItems, globalSidebarItems, providerSidebarItems } from './config';
import UserDropdown from './UserDropdown';
import ProviderRoutesSection from './ProviderRoutesSection';
// import useWindowDimensions from '../../hooks/common/useWindowDimensions';
import roles from '../../constants/roles';
import SidebarController from '../../services/SidebarController';
import Backdrop from '../common/Backdrop';

// const md = 768;

const Sidebar = () => {
  const [currentUser, loading] = useMeQuery();
  const [isShowSidebarForSmallScreen, setIsShowSidebarForSmallScreen] = useState(false);
  useEffect(() => {
    SidebarController.setChangeIsShowSidebar(setIsShowSidebarForSmallScreen);
    return () => {
      SidebarController.resetChangeIsShowSidebar();
    };
  }, []);

  // const windowDimensions = useWindowDimensions();
  // useEffect(() => {
  //   if (windowDimensions.width >= md && isShowSidebarForSmallScreen) {
  //     setIsShowSidebarForSmallScreen(false);
  //   }
  // }, [isShowSidebarForSmallScreen, windowDimensions]);
  return (
    <>
      <Backdrop isShowSidebarForSmallScreen={isShowSidebarForSmallScreen} />
      <div className={cn(c.sidebarPlaceholder)}>
        <div className={cn(c.sidebarFixed, !isShowSidebarForSmallScreen && c.hide)}>
          {isShowSidebarForSmallScreen ? (
            <Button
              variant="link"
              className={c.closeButton}
              onClick={() => {
                SidebarController.closeSidebar();
              }}
            >
              <X color="#ffffff" />
            </Button>
          ) : null}
          {!loading ? (
            <div className={c.sidebar}>
              <div className="text-center">
                <Link to={paths.home}>
                  <Image className={c.sidebarLogo} src={Logo} />
                </Link>
              </div>

              {currentUser.role === roles.SCRIBE ? (
                <>
                  <div>
                    {globalSidebarItems.map(({ title, path, Icon, exact }) => (
                      <MenuItem
                        onClick={() => {
                          SidebarController.closeSidebar();
                        }}
                        key={title}
                        title={title}
                        path={path}
                        Icon={Icon}
                        exact={exact}
                      />
                    ))}
                  </div>
                  <ProviderRoutesSection items={providerSidebarItems} />
                </>
              ) : null}
              {currentUser.role === roles.ADMIN ? (
                <div>
                  {adminSidebarItems.map(({ title, path, Icon, exact }) => (
                    <MenuItem
                      onClick={() => {
                        SidebarController.closeSidebar();
                      }}
                      key={title}
                      title={title}
                      path={path}
                      Icon={Icon}
                      exact={exact}
                    />
                  ))}
                </div>
              ) : null}

              <div className={c.userDropdownContainer}>
                <UserDropdown />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
