import { EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { authorities } from '../../components/auth/MsalAuthWrapper';
import ErrorAlert from '../../components/common/ErrorAlert';
import FullscreenLoading from '../../components/common/FullscreenLoading';
import paths from '../../router/paths';

const authRequest = {
  authority: authorities.RESET_PASSWORD,
  redirectUri: `${window.location.origin}${paths.resetPassword}`,
};
export default function ResetPassword() {
  const { instance, inProgress } = useMsal();
  const [error, setError] = useState(null);
  const [isSuccess, setIsSuccess] = useState(null);

  useEffect(() => {
    if (inProgress === 'none') {
      instance.loginRedirect(authRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        setError(event.error);
        setIsSuccess(false);
      }
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        setError(null);
        setIsSuccess(true);
      }
    });
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  if (error) {
    return (
      <ErrorAlert error title={error.name}>
        {error.errorMessage}
      </ErrorAlert>
    );
  }
  if (isSuccess) {
    return <Alert variant="success">Password changed successfully. You can close the window</Alert>;
  }
  return <FullscreenLoading />;
}
