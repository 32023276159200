import { Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import { useRef } from 'react';
import FormikGroup from '../common/formik/FromikGroup';
import FormikFormSelect from '../common/formik/FormikFormSelect';
import { visitReasonTitleMapping } from '../../constants/visitReasons';
import FormikFormDate from '../common/formik/FormikFormDate';
import FormikFormTextArea from '../common/formik/FormikFormTextArea';
import prepareInitialValues from '../../utils/form';
import Button from '../common/Button';

const schema = yup.object().shape({
  visitDate: yup.date().label('Date').required(),
  reason: yup.mixed().label('Reason for Visit').oneOf(Object.keys(visitReasonTitleMapping)).required(),
  complaint: yup.string().label('Chief Complaint').required(),
});

const defaultValues = {
  visitDate: '',
  reason: '',
  complaint: '',
};

const VisitForm = ({ onSubmit, onClose, initialValues = {}, isEdit }) => {
  const initialValuesRef = useRef();
  if (!initialValuesRef.current) {
    initialValuesRef.current = prepareInitialValues(initialValues, defaultValues);
  }
  return (
    <Formik validationSchema={schema} initialValues={initialValuesRef.current} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <FormikForm>
          <FormikGroup label="Date">
            <FormikFormDate isEnableTimeSelect required name="visitDate" />
          </FormikGroup>
          <FormikGroup className="mt-4" label="Reason for Visit">
            <FormikFormSelect required name="reason">
              <option value="" aria-label="empty">
                {' '}
              </option>
              {Object.entries(visitReasonTitleMapping).map(([value, title]) => (
                <option key={value} value={value}>
                  {title}
                </option>
              ))}
            </FormikFormSelect>
          </FormikGroup>
          <FormikGroup className="mt-4" label="Chief Complaint">
            <FormikFormTextArea required name="complaint" />
          </FormikGroup>
          <div className="mt-5">
            <Row>
              <Col className="d-flex justify-content-end">
                <Button className="me-2" onClick={onClose} variant="secondary">
                  Cancel
                </Button>
                <Button loading={isSubmitting} type="submit" variant="primary">
                  {isEdit ? 'Save' : 'Submit'}
                </Button>
              </Col>
            </Row>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default VisitForm;
