import { gql } from '@apollo/client';
import NOTE_TEMPLATE from '../fragments/noteTemplate';

const editNoteTemplateMutation = gql`
  mutation updateNoteTemplate($data: UpdateNoteTemplateInput!, $id: ID!) {
    updateNoteTemplate(data: $data, id: $id) {
      ...noteTemplate
    }
  }
  ${NOTE_TEMPLATE}
`;

export default editNoteTemplateMutation;
