import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { format } from 'date-fns';
import { useParams, useHistory, generatePath } from 'react-router-dom';
import { find, orderBy } from 'lodash';
import usePaginatedQuery from '../../hooks/common/usePaginatedQuery';
import visitsTableQuery from '../../graphql/queries/visitsTable';
import useDebouncedValue from '../../hooks/common/useDebouncedValue';
import usePatientSelectOption from '../../hooks/common/usePatientSelectOption';
import useProviderSelectOption from '../../hooks/common/useProviderSelectOption';

import Page from '../../components/common/Page';
import Tabs from '../../components/common/Tabs';
import Select from '../../components/common/Select';
import PageLoading from '../../components/common/PageLoading';
import ErrorAlert from '../../components/common/ErrorAlert';
import Table from '../../components/common/Table';
import TablePagination from '../../components/common/Pagination';
import VisitStatus from '../../components/visits/VisitStatus';
import visitStatuses from '../../constants/visitStatuses';
import useActionItemCounters from '../../hooks/documents/useActionItemCounters';
import { DATE_TIME_FORMAT } from '../../constants/date';

import paths from '../../router/paths';
import { customDateFormat } from '../../utils/date';

const initialTabs = [
  {
    key: 'all',
    label: 'All',
    statuses: undefined,
  },
  {
    key: 'recording',
    label: 'Scheduled',
    statuses: [visitStatuses.RECORDING, visitStatuses.SCHEDULED],
  },
  {
    key: 'documenting',
    label: 'Document',
    statuses: [visitStatuses.DOCUMENTING],
    counter: 0,
  },
  {
    key: 'note_submitted',
    label: 'Waiting',
    statuses: [visitStatuses.NOTE_SUBMITTED],
  },
  {
    key: 'note_rejected',
    label: 'Amend',
    counter: 0,
    statuses: [visitStatuses.NOTE_REJECTED],
  },
  {
    key: 'note_approved',
    label: 'Approved',
    counter: 0,
    statuses: [visitStatuses.NOTE_APPROVED],
  },
  {
    key: 'completed',
    label: 'Completed',
    statuses: [visitStatuses.COMPLETED],
  },
];

const getOptions = ({ data, hasProviderId }) => {
  if (!data) return [];

  const opt = orderBy(
    data.map((el) => ({ value: el.id, label: hasProviderId ? el.fullName : el.displayName })),
    ['label'],
    // eslint-disable-next-line comma-dangle
    ['asc']
  );

  const uniqueOptions = opt.reduce((unique, o) => {
    if (!unique.some((obj) => obj.label === o.label && obj.value === o.value)) {
      unique.push(o);
    }
    return unique;
  }, []);

  return uniqueOptions;
};

const columns = [
  { id: 'provider', title: 'Provider', cell: (row) => row.provider.displayName },
  { id: 'patient', title: 'Patient', cell: (row) => row.patient.fullName },
  { id: 'date', title: 'Date', cell: (row) => format(new Date(row.visitDate), DATE_TIME_FORMAT) },
  { id: 'status', title: 'Status', cell: ({ status }) => <VisitStatus status={status} /> },
  { id: 'recordings', title: 'Recordings', cell: (row) => row.recordingsNumber },
  { id: 'lastUpdated', title: 'Last updated', cell: (row) => customDateFormat(new Date(row.updatedAt)) },
];

const keyExtractor = (row) => row.id;

const Documents = () => {
  const [filterByProviderId, setFilterByProviderId] = useState('');
  const [filterByPatientId, setFilterByPatientId] = useState('');
  const [activeTab, setActiveTab] = useState('all');

  const { providerId } = useParams();
  const history = useHistory();
  const [tabs, setTabs] = useState(initialTabs);

  const filteredColumns = () => {
    if (providerId) {
      return columns.filter((el) => el.id !== 'provider');
    }

    return columns;
  };

  const [searchQuery] = useDebouncedValue();

  const { patientOptions } = usePatientSelectOption({ searchQuery, providerId });

  const { providerOptions } = useProviderSelectOption({ searchQuery });

  const { data, loading, error, totalPages, page, goToNPage } = usePaginatedQuery(visitsTableQuery, {
    itemsPerPage: 10,
    fetchPolicy: 'cache-and-network',
    variables: {
      searchQuery,
      providerId: providerId || filterByProviderId,
      patientId: filterByPatientId !== '' ? filterByPatientId : null,
      statuses: find(tabs, { key: activeTab })?.statuses || undefined,
    },
  });

  useActionItemCounters({
    fetchPolicy: 'cache-and-network',
    variables: {
      providerId: providerId || filterByProviderId,
      patientId: filterByPatientId !== '' ? filterByPatientId : null,
    },
    onCompleted: ({ actionItemCounters }) => {
      const newTabs = tabs.map((tab) => {
        if (tab.key === visitStatuses.DOCUMENTING) {
          return { ...tab, counter: actionItemCounters.documenting };
        }
        if (tab.key === visitStatuses.NOTE_APPROVED) {
          return { ...tab, counter: actionItemCounters.note_approved };
        }
        if (tab.key === visitStatuses.NOTE_REJECTED) {
          return { ...tab, counter: actionItemCounters.note_rejected };
        }
        return tab;
      });
      setTabs(newTabs);
    },
  });

  return (
    <Page title={providerId ? 'Documents' : 'All Documents'}>
      <Row className="mb-4">
        <Col>
          <Tabs tabs={tabs} activeTab={activeTab} onActiveTabChange={setActiveTab} />
        </Col>
        <Col md={4}>
          <h6>{providerId ? 'Patient' : 'Provider'}</h6>
          <Select
            clearable
            emptyOptionLabel={`Select ${providerId ? 'patient' : 'provider'}...`}
            options={getOptions({ data: providerId ? patientOptions : providerOptions, hasProviderId: !!providerId })}
            value={providerId ? filterByPatientId : filterByProviderId}
            onChange={providerId ? setFilterByPatientId : setFilterByProviderId}
          />
        </Col>
      </Row>
      {error ? <ErrorAlert error={error} /> : null}
      {loading && !Array.isArray(data) ? <PageLoading /> : null}
      {Array.isArray(data) ? (
        <>
          <Table
            loading={loading}
            columns={filteredColumns()}
            keyExtractor={keyExtractor}
            data={data}
            onClickRow={(row) => {
              history.push(generatePath(paths.visitOverview, { providerId: row.providerId, visitId: row.id }));
            }}
            noData={providerId ? 'No patients found' : 'No providers found'}
          />
          <TablePagination {...{ page, totalPages, goToNPage }} />
        </>
      ) : null}
    </Page>
  );
};

export default Documents;
