import classNames from 'classnames';
import { Accordion, Card } from 'react-bootstrap';
import { customDateFormat } from '../../utils/date';
import NoteOverview from './NoteOverview';
import ProviderFeedback from './ProviderFeedback';
import c from './VisitStatusHistoryCard.module.scss';

const VisitStatusHistoryCard = ({ historyData, providerId }) => (
  <Card className={classNames('mb-3', c.visitStatusHistoryCard)}>
    <Card.Body>
      <Card.Text className={classNames('text-muted', c.date)}>{customDateFormat(new Date(historyData.date))}</Card.Text>
      <Card.Text>{historyData.formattedMessage}</Card.Text>
      {historyData.amendments ? (
        <Accordion>
          <ProviderFeedback recordings={historyData.amendments} providerId={providerId} isStatusHistory />
        </Accordion>
      ) : null}
      {historyData.soap ? (
        <Accordion>
          <NoteOverview
            subjective={historyData.soap.subjective}
            objective={historyData.soap.objective}
            assessment={historyData.soap.assessment}
            plan={historyData.soap.plan}
            isStatusHistory
          />
        </Accordion>
      ) : null}
    </Card.Body>
  </Card>
);

export default VisitStatusHistoryCard;
