import { generatePath, useParams } from 'react-router-dom';
import { Row, Col, Accordion } from 'react-bootstrap';
import useCurrentProvider from '../../modules/currentProvider/useCurrentProvider';
import useVisit from '../../hooks/common/useVisit';

import Page from '../../components/common/Page';
import Breadcrumb from '../../components/common/Breadcrumb';
import ErrorAlert from '../../components/common/ErrorAlert';
import paths from '../../router/paths';

import SOAPForm from '../../components/visits/SOAPForm';
import VisitOverviewCard from '../../components/visits/VIsitOverviewCard';
import PatientOverviewCard from '../../components/patients/PatientOverviewCard';
import VisitRecordings from '../../components/visits/VisitRecordings';
import visitStatuses from '../../constants/visitStatuses';
import SOAPCard from '../../components/visits/SOAPCard';
import VisitStatus from '../../components/visits/VisitStatus';
import ProviderFeedback from '../../components/visits/ProviderFeedback';
import NoteOverview from '../../components/visits/NoteOverview';
import SOAPLoading from '../../components/visits/SOAPLoading';
import { customDateFormat } from '../../utils/date';

const Visits = () => {
  const { providerId, visitId } = useParams();

  const { provider } = useCurrentProvider();

  const { visit, loading, error } = useVisit({ providerId, visitId });

  const links = [
    {
      path: paths.providers,
      title: 'Providers',
    },
    {
      path: generatePath(paths.providerDashboard, { providerId }),
      title: provider?.displayName || 'Provider',
    },
    {
      path: generatePath(paths.patients, { providerId }),
      title: 'Patients',
    },
    {
      path: visit?.patient.id ? generatePath(paths.patientOverview, { providerId, patientId: visit.patient.id }) : null,
      title: visit?.patient.fullName || 'Patient',
    },
  ];

  // eslint-disable-next-line operator-linebreak
  const isSoapFormDisabled =
    visit?.status !== visitStatuses.DOCUMENTING && visit?.status !== visitStatuses.NOTE_REJECTED;
  const isSoapFormDisplayed = [
    visitStatuses.SCHEDULED,
    visitStatuses.RECORDING,
    visitStatuses.DOCUMENTING,
    visitStatuses.NOTE_REJECTED,
  ].includes(visit?.status);

  return (
    <Page title="Visit Overview">
      <div className="mb-2">
        <Breadcrumb
          links={links}
          activeLinkTitle={`Visit ${visit?.visitDate ? customDateFormat(new Date(visit?.visitDate)) : ''}`}
        />
      </div>
      <Row>
        <ErrorAlert error={error} />
        {!visit && !loading && !error ? <ErrorAlert error={{ message: 'Visit not found' }} /> : null}
      </Row>
      <Row>
        <Col md={6} className="mt-4">
          <Row>
            <Col md={6} className="mb-4">
              <VisitOverviewCard visit={visit} loading={visit ? false : loading} providerId={providerId} />
            </Col>
            <Col md={6} className="mb-4">
              <PatientOverviewCard
                patient={visit?.patient}
                loading={visit ? false : loading}
                hideDeleteButton
                hideEditButton
                brief
              />
            </Col>
            <Col md={12} className="mb-4">
              {visit?.status === visitStatuses.NOTE_REJECTED ? (
                <Accordion>
                  <ProviderFeedback recordings={visit.soap.amendments} providerId={providerId} />
                  <NoteOverview
                    subjective={visit.soap.subjective}
                    objective={visit.soap.objective}
                    assessment={visit.soap.assessment}
                    plan={visit.soap.plan}
                  />
                </Accordion>
              ) : null}
              <VisitRecordings
                recordings={visit?.recordings}
                recordingsDurationSeconds={visit?.recordingsDurationSeconds}
                providerId={providerId}
                loading={loading}
              />
            </Col>
          </Row>
        </Col>
        {loading && !visit ? (
          <Col md={6} className="mt-4">
            <SOAPLoading />
          </Col>
        ) : null}
        {!visit ? null : (
          <Col md={6} className="mt-4">
            {!isSoapFormDisplayed ? (
              <SOAPCard
                subjective={visit.soap?.subjective}
                objective={visit.soap?.objective}
                assessment={visit.soap?.assessment}
                plan={visit.soap?.plan}
                isApproved={visit.status === visitStatuses.NOTE_APPROVED}
                visitId={visitId}
                providerId={providerId}
              />
            ) : (
              <SOAPForm
                initialValues={{
                  subjective: visit.soap?.subjective || '',
                  objective: visit.soap?.objective || '',
                  assessment: visit.soap?.assessment || '',
                  plan: visit.soap?.plan || '',
                  saveAsNewNoteTemplate: false,
                  noteTemplateName: '',
                }}
                patientId={visit?.patient.id}
                submitDisabled={isSoapFormDisabled}
                visitId={visit.id}
                providerId={providerId}
                loading={loading}
                formAlert={
                  isSoapFormDisabled ? (
                    <>
                      The Note Overview form can be submitted only when the visit is in{' '}
                      <VisitStatus status={visitStatuses.DOCUMENTING} /> or{' '}
                      <VisitStatus status={visitStatuses.NOTE_REJECTED} /> status, but you can start drafting the note
                      overview which is autosaved in your browser
                    </>
                  ) : null
                }
              />
            )}
          </Col>
        )}
      </Row>
    </Page>
  );
};

export default Visits;
