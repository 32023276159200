import { gql } from '@apollo/client';
import VISIT_FOR_EDIT_FRAGMENT from '../fragments/visitForEdit';

const newVisitMutation = gql`
  mutation createVisit($data: CreateVisitInput!, $providerId: ID, $patientId: ID!) {
    createVisit(data: $data, providerId: $providerId, patientId: $patientId) {
      ...visitForEdit
    }
  }
  ${VISIT_FOR_EDIT_FRAGMENT}
`;

export default newVisitMutation;
