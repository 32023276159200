import { confirmAlert } from '@uplab/react-confirm-alert';
import { toast } from 'react-toastify';
import useNewVisit from '../../hooks/visits/useNewVisit';
import VisitForm from './VisitForm';
import { ReactComponent as NewVisitTitleIcon } from '../../assets/icons/new-visit-title.svg';
import FormModal from '../common/FormModal';

const NewVisit = ({ show, onClose, providerId, patientId, onCompleted }) => {
  const [newVisit, { error }] = useNewVisit({
    onCompleted: (data) => {
      onClose();
      if (typeof onCompleted === 'function') onCompleted(data);
    },
  });

  const onSubmit = async (data) => {
    await newVisit({ variables: { data, providerId, patientId } });
    toast.success('Visit successfully created');
  };

  return (
    <FormModal
      show={show}
      onClose={onClose}
      onSubmit={onSubmit}
      TitleIcon={NewVisitTitleIcon}
      Form={VisitForm}
      error={error}
    />
  );
};

export default NewVisit;

export function showNewVisitModal({ providerId, patientId, onCompleted }) {
  confirmAlert({
    customUI: ({ onClose }) => (
      <NewVisit onClose={onClose} show providerId={providerId} onCompleted={onCompleted} patientId={patientId} />
    ),
  });
}
