import { gql } from '@apollo/client';

const PROVIDER = gql`
  fragment provider on Provider {
    id
    displayName
    totalPatients
    numberOfScribes
    deactivated
    email
    user {
      role
      createdAt
    }
  }
`;

export default PROVIDER;
