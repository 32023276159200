import { useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { format } from 'date-fns';
import { useHistory, generatePath } from 'react-router-dom';
import DateButtons from '../common/DateButtons';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import Page from '../common/Page';
import Table from '../common/Table';
import { showAssignScribeProviderModal } from './AssignScribeProviderModal';
import usePaginationQuery from '../../hooks/common/usePaginatedQuery';
import TablePagination from '../common/Pagination';
import scribesByProviderId from '../../graphql/queries/scribesByProviderId';
import providersByScribeId from '../../graphql/queries/providersByScribeId';
import ErrorAlert from '../common/ErrorAlert';
import UnassignButton from './UnassignButton';
import useUserById from '../../hooks/users/useUserById';
import Loading from '../common/Loading';
import { customDateFormat, getTimeRangeThisWeek } from '../../utils/date';
import { DATE_FORMAT_FOR_DATE_RANGE } from '../../constants/date';
import paths from '../../router/paths';
import useHistoricMetrics from '../../hooks/metrics/useHistoricMetrics';
import { createMetricsCards } from '../../utils/helpers';
import HistoricMetrics from '../metrics/HistoricMetrics';
import RealTimeMetrics from '../metrics/RealTimeMetrics';
import useRealTimeMetrics from '../../hooks/metrics/useRealTimeMetrics';

const keyExtractor = (row) => row.id;

const UserOverview = ({ isProviderOverview, userId }) => {
  const [dateInterval, setDateInterval] = useState({ id: 'this-week', dateInterval: getTimeRangeThisWeek() });
  const columns = useRef([
    {
      id: 'displayName',
      title: `${isProviderOverview ? 'Scribe' : 'Provider'} name`,
      cell: ({ user }) => user.displayName,
    },
    { id: 'email', title: `${isProviderOverview ? 'Scribe' : 'Provider'} email`, cell: ({ user }) => user.email },
    { id: 'visitDocumented', title: 'Visits documented', cell: () => 'N/A' },
    {
      id: 'assignmentDate',
      title: 'Date of Assignment',
      cell: ({ createdAt }) => customDateFormat(new Date(createdAt)),
    },
    {
      id: 'actions',
      title: 'Actions',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ id }, context) => <UnassignButton id={id} refetch={context.refetch} />,
    },
  ]);

  const {
    data: tableData,
    error: tableError,
    loading: tableLoading,
    totalPages,
    page,
    goToNPage,
    refetch,
  } = usePaginationQuery(isProviderOverview ? scribesByProviderId : providersByScribeId, {
    itemsPerPage: 10,
    fetchPolicy: 'cache-and-network',
    variables: isProviderOverview ? { providerId: userId } : { scribeId: userId },
  });

  const history = useHistory();

  const [data, loading, error] = useUserById({ variables: { userId } });

  const [historicMetricsCards, setHistoricMetricsCards] = useState([]);
  const [realTimeMetricsCards, setRealTimeMetricsCards] = useState([]);

  const [, loadingHistoricMetrics, errorHistoricMetrics, refetchHistoricMetrics] = useHistoricMetrics({
    variables: {
      providerId: isProviderOverview ? userId : null,
      scribeId: isProviderOverview ? null : userId,
      dateRange: dateInterval.dateInterval,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ numberOfHistoricalMetrics }) => {
      setHistoricMetricsCards(createMetricsCards(numberOfHistoricalMetrics));
    },
  });

  const [, loadingRealTimeMetrics, errorRealTimeMetrics, refetchRealTimeMetrics] = useRealTimeMetrics({
    variables: {
      providerId: isProviderOverview ? userId : null,
      scribeId: isProviderOverview ? null : userId,
      dateRange: dateInterval.dateInterval,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ realTimeMetrics }) => {
      setRealTimeMetricsCards(createMetricsCards(realTimeMetrics));
    },
  });

  return (
    <Page title={isProviderOverview ? 'Provider Overview' : 'Scribe Overview'}>
      <Row>
        <Col md="5">
          {error ? <ErrorAlert error={error} /> : null}
          {loading && !data ? <Loading /> : null}
          {data ? (
            <>
              <h3>
                <strong>{data?.displayName}</strong>
              </h3>
              <p className="text-muted">{data.email}</p>
            </>
          ) : null}
        </Col>
        <Col>
          <Col className="d-flex justify-content-end">
            <DateButtons setDateInterval={setDateInterval} initialDateInterval={dateInterval} />
          </Col>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md="3">
          <h5>
            <strong>Usage</strong>
          </h5>
        </Col>
        <Col md="6">
          <p className="text-muted">
            {dateInterval.dateInterval
              ? `${format(dateInterval.dateInterval.startDate, DATE_FORMAT_FOR_DATE_RANGE)} - ${format(
                  dateInterval.dateInterval.endDate,
                  DATE_FORMAT_FOR_DATE_RANGE
                )}`
              : 'all time'}
          </p>
        </Col>
      </Row>
      <Row className="mb-5">
        <HistoricMetrics error={errorHistoricMetrics} loading={loadingHistoricMetrics} metrics={historicMetricsCards} />
        <RealTimeMetrics error={errorRealTimeMetrics} loading={loadingRealTimeMetrics} metrics={realTimeMetricsCards} />
      </Row>
      <Row className="mb-4">
        <Col md="6">
          <h4>
            <strong>{`${isProviderOverview ? 'Scribe' : 'Provider'} Assignments`}</strong>
          </h4>
        </Col>
        <Col className="d-flex justify-content-end mt-2 mt-md-0">
          <Button
            onClick={() => {
              showAssignScribeProviderModal({
                isProviderPage: isProviderOverview,
                userId,
                refetch: () => {
                  refetch();
                  refetchHistoricMetrics();
                  refetchRealTimeMetrics();
                },
              });
            }}
            size="sm"
          >
            <AddIcon />
            {` Assign ${isProviderOverview ? 'Scribe' : 'to Provider'}`}
          </Button>
        </Col>
      </Row>
      {tableError ? <ErrorAlert error={tableError} /> : null}
      {tableLoading && !tableData ? <Loading isTable /> : null}
      {Array.isArray(tableData) ? (
        <Table
          columns={columns.current}
          keyExtractor={keyExtractor}
          data={tableData}
          loading={tableLoading}
          noData={isProviderOverview ? 'No Scribe found' : 'No Provider found'}
          context={{
            refetch: () => {
              refetch();
              refetchHistoricMetrics();
              refetchRealTimeMetrics();
            },
          }}
          onClickRow={({ user }) => {
            if (!tableLoading) {
              history.push(
                generatePath(
                  isProviderOverview ? paths.scribeOverview : paths.providerOverview,
                  isProviderOverview ? { scribeId: user.id } : { providerId: user.id }
                )
              );
            }
          }}
        />
      ) : null}
      <TablePagination {...{ totalPages, page, goToNPage }} />
    </Page>
  );
};

export default UserOverview;
