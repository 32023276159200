import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { format } from 'date-fns';
import Page from '../../components/common/Page';
import ActionItems from '../../components/dashboard/ActionItems';
import Notifications from '../../components/dashboard/Notifications';
import notifications from '../../__mock__/notificationsData';
import providerActionItemsData from '../../__mock__/providerActionItemsData';
import useCurrentProvider from '../../modules/currentProvider/useCurrentProvider';
import DateButtons from '../../components/common/DateButtons';
import { DATE_FORMAT_FOR_DATE_RANGE } from '../../constants/date';
import useHistoricMetrics from '../../hooks/metrics/useHistoricMetrics';
import { createMetricsCards } from '../../utils/helpers';
import { getTimeRangeThisWeek } from '../../utils/date';
import useRealTimeMetrics from '../../hooks/metrics/useRealTimeMetrics';
import HistoricMetrics from '../../components/metrics/HistoricMetrics';
import RealTimeMetrics from '../../components/metrics/RealTimeMetrics';

const ProviderDashboard = () => {
  const { provider } = useCurrentProvider();
  const [dateInterval, setDateInterval] = useState({ id: 'this-week', dateInterval: getTimeRangeThisWeek() });
  const [historicMetricsCards, setHistoricMetricsCards] = useState([]);
  const [realTimeMetricsCards, setRealTimeMetricsCards] = useState([]);
  const [, loadingHistoricMetrics, errorHistoricMetrics] = useHistoricMetrics({
    variables: {
      dateRange: dateInterval.dateInterval,
      providerId: provider.id,
    },
    onCompleted: ({ numberOfHistoricalMetrics }) => {
      setHistoricMetricsCards(createMetricsCards(numberOfHistoricalMetrics));
    },
  });
  const [, loadingRealTimeMetrics, errorRealTimeMetrics] = useRealTimeMetrics({
    variables: { providerId: provider.id },
    onCompleted: ({ realTimeMetrics }) => {
      setRealTimeMetricsCards(createMetricsCards(realTimeMetrics));
    },
  });
  return (
    <Page title="Provider Dashboard">
      <Row>
        <h3>
          <strong>{provider?.displayName || 'Provider'}</strong>
        </h3>
        <p className="text-muted">
          {dateInterval.dateInterval
            ? `${format(dateInterval.dateInterval.startDate, DATE_FORMAT_FOR_DATE_RANGE)} - ${format(
                dateInterval.dateInterval.endDate,
                DATE_FORMAT_FOR_DATE_RANGE
              )}`
            : 'all time'}
        </p>
      </Row>
      <Row className="mb-5">
        <HistoricMetrics error={errorHistoricMetrics} loading={loadingHistoricMetrics} metrics={historicMetricsCards} />
      </Row>
      <Row>
        <Col>
          <DateButtons setDateInterval={setDateInterval} initialDateInterval={dateInterval} />
        </Col>
      </Row>
      <Row>
        <RealTimeMetrics error={errorRealTimeMetrics} loading={loadingRealTimeMetrics} metrics={realTimeMetricsCards} />
      </Row>
      <Row className="mt-5">
        <Col md={6}>
          <ActionItems data={providerActionItemsData} />
        </Col>
        <Col className="mt-4 mt-md-0" md={6}>
          <Notifications notifications={notifications} />
        </Col>
      </Row>
    </Page>
  );
};

export default ProviderDashboard;
