import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { generatePath } from 'react-router-dom';
import { format } from 'date-fns';
import Page from '../../components/common/Page';
import Select from '../../components/common/Select';
import Tabs from '../../components/common/Tabs';
import roles from '../../constants/roles';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import userStatuses from '../../constants/userStatuses';
import usePaginatedQuery from '../../hooks/common/usePaginatedQuery';
import { showInviteUserModal } from '../../components/users/InviteUserModal';
import paths from '../../router/paths';
import TableWithLoadingAndErrorAlert from '../../components/users/TableWithLoadingAndErrorAlert';
import { DATE_TIME_FORMAT } from '../../constants/date';
import invites from '../../graphql/queries/invites';
import RemoveInviteButton from '../../components/users/RemoveInviteButton';
import ProvidersTable from '../../components/users/tables/ProvidersTable';
import ScribesTable from '../../components/users/tables/ScribesTable';
import AdminsOrGuestsTable from '../../components/users/tables/AdminsOrGuestsTable';

const tabs = [
  {
    key: roles.PROVIDER,
    label: 'Providers',
  },
  {
    key: roles.SCRIBE,
    label: 'Scribes',
  },
  {
    key: roles.ADMIN,
    label: 'Admins',
  },
  { key: roles.GUEST, label: 'Guests' },
];
const options = [
  { label: 'Any', value: 'ANY' },
  {
    label: 'Active',
    value: userStatuses.ACTIVE,
  },
  {
    label: 'Inactive',
    value: userStatuses.INACTIVE,
  },
];

const invitesColumns = [
  { id: 'email', title: 'Email', cell: ({ email }) => email },
  // { id: 'role', title: 'Role', cell: ({ role }) => role },
  // { id: 'closed', title: 'Status', cell: ({ closed }) => (closed ? 'Closed' : 'Opened') },
  {
    id: 'createdAt',
    title: 'Invitation date',
    cell: ({ createdAt }) => format(new Date(createdAt), DATE_TIME_FORMAT),
  },
  { id: 'actions', title: 'Actions', cell: ({ id }, { refetch }) => <RemoveInviteButton id={id} refetch={refetch} /> },
];

const keyExtractor = (row) => row.id;

const handleClickRow = (id, activeTab, history) => {
  if (activeTab === roles.PROVIDER) {
    history.push(generatePath(paths.providerOverview, { providerId: id }));
  }
  if (activeTab === roles.SCRIBE) {
    history.push(generatePath(paths.scribeOverview, { scribeId: id }));
  }
};

const Users = () => {
  const [activeTab, setActiveTab] = useState(roles.PROVIDER);
  const [filter, setFilter] = useState(options[0].value);

  const {
    data: invitesData,
    loading: invitesLoading,
    error: invitesError,
    totalPages: invitesTotalPage,
    page: invitesPage,
    refetch: invitesRefetch,
    goToNPage: invitesGoToNPage,
  } = usePaginatedQuery(invites, {
    itemsPerPage: 10,
    fetchPolicy: 'cache-and-network',
    variables: {
      role: activeTab,
      closed: false,
    },
  });

  return (
    <Page title="Users">
      <Row>
        <Col>
          <Tabs tabs={tabs} activeTab={activeTab} onActiveTabChange={setActiveTab} />
        </Col>
        <Col className="mt-3 mt-md-0" md={4}>
          <h6>Status</h6>
          <Select options={options} value={filter} onChange={setFilter} />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-end mb-4 mt-3 mt-md-0">
          {activeTab !== roles.GUEST ? (
            <Button
              size="sm"
              onClick={() => {
                showInviteUserModal({
                  role: activeTab,
                  onCompleted: () => {
                    invitesRefetch();
                  },
                });
              }}
            >
              <AddIcon />
              {activeTab === roles.PROVIDER ? ' Invite new provider' : null}
              {activeTab === roles.SCRIBE ? ' Invite new scribe' : null}
              {activeTab === roles.ADMIN ? ' Invite new admin' : null}
            </Button>
          ) : null}
        </Col>
      </Row>
      {activeTab === roles.PROVIDER ? (
        <ProvidersTable
          filter={filter}
          keyExtractor={keyExtractor}
          handleClickRow={handleClickRow}
          activeTab={activeTab}
        />
      ) : null}
      {activeTab === roles.SCRIBE ? (
        <ScribesTable
          filter={filter}
          keyExtractor={keyExtractor}
          handleClickRow={handleClickRow}
          activeTab={activeTab}
        />
      ) : null}
      {activeTab === roles.ADMIN || activeTab === roles.GUEST ? (
        <AdminsOrGuestsTable
          filter={filter}
          keyExtractor={keyExtractor}
          handleClickRow={handleClickRow}
          activeTab={activeTab}
        />
      ) : null}
      {activeTab !== roles.GUEST ? (
        <div className="mt-5">
          <TableWithLoadingAndErrorAlert
            error={invitesError}
            loading={invitesLoading && !invitesData}
            data={invitesData}
            tableProps={{
              loading: invitesLoading,
              keyExtractor,
              columns: invitesColumns,
              noData: 'No invites found',
              context: { refetch: invitesRefetch },
            }}
            tablePaginationProps={{ page: invitesPage, totalPages: invitesTotalPage, goToNPage: invitesGoToNPage }}
          />
        </div>
      ) : null}
    </Page>
  );
};

export default Users;
