import { Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import { useRef } from 'react';
import FormikGroup from '../common/formik/FromikGroup';
import FormikFormControl from '../common/formik/FormikFormControl';
import prepareInitialValues from '../../utils/form';
import Button from '../common/Button';

const schema = yup.object().shape({
  givenName: yup.string().label('First Name').required().max(40),
  surname: yup.string().label('First Name').required().max(40),
});

const defaultValues = {
  givenName: '',
  surname: '',
};

const UserForm = ({ onSubmit, onClose, initialValues }) => {
  const initialValuesRef = useRef();
  if (!initialValuesRef.current) {
    initialValuesRef.current = prepareInitialValues(initialValues, defaultValues);
  }
  return (
    <Formik validationSchema={schema} initialValues={initialValuesRef.current} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <FormikForm>
          <FormikGroup label="First Name">
            <FormikFormControl type="text" required name="givenName" />
          </FormikGroup>
          <FormikGroup className="mt-4" label="Last Name">
            <FormikFormControl type="text" required name="surname" />
          </FormikGroup>
          <div className="mt-5">
            <Row>
              <Col className="d-flex justify-content-end">
                <Button className="me-2" onClick={onClose} variant="secondary">
                  Cancel
                </Button>
                <Button loading={isSubmitting} type="submit" variant="primary">
                  Edit
                </Button>
              </Col>
            </Row>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default UserForm;
