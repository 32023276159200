import { Button, Col, Row } from 'react-bootstrap';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { startCase } from 'lodash';
import Page from '../../components/common/Page';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import SearchInput from '../../components/common/SearchInput';
import Table from '../../components/common/Table';
import paths from '../../router/paths';
import { formatDurationSeconds } from '../../utils/helpers';
import Breadcrumb from '../../components/common/Breadcrumb';
import VisitStatus from '../../components/visits/VisitStatus';
import PageLoading from '../../components/common/PageLoading';
import ErrorAlert from '../../components/common/ErrorAlert';
import useDebouncedValue from '../../hooks/common/useDebouncedValue';
import TablePagination from '../../components/common/Pagination';
import usePaginatedQuery from '../../hooks/common/usePaginatedQuery';
import patientsTableQuery from '../../graphql/queries/patientsTable';
import { showNewPatientModal } from '../../components/patients/NewPatientModal';
import PatientTableActionButtons from '../../components/patients/PatientTableActionButtons';
import useCurrentProvider from '../../modules/currentProvider/useCurrentProvider';
import { customDateFormat } from '../../utils/date';

const columns = [
  {
    id: 'fullName',
    title: 'Patient',
    cell: ({ fullName }) => fullName,
  },
  { id: 'patientNumber', title: 'Number', cell: (row) => row.patientNumber },
  { id: 'gender', title: 'Gender', cell: (row) => startCase(row.gender) },
  { id: 'age', title: 'Age', cell: (row) => row.age },
  {
    id: 'totalTimeDocumentedSeconds',
    title: 'Time Documented',
    cell: (row) => formatDurationSeconds(row.totalTimeDocumentedSeconds),
  },
  { id: 'totalVisits', title: 'Visits', cell: (row) => row.totalVisits },
  {
    id: 'visitDate',
    title: 'Latest Visit',

    cell: (row) => (row.latestVisit?.visitDate ? customDateFormat(new Date(row.latestVisit.visitDate)) : 'N/A'),
  },
  {
    id: 'status',
    title: 'Status',
    cell: ({ latestVisit }) => (latestVisit?.status ? <VisitStatus status={latestVisit?.status} /> : 'N/A'),
  },
  {
    id: 'buttons',
    title: '',
    cell: ({ id, providerId }) => (
      <PatientTableActionButtons
        onClick={(e) => {
          e.stopPropagation();
        }}
        patientId={id}
        providerId={providerId}
      />
    ),
  },
];

const keyExtractor = (row) => row.id;
const PatientList = () => {
  const history = useHistory();
  const { providerId } = useParams();

  const [searchQuery, searchInputValue, setSearchInputValue] = useDebouncedValue();
  const { data, error, loading, totalPages, page, goToNPage, refetch } = usePaginatedQuery(patientsTableQuery, {
    itemsPerPage: 10,
    fetchPolicy: 'cache-and-network',
    variables: { searchQuery, providerId },
  });

  const { provider } = useCurrentProvider();

  const links = [
    {
      path: paths.providers,
      title: 'Providers',
    },
    {
      path: generatePath(paths.providerDashboard, { providerId }),
      title: provider?.displayName || 'Provider',
    },
  ];

  return (
    <Page title="Patients">
      <Row className="mb-4">
        <Col lg="4" sm="8" md="6">
          <Breadcrumb links={links} activeLinkTitle="Patients" />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={4} sm={12}>
          <SearchInput
            value={searchInputValue}
            onChange={(v) => {
              setSearchInputValue(v);
            }}
          />
        </Col>
        <Col className="d-flex justify-content-end mt-4 mt-md-0">
          <Button
            size="sm"
            onClick={() => {
              showNewPatientModal({ providerId, onCompleted: () => refetch() });
            }}
            variant="danger"
          >
            <AddIcon /> New Patient
          </Button>
        </Col>
      </Row>
      {loading && !data ? <PageLoading /> : null}
      {error ? <ErrorAlert error={error} /> : null}
      {Array.isArray(data) ? (
        <Table
          loading={loading}
          columns={columns}
          data={data}
          keyExtractor={keyExtractor}
          onClickRow={({ id }) => {
            history.push(generatePath(paths.patientOverview, { providerId, patientId: id }));
          }}
          noData="No patients found"
        />
      ) : null}
      <TablePagination {...{ totalPages, page, goToNPage }} />
    </Page>
  );
};

export default PatientList;
