import { useMutation } from '@apollo/client';
import React from 'react';
import { getMessageFromGraphQLError, grabFirstGQLDataResult } from '../../utils/helpers';
import ApolloClient from '../../graphql/client';
import loginWithAzureTokenMutation from '../../graphql/mutations/loginWithAzureToken';
import { ME_QUERY } from './useMeQuery';

const useLoginMutation = () => {
  const [mutate] = useMutation(loginWithAzureTokenMutation);

  return React.useCallback(
    async (accessToken) => {
      const { data } = await mutate({
        variables: { accessToken },
      });
      try {
        const { user } = grabFirstGQLDataResult(data);
        ApolloClient.writeQuery({
          query: ME_QUERY,
          data: { me: user },
        });
        return user;
      } catch (error) {
        throw new Error(getMessageFromGraphQLError(error));
      }
    },
    [mutate]
  );
};

export default useLoginMutation;
