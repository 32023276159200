import { Form } from 'react-bootstrap';

const FormikGroup = ({ label, className, children, ...rest }) => (
  <Form.Group className={className} {...rest}>
    <Form.Label>{label}</Form.Label>
    {children}
  </Form.Group>
);

export default FormikGroup;
