import { confirmAlert } from '@uplab/react-confirm-alert';
import { toast } from 'react-toastify';
import FormModal from '../common/FormModal';
import useEditUser from '../../hooks/users/useEditUser';
import UserForm from './UserForm';

const EditUserModal = ({ show, onClose, user, refetch }) => {
  const [editUser, { error }] = useEditUser({
    onCompleted: () => {
      onClose();
      refetch();
      toast.success('Profile data successfully updated');
    },
  });
  const onSubmit = async (newData) => {
    await editUser({
      variables: {
        data: newData,
      },
    });
  };

  return (
    <FormModal
      isEdit
      show={show}
      onClose={onClose}
      onSubmit={onSubmit}
      titleText="Edit Profile"
      initialValues={{ givenName: user.givenName, surname: user.surname }}
      Form={UserForm}
      error={error}
    />
  );
};

export default EditUserModal;

export function showEditUserModal({ user, refetch }) {
  confirmAlert({
    customUI: ({ onClose }) => <EditUserModal onClose={onClose} show user={user} refetch={refetch} />,
  });
}
