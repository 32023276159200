import { gql } from '@apollo/client';

const SELECTED_PROVIDER_FRAGMENT = gql`
  fragment selectedProvider on Provider {
    id
    displayName
  }
`;

export default SELECTED_PROVIDER_FRAGMENT;
