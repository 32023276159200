import { gql } from '@apollo/client';
import VISIT_DETAILS_FRAGMENT from '../fragments/visit';

const submitVisitSOAPMutation = gql`
  mutation submitVisitSOAP($visitId: ID!, $providerId: ID!, $data: SOAPInput!) {
    submitVisitSOAP(visitId: $visitId, providerId: $providerId, data: $data) {
      ...visitDetails
    }
  }
  ${VISIT_DETAILS_FRAGMENT}
`;

export default submitVisitSOAPMutation;
