import { gql } from '@apollo/client';
import { REAL_TIME_METRICS_FRAGMENT } from '../fragments/metrics';

const realTimeMetrics = gql`
  query realTimeMetrics($providerId: ID, $scribeId: ID, $metricsEvents: [RealTimeMetricsEvents]) {
    realTimeMetrics(providerId: $providerId, scribeId: $scribeId, metricsEvents: $metricsEvents) {
      ...realTimeMetrics
    }
  }
  ${REAL_TIME_METRICS_FRAGMENT}
`;

export default realTimeMetrics;
