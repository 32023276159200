import { Alert, Button, Col, Row } from 'react-bootstrap';
import Page from '../../components/common/Page';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { showCreateNoteTemplateModal } from '../../components/noteTemplates/CreateNoteTemplateModal';
import PageLoading from '../../components/common/PageLoading';
import ErrorAlert from '../../components/common/ErrorAlert';
import noteTemplatesQuery from '../../graphql/queries/noteTemplates';
import NoteTemplatesInfiniteScroll from '../../components/noteTemplates/NoteTemplatesInfiniteScroll';
import useInfiniteScrollQuery from '../../hooks/common/useInfiniteScrollQuery';

const NoteTemplates = () => {
  const [data, error, loading, refetch, fetchMoreData] = useInfiniteScrollQuery(noteTemplatesQuery, {
    restOptions: {
      fetchPolicy: 'cache-and-network',
    },
  });
  return (
    <Page title="Note Templates">
      {loading && !data?.data ? <PageLoading /> : null}

      <Row>
        <Col className="d-flex justify-content-end">
          <Button
            className="mt-3 mt-md-0"
            size="sm"
            onClick={() => {
              showCreateNoteTemplateModal({
                onCompleted: () => {
                  refetch();
                },
              });
            }}
          >
            <AddIcon /> New Note Template
          </Button>
        </Col>
      </Row>
      {error ? <ErrorAlert error={error} /> : null}
      {data?.data && !error && data?.data.length === 0 ? (
        <Alert className="mt-4" variant="info">
          No note templates found
        </Alert>
      ) : null}
      {Array.isArray(data?.data) ? (
        <NoteTemplatesInfiniteScroll data={data.data} count={data.count} fetchMoreData={fetchMoreData} />
      ) : null}
    </Page>
  );
};

export default NoteTemplates;
