import { Tabs as TabsComponent, Tab, Badge } from 'react-bootstrap';
import cn from 'classnames';

import c from './Tabs.module.scss';

const TabTitle = ({ label, counter }) => {
  if (!counter) return label;

  return (
    <div className={c.tabTitle}>
      {label}
      <Badge className={c.badge} pill>
        {counter}
      </Badge>
    </div>
  );
};

const Tabs = ({ tabs, activeTab, onActiveTabChange, withoutMarginTop }) => (
  <TabsComponent
    className={cn(
      c.tabs,
      withoutMarginTop ? c.withoutMarginTop : null,
      tabs.length > 2 ? c.moreThanTwoTabs : null,
      'mb-3'
    )}
    activeKey={activeTab}
    onSelect={(k) => onActiveTabChange(k)}
  >
    {tabs?.map(({ key, label, counter }) => (
      <Tab
        tabClassName={cn(c.tab, activeTab === key && c.active)}
        key={key}
        eventKey={key}
        title={<TabTitle label={label} counter={counter} />}
      />
    ))}
  </TabsComponent>
);

export default Tabs;
