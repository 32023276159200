import { endOfMonth, endOfWeek, startOfMonth, startOfWeek, subMonths, subWeeks } from 'date-fns';
import { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { getTimeRangeThisWeek } from '../../utils/date';

const dateButtons = [
  {
    id: 'past-month',
    title: 'Past month',
    onClick: (setDateInterval) => {
      setDateInterval({
        id: 'past-month',
        dateInterval: {
          startDate: subMonths(startOfMonth(new Date()), 1),
          endDate: subMonths(endOfMonth(new Date()), 1),
        },
      });
    },
  },
  {
    id: 'this-month',
    title: 'This month',
    onClick: (setDateInterval) => {
      setDateInterval({
        id: 'this-month',
        dateInterval: {
          startDate: startOfMonth(new Date()),
          endDate: endOfMonth(new Date()),
        },
      });
    },
  },
  {
    id: 'past-week',
    title: 'Past week',
    onClick: (setDateInterval) => {
      setDateInterval({
        id: 'past-week',
        dateInterval: {
          startDate: subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
          endDate: subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1),
        },
      });
    },
  },
  {
    id: 'this-week',
    title: 'This week',
    onClick: (setDateInterval) => {
      setDateInterval({
        id: 'this-week',
        dateInterval: getTimeRangeThisWeek(),
      });
    },
  },
  {
    id: 'all-time',
    title: 'All time',
    onClick: (setDateInterval) => {
      setDateInterval({
        id: 'all-time',
        dateInterval: null,
      });
    },
  },
];

const DateButtons = ({ setDateInterval, initialDateInterval }) => {
  const [activeButtonId, setActiveButtonId] = useState(
    dateButtons.findIndex((item) => item.id === initialDateInterval.id)
  );
  return (
    <ButtonGroup>
      {dateButtons.map((button, i) => (
        <Button
          size="sm"
          key={button.id}
          active={i === activeButtonId}
          variant={i === activeButtonId ? 'outline-primary' : 'outline-secondary'}
          onClick={() => {
            setActiveButtonId(i);
            button.onClick(setDateInterval);
          }}
        >
          {button.title}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default DateButtons;
