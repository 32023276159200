import { Col } from 'react-bootstrap';
import ErrorAlert from '../common/ErrorAlert';
import StatCard from '../dashboard/StatCard';
import StatCardsLoading from '../dashboard/StatCardsLoading';

const RealTimeMetrics = ({ loading, error, metrics }) => (
  <>
    <p className="text-muted mt-5">Live Metrics</p>
    {error ? <ErrorAlert error={error} /> : null}
    {loading && metrics.length === 0 ? <StatCardsLoading numberOfCards={5} /> : null}
    {metrics.map((props) => (
      <Col sm={6} md={4} lg={3} xl={2} key={props.id} className="mb-2">
        <StatCard loading={loading} {...props} />
      </Col>
    ))}
  </>
);

export default RealTimeMetrics;
