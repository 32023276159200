const roles = {
  ADMIN: 'ADMIN',
  PROVIDER: 'PROVIDER',
  SCRIBE: 'SCRIBE',
  GUEST: 'GUEST',
};

export const rolesTitleMapping = {
  [roles.SCRIBE]: 'Scribe',
  [roles.PROVIDER]: 'Provider',
  [roles.ADMIN]: 'Admin',
  [roles.GUEST]: 'Guest',
};

export default roles;
