import { gql } from '@apollo/client';
import PROVIDER from '../fragments/provider';

const providers = gql`
  query providers($limit: Int!, $skip: Int!, $userStatus: UserStatuses) {
    providers(limit: $limit, skip: $skip, userStatus: $userStatus) {
      count
      data {
        ...provider
      }
    }
  }
  ${PROVIDER}
`;

export default providers;
