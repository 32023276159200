import { gql } from '@apollo/client';

const PATIENT_TABLE_ITEM_FRAGMENT = gql`
  fragment patientTableItem on Patient {
    id
    fullName
    patientNumber
    gender
    age
    totalVisits
    totalTimeDocumentedSeconds
    providerId
    latestVisit {
      status
      visitDate
    }
  }
`;

export default PATIENT_TABLE_ITEM_FRAGMENT;
