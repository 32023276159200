import { useState, useRef, useEffect } from 'react';
import { Button, Form, ButtonGroup } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import classNames from 'classnames';
import { ReactComponent as PlayIcon } from '../../assets/icons/play.svg';
import { ReactComponent as PauseIcon } from '../../assets/icons/pause.svg';
import c from './AudioPlayer.module.scss';
import GlobalAudioController from '../../services/GlobalAudioController';
import { customDateFormat } from '../../utils/date';

const formatSecondsForPlayer = (durationSeconds) => {
  if (!durationSeconds) {
    return '0:00';
  }
  const minutes = Math.floor(durationSeconds / 60);
  const seconds = durationSeconds % 60;
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

const playbackRateButtons = [
  {
    label: '1x',
    value: 1.0,
  },
  {
    label: '1.5x',
    value: 1.5,
  },
  {
    label: '2x',
    value: 2.0,
  },
];

const AudioPlayer = ({ id, url, date }) => {
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState({ loaded: 0, loadedSeconds: 0, played: 0, playedSeconds: 0 });
  const [seeking, setSeeling] = useState(false);
  const [isStoppedForTimeSelect, setIsStoppedForTimeSelect] = useState(false);
  const [playbackRate, setPayBackRate] = useState(1.0);
  const ref = useRef();
  const handleDuration = (d) => {
    setDuration(d);
  };
  const handleSeekMouseDown = () => {
    if (playing) {
      setPlaying(false);
      setIsStoppedForTimeSelect(true);
    }
    setSeeling(true);
  };
  const handleProgress = (e) => {
    if (!seeking) {
      setProgress(e);
    }
  };
  const handleSeekChange = (e) => {
    setProgress({ ...progress, played: parseFloat(e.target.value) });
  };
  const handleSeekMouseUp = (e) => {
    setSeeling(false);
    ref.current.player.seekTo(parseFloat(e.target.value));
    if (isStoppedForTimeSelect) {
      setPlaying(true);
      setIsStoppedForTimeSelect(false);
    }
  };
  const handleSetPlaybackRate = (e) => {
    setPayBackRate(parseFloat(e.target.value));
  };
  const handlePause = () => {
    setPlaying(false);
  };
  const handlePlay = () => {
    GlobalAudioController.registerPlayer(id, handlePause);
    setPlaying(true);
  };

  useEffect(() => GlobalAudioController.resetPlayerById(id), [id]);

  const safeProgressPlayed = progress?.played || 0;
  return (
    <>
      <ReactPlayer
        ref={ref}
        url={url}
        volume={1}
        playing={playing}
        width="0px"
        height="0px"
        playbackRate={playbackRate}
        onDuration={handleDuration}
        onProgress={handleProgress}
        onPause={handlePause}
        progressInterval={15}
      />
      <div className={c.playerWrapper}>
        <Button
          className={c.playOrPauseButton}
          onClick={() => {
            if (playing) {
              handlePause();
            } else {
              handlePlay();
            }
          }}
        >
          {playing || isStoppedForTimeSelect ? <PauseIcon /> : <PlayIcon />}
        </Button>
        <div className={c.playerControls}>
          <div className={classNames('text-end', c.controlsHeader)}>
            <div className="text-muted flex-fill">{date ? customDateFormat(new Date(date)) : ''}</div>
          </div>
          <Form.Range
            className={c.progress}
            style={{
              background: `linear-gradient(to right, #a90000 0%, #a90000 ${safeProgressPlayed * 100}%, #dee2e6 ${
                safeProgressPlayed * 100
              }%, #dee2e6 100%`,
            }}
            min={0}
            max={0.999999}
            step="any"
            value={progress?.played}
            variant="secondary"
            onMouseDown={handleSeekMouseDown}
            onChange={handleSeekChange}
            onMouseUp={handleSeekMouseUp}
          />
          <div className={classNames('text-end', c.controlsFooter)}>
            <ButtonGroup>
              {playbackRateButtons.map((item) => (
                <Button
                  key={item.value}
                  value={item.value}
                  onClick={handleSetPlaybackRate}
                  size="sm"
                  variant={playbackRate === item.value ? 'primary' : 'outline-primary'}
                  className={c.playbackRateButton}
                >
                  {item.label}
                </Button>
              ))}
            </ButtonGroup>
            <div>
              {formatSecondsForPlayer(Math.round(duration * safeProgressPlayed))} /{' '}
              {formatSecondsForPlayer(Math.round(duration))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AudioPlayer;
