import { useField } from 'formik';
import { Form } from 'react-bootstrap';

const FormikFormControl = ({ as = 'input', type, name, required, ...rest }) => {
  const [field, meta] = useField(name);
  const { onBlur, onChange, value } = field;
  const { error, touched } = meta;
  return (
    <>
      <Form.Control
        as={as}
        value={value}
        onChange={onChange}
        isInvalid={touched && error}
        onBlur={onBlur}
        type={type}
        name={name}
        required={required}
        {...rest}
      />
      {touched && error && (
        <div>
          <small className="text-danger">{error}</small>
        </div>
      )}
    </>
  );
};

export default FormikFormControl;
