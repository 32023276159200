import Button from '../common/Button';
import { showAssignRoleModal } from './AssignRoleModal';

const AssignRoleButton = ({ userId, refetch }) => (
  <Button
    onClick={() => {
      showAssignRoleModal({ userId, onCompleted: refetch });
    }}
    variant="link"
    size="sm"
    style={{ textDecoration: 'none', padding: '0px' }}
  >
    Assign role
  </Button>
);

export default AssignRoleButton;
