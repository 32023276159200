import { Button, Spinner, Card, Alert } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup';
import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as CardListIcon } from '../../assets/icons/card-list.svg';
import FormikGroup from '../common/formik/FromikGroup';
import DraftStorage from '../../services/DraftStorage';
import useUserQuery from '../../hooks/auth/useMeQuery';
import useSubmitVisitSOAP from '../../hooks/visits/useSubmitVisitSOAP';
import FormikFormTextArea from '../common/formik/FormikFormTextArea';
import FormikFormControl from '../common/formik/FormikFormControl';
import FormikFormCheck from '../common/formik/FormikFormCheck';
import { showNoteTemplatesModal } from '../noteTemplates/NoteTemplatesModal';

const schema = yup.object().shape({
  subjective: yup.string().label('Subjective').required(),
  objective: yup.string().label('Objective').required(),
  assessment: yup.string().label('Assessment').required(),
  plan: yup.string().label('Plan').required(),
  saveAsNewNoteTemplate: yup.boolean(),
  noteTemplateName: yup.string().label('Note template name').when('saveAsNewNoteTemplate', {
    is: true,
    then: yup.string().required(),
  }),
});

export default function SOAPForm({ initialValues, submitDisabled, formAlert, visitId, providerId, patientId }) {
  const [submitVisitSOAP] = useSubmitVisitSOAP({
    onCompleted: () => {
      toast.success('Note Overview submitted successfully!');
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });
  const onSubmit = async ({ saveAsNewNoteTemplate, ...data }) => {
    await submitVisitSOAP({
      variables: {
        data: saveAsNewNoteTemplate ? data : { ...data, noteTemplateName: null },
        visitId,
        providerId,
      },
    });
  };
  const [user] = useUserQuery({ fetchPolicy: 'cache-only' });
  const draftValues = DraftStorage.getDraft(`${user.id}:${visitId}`)
    ? DraftStorage.getDraft(`${user.id}:${visitId}`).values
    : {};
  return (
    <Card>
      <Card.Body>
        <Formik validationSchema={schema} initialValues={{ ...initialValues, ...draftValues }} onSubmit={onSubmit}>
          {({ isSubmitting, values, setValues }) => (
            <FormikForm>
              {!isEqual(values, initialValues) ? <SaveDraft id={`${user.id}:${visitId}`} values={values} /> : null}
              <div className="d-flex justify-content-between mb-3">
                <Card.Title>
                  <h5>Note Overview</h5>
                </Card.Title>
                {/* TODO: implement fill with note template */}
                <Button
                  onClick={() => {
                    showNoteTemplatesModal({
                      fill: (data) => {
                        const { __typename, ...soapValues } = data;
                        setValues({
                          ...soapValues,
                          saveAsNewNoteTemplate: values.saveAsNewNoteTemplate,
                          noteTemplateName: values.noteTemplateName,
                        });
                      },
                      providerId,
                      patientId,
                    });
                  }}
                  variant="outline-primary"
                  type="button"
                >
                  <CardListIcon /> Fill with Note Template
                </Button>
              </div>
              {formAlert ? <Alert variant="warning">{formAlert}</Alert> : null}
              <FormikGroup label="Subjective" className="mb-2">
                <FormikFormTextArea name="subjective" />
              </FormikGroup>

              <FormikGroup label="Objective" className="mb-2">
                <FormikFormTextArea name="objective" />
              </FormikGroup>

              <FormikGroup label="Assessment" className="mb-2">
                <FormikFormTextArea name="assessment" />
              </FormikGroup>

              <FormikGroup label="Plan" className="mb-2">
                <FormikFormTextArea name="plan" />
              </FormikGroup>
              <FormikFormCheck name="saveAsNewNoteTemplate" label="Save as new Note Template" />
              {values.saveAsNewNoteTemplate ? (
                <FormikGroup label="Note Template name" className="mb-2">
                  <FormikFormControl type="text" name="noteTemplateName" />
                </FormikGroup>
              ) : null}
              <SubmitButton {...{ isSubmitting, submitDisabled }} />
            </FormikForm>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
}

const SaveDraft = ({ values, id }) => {
  useEffect(() => {
    DraftStorage.updateDraft({ id, values });
  }, [values, id]);
  return null;
};

const SubmitButton = ({ isSubmitting, submitDisabled, style }) => (
  <Button
    className="w-100 mt-3"
    type="submit"
    variant="primary"
    disabled={submitDisabled || isSubmitting}
    style={style}
  >
    {isSubmitting ? <Spinner size="sm" animation="border" variant="danger" /> : null} Submit for Review
  </Button>
);
