import { Card, Col, Row } from 'react-bootstrap';
import Loading from '../common/Loading';

const SOAPLoading = () => (
  <Card>
    <Card.Header>
      <Row>
        <Col>
          <h5 style={{ margin: '0px' }}>Note Overview</h5>
        </Col>
      </Row>
    </Card.Header>
    <Card.Body>
      <Loading count={15} />
    </Card.Body>
  </Card>
);

export default SOAPLoading;
