import { gql } from '@apollo/client';
import NOTE_TEMPLATE from '../fragments/noteTemplate';

const createNoteTemplate = gql`
  mutation createNoteTemplate($data: CreateNoteTemplateInput!) {
    createNoteTemplate(data: $data) {
      ...noteTemplate
    }
  }
  ${NOTE_TEMPLATE}
`;

export default createNoteTemplate;
