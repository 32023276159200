import { gql } from '@apollo/client';
import NOTE_TEMPLATE from '../fragments/noteTemplate';

const noteTemplateById = gql`
  query noteTemplate($id: ID!) {
    noteTemplate(id: $id) {
      ...noteTemplate
    }
  }
  ${NOTE_TEMPLATE}
`;

export default noteTemplateById;
