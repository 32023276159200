import { Card, Col, Row } from 'react-bootstrap';
import cn from 'classnames';
import { Link, generatePath } from 'react-router-dom';
import { useRef } from 'react';
import c from './NotificationItem.module.scss';
import paths from '../../router/paths';
import { customDateFormat } from '../../utils/date';

const NotificationItem = ({ date, message, read, payload, className }) => {
  const redirect = useRef(JSON.parse(payload)?.redirect);
  return redirect.current?.route === 'VisitOverview' ? (
    <Link
      className={c.cardWithLink}
      to={generatePath(paths.visitOverview, {
        visitId: redirect.current.params.visitId,
        providerId: redirect.current.params.providerId,
      })}
    >
      <NotificationCard date={date} message={message} read={read} className={className} />
    </Link>
  ) : (
    <NotificationCard date={date} message={message} read={read} className={className} />
  );
};

function NotificationCard({ date, message, read, className }) {
  return (
    <Card className={cn(c.card, className)}>
      <Row>
        <Col>
          <span className="text-muted mb-1">{customDateFormat(new Date(date), { addSuffix: true })}</span>
        </Col>
        {!read ? (
          <Col className="d-flex justify-content-end">
            <div className={c.redCircle} />
          </Col>
        ) : null}
      </Row>
      <p className="mb-0">{message}</p>
    </Card>
  );
}

export default NotificationItem;
