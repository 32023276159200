import { gql } from '@apollo/client';

const USER_TABLE_ITEM_FRAGMENT = gql`
  fragment userTableItem on User {
    id
    displayName
    email
    role
    createdAt
    deactivated
    lastLoginDate
  }
`;

export default USER_TABLE_ITEM_FRAGMENT;
