import { confirmAlert } from '@uplab/react-confirm-alert';
import { toast } from 'react-toastify';
import useInviteUser from '../../hooks/users/useInviteUser';
import FormModal from '../common/FormModal';
import InviteUserForm from './InviteUserForm';

const InviteUser = ({ show, onClose, defaultRole, onCompleted }) => {
  const [inviteUser, { error }] = useInviteUser({
    onCompleted: () => {
      if (typeof onCompleted === 'function') {
        onCompleted();
      }
      onClose();
      toast.success('user invited');
    },
  });
  const onSubmit = async ({ role, email }) => {
    await inviteUser({ variables: { role, email } });
  };
  return (
    <FormModal
      show={show}
      error={error}
      onClose={onClose}
      onSubmit={onSubmit}
      titleText="Invite User"
      Form={InviteUserForm}
      initialValues={{ role: defaultRole }}
    />
  );
};

export default InviteUser;

export function showInviteUserModal({ role, onCompleted }) {
  confirmAlert({
    customUI: ({ onClose }) => <InviteUser onClose={onClose} show defaultRole={role} onCompleted={onCompleted} />,
  });
}
