import { gql } from '@apollo/client';

const newPatientMutation = gql`
  mutation createPatient($data: CreatePatientInput!, $providerId: ID) {
    createPatient(data: $data, providerId: $providerId) {
      id
    }
  }
`;

export default newPatientMutation;
