import ErrorAlert from '../common/ErrorAlert';
import Loading from '../common/Loading';
import TablePagination from '../common/Pagination';
import Table from '../common/Table';

const TableWithLoadingAndErrorAlert = ({ error, data, loading, tableProps, tablePaginationProps }) => (
  <>
    {error ? <ErrorAlert className error={error} /> : null}
    {loading && !Array.isArray(data) ? <Loading isTable /> : null}
    {Array.isArray(data) ? (
      <>
        <Table {...tableProps} data={data} />
        <TablePagination {...tablePaginationProps} />
      </>
    ) : null}
  </>
);

export default TableWithLoadingAndErrorAlert;
