import c from './Page.module.scss';

export default function Page({ children, title, subtitle }) {
  return (
    <div className={c.page}>
      {title ? <h1>{title}</h1> : null}
      {subtitle ? <span className="text-muted">{subtitle}</span> : null}
      <div className={c.spacer} />
      {children}
    </div>
  );
}
