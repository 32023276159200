import { gql } from '@apollo/client';

const INVITE_FRAGMENT = gql`
  fragment invite on Invite {
    id
    createdAt
    email
    closed
    role
  }
`;

export default INVITE_FRAGMENT;
