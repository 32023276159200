import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import useLoginApolloWithAzure from '../../hooks/auth/useLoginApolloWithAzure';
import ErrorAlert from '../common/ErrorAlert';
import paths from '../../router/paths';
import FullscreenLoading from '../common/FullscreenLoading';
import roles from '../../constants/roles';

// history.replace(paths.logout);
export default function ApolloAuthorizator() {
  const history = useHistory();
  const { loginApolloWithAzure, isLoading, error } = useLoginApolloWithAzure({
    onCompleted: (data) => {
      // console.log('Logged in as', user);
      if (data.role === roles.ADMIN) {
        history.push(paths.users);
      } else {
        history.replace(paths.dashboard);
      }
    },
  });

  useEffect(() => {
    loginApolloWithAzure();
  }, [loginApolloWithAzure]);

  if (isLoading) return <FullscreenLoading />;
  if (error) {
    return (
      <ErrorAlert error={error}>
        <Link to={paths.logout}>
          <Button variant="primary" className="mt-3">
            Logout
          </Button>
        </Link>
      </ErrorAlert>
    );
  }
  return null;
}
