import { Table as BootstrapTable } from 'react-bootstrap';
import cn from 'classnames';
import c from './Table.module.scss';
import MaterialProgress from './MaterialProgress';

const LoadingIndicator = ({ loading }) => {
  if (!loading) return null;
  return (
    <div className={c.loadingIndicator}>
      <MaterialProgress />
    </div>
  );
};
export default function Table({ data, columns, keyExtractor, onClickRow, loading, noData, context }) {
  return (
    <div className={c.tableContainer}>
      <BootstrapTable responsive bordered hover={onClickRow && data.length} className={cn(c.table, 'bg-white')}>
        <thead className={cn('table-dark', 'position-relative', c.thead)}>
          <tr>
            {columns.map((col, i) => (
              <th key={col.id}>
                {col.title}
                {i === columns.length - 1 ? <LoadingIndicator loading={loading} /> : null}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((row) => (
            <tr key={keyExtractor(row)} onClick={onClickRow ? () => onClickRow(row) : undefined}>
              {columns.map((col) => (
                <td key={col.id}>{col.cell(row, context)}</td>
              ))}
            </tr>
          ))}
          {!data.length && noData ? (
            <tr>
              <td colSpan={columns.length} className={cn('text-center', c.noData)}>
                {noData}
              </td>
            </tr>
          ) : null}
        </tbody>
      </BootstrapTable>
    </div>
  );
}
