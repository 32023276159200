import { Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import { useRef } from 'react';
import FormikGroup from '../common/formik/FromikGroup';
import FormikFormSelect from '../common/formik/FormikFormSelect';
import FormikFormControl from '../common/formik/FormikFormControl';
import roles, { rolesTitleMapping } from '../../constants/roles';
import prepareInitialValues from '../../utils/form';
import Button from '../common/Button';

const schema = yup.object().shape({
  role: yup.mixed().label('Role').oneOf(Object.keys(rolesTitleMapping)).required(),
  email: yup.string().label('Email address').email().required(),
});

const defaultValues = {
  role: roles.SCRIBE,
  email: '',
};

const InviteUserForm = ({ onSubmit, onClose, initialValues = {} }) => {
  const initialValuesRef = useRef();
  if (!initialValuesRef.current) {
    initialValuesRef.current = prepareInitialValues(initialValues, defaultValues);
  }
  return (
    <Formik validationSchema={schema} initialValues={initialValuesRef.current} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <FormikForm>
          <FormikGroup label="Role">
            <FormikFormSelect required name="role">
              {Object.entries(rolesTitleMapping).map(([value, title]) => (
                <option key={value} value={value}>
                  {title}
                </option>
              ))}
            </FormikFormSelect>
          </FormikGroup>
          <FormikGroup className="mt-4" label="Email address">
            <FormikFormControl required name="email" />
          </FormikGroup>
          <div className="mt-5">
            <Row>
              <Col className="d-flex justify-content-end">
                <Button className="me-2" onClick={onClose} variant="secondary">
                  Cancel
                </Button>
                <Button loading={isSubmitting} type="submit" variant="primary">
                  Invite
                </Button>
              </Col>
            </Row>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default InviteUserForm;
