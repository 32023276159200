const paths = {
  home: '/',
  logout: '/logout',
  resetPassword: '/reset-password',
  dashboard: '/secure/dashboard',
  providers: '/secure/providers',
  allDocuments: '/secure/all-documents',
  noteTemplates: '/secure/note-templates',
  providerDashboard: '/secure/:providerId/dashboard',
  documents: '/secure/:providerId/documents',
  patients: '/secure/:providerId/patients',
  authenticator: '/ad/auth',
  patientOverview: '/secure/:providerId/patients/:patientId',
  visitOverview: '/secure/:providerId/visits/:visitId',
  users: '/secure/users',
  statistics: '/secure/statistics',
  providerOverview: '/secure/:providerId/provider-overview',
  scribeOverview: '/secure/:scribeId/scribe-overview',
};

export default paths;
