import { useQuery } from '@apollo/client';
import providerSelectOption from '../../graphql/queries/providerSelectOption';

const useProviderSelectOption = ({ searchQuery, excludeWithoutActionItems, scribeId }) => {
  const { data } = useQuery(providerSelectOption, {
    variables: { limit: 10, skip: 0, searchQuery, excludeWithoutActionItems, scribeId },
  });

  return { providerOptions: data?.scribeProviders.data };
};

export default useProviderSelectOption;
