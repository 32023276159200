class GlobalAudioController {
  stopActivePlayer = null;

  activePlayerId = null;

  registerPlayer = (playerId, stop) => {
    if (typeof this.stopActivePlayer === 'function') {
      this.stopActivePlayer();
    }
    this.stopActivePlayer = stop;
    this.activePlayerId = playerId;
  };

  resetPlayerById = (playerId) => {
    if (playerId === this.activePlayerId) {
      this.stopActivePlayer = null;
      this.activePlayerId = null;
    }
  };
}

export default new GlobalAudioController();
