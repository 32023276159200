import { gql } from '@apollo/client';
import { CURRENT_USER_FRAGMENT } from '../../hooks/auth/useMeQuery';

const loginWithAzureTokenMutation = gql`
  mutation loginWithAzureToken($accessToken: String!) {
    loginWithAzureToken(accessToken: $accessToken) {
      user {
        ...currentUser
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

export default loginWithAzureTokenMutation;
