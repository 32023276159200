import { gql } from '@apollo/client';

const PATIENT_DETAILS_FRAGMENT = gql`
  fragment patientDetails on Patient {
    id
    fullName
    providerId
    provider {
      id
      displayName
    }
    gender
    patientNumber
    age
    dob
    totalTimeDocumentedSeconds
    totalVisits
    latestVisit {
      id
      visitDate
    }
  }
`;

export default PATIENT_DETAILS_FRAGMENT;
