import { Button } from 'react-bootstrap';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import visitStatuses from '../../constants/visitStatuses';
import useRemoveVisit from '../../hooks/visits/useRemoveVisit';

const VisitTableDeleteButton = ({ status, id, providerId }) => {
  const removeVisit = useRemoveVisit({ visitId: id, providerId });
  if (status !== visitStatuses.SCHEDULED) return null;
  return (
    <Button
      size="sm"
      onClick={(e) => {
        e.stopPropagation();
        removeVisit();
      }}
      variant="light"
      disabled={status !== 'scheduled'}
    >
      <DeleteIcon />
    </Button>
  );
};

export default VisitTableDeleteButton;
