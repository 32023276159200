import { Card } from 'react-bootstrap';
import Loading from '../common/Loading';
import c from './StatCard.module.scss';

const StatCard = ({ backgroundColor, textColor, value, title, loading }) => (
  <Card style={{ backgroundColor, color: textColor }} className={c.statCard}>
    <Card.Body className={c.body}>
      {loading ? (
        <Loading count={3} />
      ) : (
        <>
          <h5 className={c.value}>{value}</h5>
          <span>{title}</span>
        </>
      )}
    </Card.Body>
  </Card>
);

export default StatCard;
