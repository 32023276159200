import { gql } from '@apollo/client';

const NOTE_TEMPLATE = gql`
  fragment noteTemplate on NoteTemplate {
    id
    name
    subjective
    objective
    assessment
    plan
  }
`;

export default NOTE_TEMPLATE;
