import { Breadcrumb as BSBreadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import s from './Breadcrumb.module.scss';

const Breadcrumb = ({ links, activeLinkTitle }) => (
  <BSBreadcrumb className={s.breadcrumb}>
    {links.map(({ path, title }) => (
      <BSBreadcrumb.Item key={title} active={!path} linkProps={{ to: path }} linkAs={Link}>
        {title}
      </BSBreadcrumb.Item>
    ))}
    <BSBreadcrumb.Item active>{activeLinkTitle}</BSBreadcrumb.Item>
  </BSBreadcrumb>
);

export default Breadcrumb;
