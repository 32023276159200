import { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import c from './MenuItem.module.scss';

const MenuItem = forwardRef(
  (
    { title, path, Icon, RightIcon, exact, clickable, className, activeClassName = c.sidebarMenuItemActive, ...rest },
    ref
  ) => {
    const Container = path ? NavLink : 'div';

    return (
      <Container
        {...rest}
        ref={ref}
        className={cn(c.sidebarMenuItem, clickable && c.clickable, className)}
        to={path}
        exact={exact}
        activeClassName={path ? activeClassName : undefined}
      >
        {Icon ? (
          <span className={c.sidebarIconContainer}>
            <Icon width={24} />
          </span>
        ) : null}
        <span className={c.sidebarItemTitle}>{title}</span>
        {RightIcon ? (
          <span className={c.sidebarRightIconContainer}>
            <RightIcon width={24} />
          </span>
        ) : null}
      </Container>
    );
  }
);

export default MenuItem;
