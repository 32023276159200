import { gql, useQuery } from '@apollo/client';

export const CURRENT_USER_FRAGMENT = gql`
  fragment currentUser on User {
    id
    userPrincipalName
    email
    givenName
    displayName
    surname
    role
    createdAt
  }
`;

export const ME_QUERY = gql`
  query me {
    me {
      ...currentUser
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

const useUserQuery = (options) => {
  const { loading, error, data, refetch } = useQuery(ME_QUERY, options);
  return [data?.me, loading, error, refetch];
};

export default useUserQuery;
