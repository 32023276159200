import { confirmAlert } from '@uplab/react-confirm-alert';
import { toast } from 'react-toastify';
import useNewPatient from '../../hooks/patiens/useNewPatient';
import { formatRFC3339Date } from '../../utils/date';
import FormModal from '../common/FormModal';
import { ReactComponent as NewPatientTitleIcon } from '../../assets/icons/new-patient-title.svg';
import PatientForm from './PatientForm';

const NewPatient = ({ show, onClose, providerId, onCompleted }) => {
  const [newPatient, { error }] = useNewPatient({
    onCompleted: (data) => {
      onClose();
      if (typeof onCompleted === 'function') onCompleted(data);
    },
  });
  const onSubmit = async (data) => {
    const { dob } = data;
    await newPatient({ variables: { data: { ...data, dob: formatRFC3339Date(dob) }, providerId } });
    toast.success(`Patient "${data.firstName} ${data.lastName}" successfully created`);
  };
  return (
    <FormModal
      show={show}
      onClose={onClose}
      onSubmit={onSubmit}
      TitleIcon={NewPatientTitleIcon}
      Form={PatientForm}
      error={error}
    />
  );
};

export default NewPatient;

export function showNewPatientModal({ providerId, onCompleted }) {
  confirmAlert({
    customUI: ({ onClose }) => <NewPatient onClose={onClose} show providerId={providerId} onCompleted={onCompleted} />,
  });
}
