import { gql } from '@apollo/client';
import NOTE_TEMPLATE from '../fragments/noteTemplate';

const noteTemplates = gql`
  query noteTemplates($limit: Int!, $skip: Int!) {
    noteTemplates(limit: $limit, skip: $skip) {
      count
      data {
        ...noteTemplate
      }
    }
  }
  ${NOTE_TEMPLATE}
`;

export default noteTemplates;
