import { useField } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';

export default function FormikFormCheck({ label, name }) {
  const [field, , helpers] = useField(name);
  const { setValue } = helpers;
  const { onBlur, value } = field;
  return (
    <Form.Group controlId="formBasicCheckbox" className="mt-3">
      <Form.Check
        label={label}
        onBlur={onBlur}
        onClick={(e) => {
          setValue(e.target.checked);
        }}
        defaultChecked={value}
        value={value}
      />
    </Form.Group>
  );
}
