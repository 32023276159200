import { Accordion, Alert, Button, Spinner, useAccordionButton } from 'react-bootstrap';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { ReactComponent as TranscriptIcon } from '../../assets/icons/transcript.svg';
import c from './RecordingItem.module.scss';
import Transcript from '../common/Transcript';
import AudioPlayer from '../common/AudioPlayer';
import recordingStatuses from '../../constants/recordingStatuses';
import useRefreshRecordingStatus from '../../hooks/visits/useRefreshRecordingStatus';

function CustomToggle({ children, eventKey, onToggle }) {
  const decoratedOnClick = useAccordionButton(eventKey, onToggle);

  return (
    <Button type="button" variant="link" size="sm" onClick={decoratedOnClick} className={c.transcriptToggle}>
      {children}
    </Button>
  );
}
export default function RecordingItem({ r, number, providerId }) {
  const [recording, setRecording] = useState(r);
  const [isOpen, setIsOpen] = useState(false);
  const [loadTranscript, setLoadTranscript] = useState(false);
  const isShowToggleButton = [recordingStatuses.FAIL, recordingStatuses.SUCCESS, recordingStatuses.COMPLETED].includes(
    // eslint-disable-next-line comma-dangle
    recording.status
  );
  const [refetch, loading] = useRefreshRecordingStatus({
    variables: { providerId, recordingId: recording.id },
    onCompleted: (data) => {
      setRecording({ ...recording, status: data.recording.status });
    },
  });
  useEffect(() => {
    if (isOpen) setLoadTranscript(true);
  }, [isOpen]);
  return (
    <div className={classNames(c.container, 'bg-white')}>
      <div className={classNames(c.header, 'bg-light')}>
        <div className={c.headerTitle}>
          <h6>Recording #{number}</h6>
          {isShowToggleButton ? (
            <CustomToggle
              eventKey={recording.id}
              onToggle={() => {
                setIsOpen((o) => !o);
              }}
            >
              <TranscriptIcon />
              {recording.status === recordingStatuses.FAIL ? ' Transcription failed' : ' Toggle transcript'}
            </CustomToggle>
          ) : null}
          {recording.status === recordingStatuses.UPLOADED ? (
            <div>
              Not transcribed <RefreshButton loading={loading} refetch={refetch} />
            </div>
          ) : null}
          {recording.status === recordingStatuses.TRANSCRIBING ? (
            <div>
              Transcribing... <RefreshButton loading={loading} refetch={refetch} />
            </div>
          ) : null}
        </div>
        <div className={c.playerContainer}>
          <AudioPlayer id={recording.id} url={recording.downloadRequest.uri} date={recording.recordingDate} />
        </div>
      </div>

      <Accordion.Collapse eventKey={recording.id} in={isOpen}>
        <>
          {recording.status === recordingStatuses.FAIL ? (
            <Alert className="mb-0" variant="danger">
              {recording.transcriptError || 'Unknown error'}
            </Alert>
          ) : null}

          {recording.status === recordingStatuses.SUCCESS ? (
            <Alert className="mb-0" variant="warning">
              Transcription finished, but not available yet. Try again later
              <RefreshButton loading={loading} refetch={refetch} />
            </Alert>
          ) : null}

          {loadTranscript && recording.status === recordingStatuses.COMPLETED ? (
            <Transcript providerId={providerId} recordingId={recording.id} />
          ) : null}
        </>
      </Accordion.Collapse>
    </div>
  );
}

function RefreshButton({ refetch, loading }) {
  return (
    <Button
      onClick={() => {
        refetch();
      }}
      size="sm"
      variant="link"
    >
      {' '}
      {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Refresh'}
    </Button>
  );
}
