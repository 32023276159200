import { Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { groupBy, orderBy } from 'lodash';
import { format } from 'date-fns';
import NotificationItem from './NotificationItem';
import Loading from '../common/Loading';
import ErrorAlert from '../common/ErrorAlert';
import useMarkAllNotificationsAsRead from '../../hooks/notifications/useMarkAllNotificationsAsRead';
import Button from '../common/Button';
import useNotification from '../../hooks/notifications/useNotificationQuery';
import { customDateFormatForNotifications } from '../../utils/date';

const notificationsToSections = (data) => {
  if (!Array.isArray(data) || data.length === 0) return [];
  const groupedNotifications = groupBy(data, ({ date }) => format(new Date(date), 'yyyy-MM-dd'));
  const sections = Object.entries(groupedNotifications).map(([dateString, list]) => ({
    date: new Date(dateString),
    notifications: list,
  }));

  const orderedSections = orderBy(sections, ['date'], ['desc']);
  return orderedSections;
};

const Notifications = () => {
  const { providerId } = useParams();
  const [fetchMoreDataLoading, setFetchMoreDataLoading] = useState(false);
  const [data, loading, error, , { fetchMore }] = useNotification({
    variables: { providerId, limit: 9, skip: 0 },
    fetchPolicy: 'cache-and-network',
  });
  const [markAllNotificationsAsRead] = useMarkAllNotificationsAsRead();
  useEffect(
    () => () => {
      markAllNotificationsAsRead();
    },
    [markAllNotificationsAsRead]
  );
  return (
    <div className="notifications">
      <h3 className="mb-4">Notifications</h3>
      {loading && !data?.data ? <Loading /> : null}
      {error ? <ErrorAlert error={error} /> : null}
      {Array.isArray(data?.data)
        ? notificationsToSections(data.data).map(({ date: sectionDate, notifications }) => (
            <div key={sectionDate}>
              <SectionHeader date={sectionDate} />
              {notifications.map(({ id, body, date, read, payload }) => (
                <NotificationItem key={id} message={body} date={date} read={read} payload={payload} className="mb-2" />
              ))}
            </div>
          ))
        : null}
      {data?.data.length === 0 ? <Alert variant="info">Your notification list is empty</Alert> : null}
      <div className="text-center mb-2">
        {data?.count && data.count !== data.data.length ? (
          <Button
            onClick={async () => {
              setFetchMoreDataLoading(true);
              await fetchMore({ variables: { skip: data.data.length } });
              setFetchMoreDataLoading(false);
            }}
            loading={fetchMoreDataLoading}
            variant="outline-secondary"
            size="sm"
            spinnerVariant="secondary"
          >
            Load more
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const SectionHeader = ({ date }) => (
  <div className="d-flex justify-content-between mb-3 mt-3">
    <h5 className="m-0">{customDateFormatForNotifications(date)}</h5>
    <h5 className="m-0 text-muted">{format(date, 'iiii')}</h5>
  </div>
);

export default Notifications;
