import { confirmAlert } from '@uplab/react-confirm-alert';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import FillWithNoteTemplates from './FillWithNoteTemplates';
import FillWithPatientNotes from './FillWithPatientNotes';
import Tabs from '../common/Tabs';

const scrollableTarget = 'modalScrollableTarget';

const tabs = [
  {
    key: 'noteTemplates',
    label: 'Note Templates',
  },
  {
    key: 'patientNotes',
    label: 'Patient Notes',
  },
];

const NoteTemplatesModal = ({ onClose, fill, show, providerId, patientId }) => {
  const [activeTab, setActiveTab] = useState('noteTemplates');
  return (
    <Modal scrollable size="xl" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Note Template</Modal.Title>
      </Modal.Header>
      <Modal.Body id={scrollableTarget}>
        <Tabs tabs={tabs} activeTab={activeTab} onActiveTabChange={setActiveTab} withoutMarginTop />
        {activeTab === 'noteTemplates' ? (
          <FillWithNoteTemplates fill={fill} scrollableTarget={scrollableTarget} />
        ) : (
          <FillWithPatientNotes
            fill={fill}
            scrollableTarget={scrollableTarget}
            providerId={providerId}
            patientId={patientId}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default NoteTemplatesModal;

export function showNoteTemplatesModal({ fill, providerId, patientId }) {
  confirmAlert({
    customUI: ({ onClose }) => (
      <NoteTemplatesModal
        fill={(data) => {
          fill(data);
          onClose();
        }}
        onClose={onClose}
        show
        providerId={providerId}
        patientId={patientId}
      />
    ),
  });
}
