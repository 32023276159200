import { gql } from '@apollo/client';

export const HISTORIC_METRICS_FRAGMENT = gql`
  fragment historicMetrics on HistoricMetrics {
    type
    name
    value
  }
`;

export const REAL_TIME_METRICS_FRAGMENT = gql`
  fragment realTimeMetrics on RealTimeMetrics {
    type
    name
    value
  }
`;
