import { gql } from '@apollo/client';
import PHRASE_FRAGMENT from '../fragments/transcriptPhrases';

const phrases = gql`
  query recording($recordingId: ID!, $providerId: ID!) {
    recording(recordingId: $recordingId, providerId: $providerId) {
      transcriptPhrases {
        ...phrase
      }
    }
  }
  ${PHRASE_FRAGMENT}
`;

export default phrases;
