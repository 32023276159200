import { Badge } from 'react-bootstrap';
import c from './VisitStatus.module.scss';
import visitStatuses from '../../constants/visitStatuses';

const statuses = [
  {
    id: visitStatuses.SCHEDULED,
    title: 'Scheduled',
    bg: 'secondary',
  },
  {
    id: visitStatuses.RECORDING,
    title: 'Recording',
    bg: 'secondary',
  },
  {
    id: visitStatuses.DOCUMENTING,
    title: 'Document',
    bg: 'warning',
  },
  {
    id: visitStatuses.NOTE_SUBMITTED,
    title: 'Waiting',
    bg: 'secondary',
  },
  {
    id: visitStatuses.NOTE_REJECTED,
    title: 'Amend',
    bg: 'danger',
  },
  {
    id: visitStatuses.NOTE_APPROVED,
    title: 'Approved',
    bg: 'info',
  },
  {
    id: visitStatuses.COMPLETED,
    title: 'Completed',
    bg: 'success',
  },
];

export default function VisitStatus({ status }) {
  const currentStatus = statuses.find(({ id }) => id === status);

  // eslint-disable-next-line no-console
  if (currentStatus === undefined) console.warn(`Passed unsupported status to VisitStatus: ${status}`);

  return currentStatus === undefined ? (
    <Badge bg="secondary" className={c.badge}>
      {status}
    </Badge>
  ) : (
    <Badge bg={currentStatus.bg} className={c.badge}>
      {currentStatus.title}
    </Badge>
  );
}
