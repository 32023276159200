import { Spinner } from 'react-bootstrap';
import c from './LoadingModal.module.scss';

const defaultMarginTop = 210;
const LoadingModal = ({ marginTop = 0 }) => (
  <div className={c.loading}>
    <Spinner
      style={{ marginTop: `${defaultMarginTop + marginTop}px` }}
      className={c.spinner}
      animation="border"
      variant="secondary"
    />
  </div>
);

export default LoadingModal;
