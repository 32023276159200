import { gql } from '@apollo/client';
import INVITE_FRAGMENT from '../fragments/invite';

const invites = gql`
  query invites($limit: Int!, $skip: Int!, $closed: Boolean, $role: Role) {
    invites(limit: $limit, skip: $skip, closed: $closed, role: $role) {
      count
      data {
        ...invite
      }
    }
  }
  ${INVITE_FRAGMENT}
`;

export default invites;
