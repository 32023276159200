import { gql } from '@apollo/client';

const VISIT_STATUS_HISTORY_FRAGMENT = gql`
  fragment visitStatusHistory on VisitHistory {
    id
    formattedMessage
    date
    amendments {
      id
      path
      downloadRequest {
        uri
      }
      recordingDate
      durationSeconds
      createdAt
    }
    soap {
      id
      subjective
      objective
      assessment
      plan
      createdAt
    }
  }
`;

export default VISIT_STATUS_HISTORY_FRAGMENT;
