import { useQuery } from '@apollo/client';
import patientById from '../../graphql/queries/patientById';

const usePatientById = ({ providerId, patientId }) => {
  const { data, loading } = useQuery(patientById, {
    variables: { providerId, patientId },
    fetchPolicy: 'cache-and-network',
  });

  return { patient: data?.patientById, loading };
};

export default usePatientById;
