import { Image, Spinner } from 'react-bootstrap';
import c from './FullscreenLoading.module.scss';
import Logo from '../../assets/images/logo.svg';

export default function FullscreenLoading() {
  return (
    <div className={c.container}>
      <Image className={c.logo} src={Logo} />
      <Spinner className={c.spinner} animation="border" variant="secondary" />
    </div>
  );
}
