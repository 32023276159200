import { Button, FormControl } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import c from './SearchInput.module.scss';

export default function SearchInput({ value, onChange }) {
  return (
    <div className={c.searchContainer}>
      <FormControl
        placeholder="Search"
        aria-label="Search"
        className={c.searchInput}
        value={value}
        onChange={onChange ? (e) => onChange(e.target.value) : undefined}
      />
      <Button className={c.searchButton} variant="outline-secondary">
        <Search />
      </Button>
    </div>
  );
}
