import { useHistory } from 'react-router-dom';
import usePaginatedQuery from '../../../hooks/common/usePaginatedQuery';
import TableWithLoadingAndErrorAlert from '../TableWithLoadingAndErrorAlert';
import usersTable from '../../../graphql/queries/usersTable';
import usersTableColumns from '../../../constants/userTableColumns';

const AdminsOrGuestsTable = ({ filter, keyExtractor, handleClickRow, activeTab }) => {
  const history = useHistory();
  const { data, loading, error, totalPages, page, refetch, goToNPage } = usePaginatedQuery(usersTable, {
    itemsPerPage: 10,
    fetchPolicy: 'cache-and-network',
    variables: {
      roles: [activeTab],
      userStatus: filter === 'ANY' ? null : filter,
    },
  });
  return (
    <TableWithLoadingAndErrorAlert
      error={error}
      loading={loading && !data}
      data={data}
      tableProps={{
        loading,
        keyExtractor,
        columns: usersTableColumns.find((item) => item.id === activeTab).column,
        noData: 'No users found',
        onClickRow: (row) => {
          handleClickRow(row.id, activeTab, history);
        },
        context: { refetch },
      }}
      tablePaginationProps={{ page, totalPages, goToNPage }}
    />
  );
};

export default AdminsOrGuestsTable;
