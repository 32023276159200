import { confirmAlert } from '@uplab/react-confirm-alert';
import { toast } from 'react-toastify';
import FormModal from '../common/FormModal';
import { ReactComponent as CreateNoteTemplateTitleIcon } from '../../assets/icons/create-note-templates-title.svg';
import NoteTemplatesForm from './NoteTemplateForm';
import useCreateNoteTemplate from '../../hooks/noteTemplates/useCreateNoteTemplate';

const CreateNoteTemplate = ({ show, onClose, onCompleted, initialValue }) => {
  const [createNoteTemplate, { error }] = useCreateNoteTemplate({
    onCompleted: (data) => {
      onClose();
      if (typeof onCompleted === 'function') onCompleted(data);
    },
  });
  const onSubmit = async (data) => {
    await createNoteTemplate({ variables: { data } });
    onClose();
    toast.success('Note template successfully created');
  };
  return (
    <FormModal
      initialValues={initialValue}
      error={error}
      show={show}
      onClose={onClose}
      onSubmit={onSubmit}
      TitleIcon={CreateNoteTemplateTitleIcon}
      Form={NoteTemplatesForm}
    />
  );
};

export default CreateNoteTemplate;

export function showCreateNoteTemplateModal({ onCompleted, initialValue }) {
  confirmAlert({
    customUI: ({ onClose }) => (
      <CreateNoteTemplate onClose={onClose} show onCompleted={onCompleted} initialValue={initialValue} />
    ),
  });
}
