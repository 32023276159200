import { useEffect } from 'react';
import useMeQuery from './useMeQuery';

export default function usePersistUserRole() {
  const [user] = useMeQuery({ fetchPolicy: 'cache-only' });

  const role = user?.role;
  useEffect(() => {
    sessionStorage.setItem('x-user-role', role || '');
  }, [role]);
}
