export default {
  isProduction: process.env.NODE_ENV === 'production',
  isDevelopment: process.env.NODE_ENV !== 'development',
  graphqlServerUrl: process.env.REACT_APP_GRAPHQL_SERVER,
  azure: {
    ad: {
      appId: process.env.REACT_APP_AZURE_AD_APP_ID,
      tenantName: process.env.REACT_APP_AZURE_AD_TENANT_NAME,
      loginPolicy: process.env.REACT_APP_AZURE_AD_LOGIN_POLICY,
      resetPasswordPolicy: process.env.REACT_APP_AZURE_AD_RESET_PASSWORD_POLICY,
      scopes: ['openid', process.env.REACT_APP_AZURE_AD_APP_ID],
    },
  },
};
