import { useMutation } from '@apollo/client';
import assignScribesToProviderMutation from '../../graphql/mutations/assignScribesToProvider';
import assignScribeToProvidersMutation from '../../graphql/mutations/assignScribeToProviders';

const useAssignScribeToProvider = (isProviderPage, options) => {
  const [assignScribeToProvider, rest] = useMutation(
    isProviderPage ? assignScribesToProviderMutation : assignScribeToProvidersMutation,
    options
  );
  return [assignScribeToProvider, rest];
};

export default useAssignScribeToProvider;
