import { gql } from '@apollo/client';
import VISIT_STATUS_HISTORY_FRAGMENT from '../fragments/visitStatusHistory';

const visitStatusHistory = gql`
  query visit($providerId: ID, $visitId: ID!) {
    visit(providerId: $providerId, visitId: $visitId) {
      history {
        ...visitStatusHistory
      }
    }
  }
  ${VISIT_STATUS_HISTORY_FRAGMENT}
`;

export default visitStatusHistory;
