import { Route, Switch } from 'react-router-dom';

import Dashboard from '../pages/scribe/Dashboard';
import Providers from '../pages/scribe/Providers';
import ProviderDashboard from '../pages/scribe/ProviderDashboard';

import paths from './paths';

import AppLayout from '../components/layout/AppLayout';
import PatientList from '../pages/scribe/PatientList';
import PatientOverview from '../pages/scribe/PatientOverview';
import Documents from '../pages/scribe/Documents';
import ProviderRouteAccessGate from '../modules/currentProvider/ProviderRouteAccessGate';
import NoteTemplates from '../pages/scribe/NoteTemplates';
import VisitOverview from '../pages/scribe/VisitOverview';

const ScribeRouter = () => (
  <AppLayout>
    <Switch>
      <Route path={paths.dashboard} exact component={Dashboard} />
      <Route path={paths.providers} exact component={Providers} />
      <Route path={paths.allDocuments} exact component={Documents} />
      <Route path={paths.noteTemplates} exact component={NoteTemplates} />
      <Route path={paths.documents} exact>
        <ProviderRouteAccessGate>
          <Documents />
        </ProviderRouteAccessGate>
      </Route>
      <Route path={paths.patients} exact>
        <ProviderRouteAccessGate>
          <PatientList />
        </ProviderRouteAccessGate>
      </Route>
      <Route path={paths.providerDashboard} exact>
        <ProviderRouteAccessGate>
          <ProviderDashboard />
        </ProviderRouteAccessGate>
      </Route>
      <Route path={paths.patientOverview} exact>
        <ProviderRouteAccessGate>
          <PatientOverview />
        </ProviderRouteAccessGate>
      </Route>
      <Route path={paths.visitOverview} exact>
        <ProviderRouteAccessGate>
          <VisitOverview />
        </ProviderRouteAccessGate>
      </Route>
    </Switch>
  </AppLayout>
);

export default ScribeRouter;
