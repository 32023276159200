import { useEffect } from 'react';
import FullscreenLoading from '../../components/common/FullscreenLoading';
import useLogout from '../../hooks/auth/useLogout';

export default function Logout() {
  const logout = useLogout();

  useEffect(() => logout(), [logout]);

  return <FullscreenLoading />;
}
