import { Form } from 'react-bootstrap';

const Select = ({ clearable = false, emptyOptionLabel, options, value, onChange }) => (
  <Form.Select value={value} onChange={(e) => onChange(e.target.value)}>
    {clearable && <option value="">{value === '' ? emptyOptionLabel : ''}</option>}
    {options.map((el) => (
      <option key={el.value} value={el.value}>
        {el.label}
      </option>
    ))}
  </Form.Select>
);

export default Select;
