import { gql } from '@apollo/client';
import SCRIBE from '../fragments/scribe';

const scribes = gql`
  query scribes($limit: Int!, $skip: Int!, $userStatus: UserStatuses) {
    scribes(limit: $limit, skip: $skip, userStatus: $userStatus) {
      count
      data {
        ...scribe
      }
    }
  }
  ${SCRIBE}
`;

export default scribes;
