import { gql } from '@apollo/client';
import PATIENT_TABLE_ITEM_FRAGMENT from '../fragments/patientTableItem';

const patientsTableQuery = gql`
  query patientsByProviderId($limit: Int!, $skip: Int!, $searchQuery: String, $providerId: ID!) {
    patientsByProviderId(limit: $limit, skip: $skip, searchQuery: $searchQuery, providerId: $providerId) {
      count
      data {
        ...patientTableItem
      }
    }
  }
  ${PATIENT_TABLE_ITEM_FRAGMENT}
`;

export default patientsTableQuery;
