import { Col, Row } from 'react-bootstrap';
import ErrorAlert from '../common/ErrorAlert';
import StatCard from '../dashboard/StatCard';
import StatCardsLoading from '../dashboard/StatCardsLoading';
import c from './HistoricMetrics.module.scss';

const HistoricMetrics = ({ error, loading, metrics }) => (
  <>
    {error ? <ErrorAlert error={error} /> : null}
    <Row className={c.cards}>
      {loading && metrics.length === 0 ? <StatCardsLoading xl={12} numberOfCards={8} /> : null}
    </Row>
    <Row className={c.cards}>
      {metrics.map((props) => (
        <Col sm={6} md={4} lg={3} xl={12} key={props.id} className="mb-2">
          <StatCard loading={loading} {...props} />
        </Col>
      ))}
    </Row>
  </>
);

export default HistoricMetrics;
