import { gql } from '@apollo/client';
import NOTIFICATION from '../fragments/notification';

const notificationsQuery = gql`
  query notifications($limit: Int!, $skip: Int!, $read: Boolean, $providerId: ID) {
    notifications(limit: $limit, skip: $skip, read: $read, providerId: $providerId) {
      count
      data {
        ...notification
      }
    }
  }
  ${NOTIFICATION}
`;

export default notificationsQuery;
