import { createContext } from 'react';
import useCurrentProviderContextValue from './useCurrentProviderContextValue';

const CurrentProviderContext = createContext({
  provider: null,
  providerId: null,
  setProviderId: () => {},
  loading: false,
  error: null,
});

export default CurrentProviderContext;

export function CurrentProviderContextProvider({ children }) {
  const value = useCurrentProviderContextValue();
  return <CurrentProviderContext.Provider value={value}>{children}</CurrentProviderContext.Provider>;
}
