import { gql } from '@apollo/client';
import PATIENT_FOR_EDIT_FRAGMENT from '../fragments/patientForEdit';

const patientById = gql`
  query patientById($patientId: ID!, $providerId: ID!) {
    patientById(patientId: $patientId, providerId: $providerId) {
      ...patientForEdit
    }
  }
  ${PATIENT_FOR_EDIT_FRAGMENT}
`;

export default patientById;
