import { Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import FormikGroup from '../common/formik/FromikGroup';
import FormikFormSelect from '../common/formik/FormikFormSelect';
import roles, { rolesTitleMapping } from '../../constants/roles';
import Button from '../common/Button';

delete rolesTitleMapping[roles.GUEST];

const schema = yup.object().shape({
  role: yup.mixed().label('Role').oneOf(Object.keys(rolesTitleMapping)).required(),
});

const defaultValues = {
  role: roles.PROVIDER,
};

const AssignRoleForm = ({ onSubmit, onClose }) => (
  <Formik validationSchema={schema} initialValues={defaultValues} onSubmit={onSubmit}>
    {({ isSubmitting }) => (
      <FormikForm>
        <FormikGroup label="Role">
          <FormikFormSelect required name="role">
            {Object.entries(rolesTitleMapping).map(([value, title]) => (
              <option key={value} value={value}>
                {title}
              </option>
            ))}
          </FormikFormSelect>
        </FormikGroup>
        <div className="mt-5">
          <Row>
            <Col className="d-flex justify-content-end">
              <Button className="me-2" onClick={onClose} variant="secondary">
                Cancel
              </Button>
              <Button loading={isSubmitting} type="submit">
                Assign role
              </Button>
            </Col>
          </Row>
        </div>
      </FormikForm>
    )}
  </Formik>
);

export default AssignRoleForm;
