import { Card, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ReactComponent as NoteIcon } from '../../assets/icons/note.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import c from './SOAPCard.module.scss';
import useMarkVisitAsCompleted from '../../hooks/visits/useMarkVisitAsCompleted';
import SOAPCardText from './SOAPCardText';
import { showCreateNoteTemplateModal } from '../noteTemplates/CreateNoteTemplateModal';
import Button from '../common/Button';

const SOAPCard = ({
  subjective,
  objective,
  assessment,
  plan,
  isApproved,
  visitId,
  providerId,
  isVisibleHeader = true,
}) => {
  const [markVisitAsCompleted, { loading }] = useMarkVisitAsCompleted({
    onCompleted: () => {
      toast.success('Visit marked as completed!');
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });
  const onMarkVisitAsCompleted = () => {
    markVisitAsCompleted({ variables: { visitId, providerId } });
  };
  return (
    <Card>
      {isVisibleHeader ? (
        <Card.Header>
          <Row>
            <Col className={c.title}>
              <h5 style={{ margin: '0px' }}>Note Overview</h5>
            </Col>
            <Col className="d-flex justify-content-end">
              <Button
                onClick={() => {
                  showCreateNoteTemplateModal({ initialValue: { subjective, objective, assessment, plan } });
                }}
                className={c.convertIntoNoteTemplateButton}
                variant="outline-primary"
                size="sm"
              >
                <NoteIcon /> Convert into Note Template
              </Button>
            </Col>
          </Row>
        </Card.Header>
      ) : null}
      <Card.Body>
        <Card.Title className="mt-3">
          <h4>
            <strong>Subjective</strong>
          </h4>
        </Card.Title>
        <SOAPCardText text={subjective} />
        <Card.Title className="mt-4">
          <h4>
            <strong>Objective</strong>
          </h4>
        </Card.Title>
        <SOAPCardText text={objective} />
        <Card.Title className="mt-4">
          <h4>
            <strong>Assessment</strong>
          </h4>
        </Card.Title>
        <SOAPCardText text={assessment} />
        <Card.Title className="mt-4">
          <h4>
            <strong>Plan</strong>
          </h4>
        </Card.Title>
        <SOAPCardText text={plan} />
        {isApproved ? (
          <Button size="sm" loading={loading} onClick={onMarkVisitAsCompleted}>
            <CheckIcon /> Mark as completed
          </Button>
        ) : null}
      </Card.Body>
    </Card>
  );
};

export default SOAPCard;
