import { Modal } from 'react-bootstrap';
import ErrorAlert from './ErrorAlert';
import LoadingModal from './LoadingModal';

const FormModal = ({
  show,
  onClose,
  onSubmit,
  isEdit,
  initialValues,
  loading,
  error,
  TitleIcon,
  titleText,
  Form,
  size,
  ...rest
}) => (
  <Modal show={show} onHide={onClose} size={size} enforceFocus={false}>
    <Modal.Header closeButton>
      {titleText ? (
        <h4 className="fw-bold" style={{ marginLeft: 'auto', marginBottom: '0px' }}>
          {titleText}
        </h4>
      ) : null}
      {TitleIcon ? <TitleIcon style={{ marginLeft: 'auto' }} /> : null}
    </Modal.Header>
    <Modal.Body>
      {error ? <ErrorAlert error={error} /> : null}
      {/* TODO: replace with loading spinner */}
      {loading && isEdit ? <LoadingModal /> : null}
      {!loading ? (
        <Form onClose={onClose} onSubmit={onSubmit} initialValues={initialValues} {...rest} isEdit={isEdit} />
      ) : null}
    </Modal.Body>
  </Modal>
);

export default FormModal;
