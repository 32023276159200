import { useQuery } from '@apollo/client';
import visit from '../../graphql/queries/visit';

const useVisit = ({ providerId, visitId }) => {
  const { data, loading, error, refetch } = useQuery(visit, {
    variables: {
      providerId,
      visitId,
    },
    fetchPolicy: 'cache-and-network',
  });

  return { visit: data?.visit, loading, error, refetch };
};

export default useVisit;
