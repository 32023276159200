import { toast } from 'react-toastify';
import useUnassignScribeFromProvider from '../../hooks/users/useUnassignScribeFromProvider';
import { confirm } from '../common/ConfirmAlert';
import Button from '../common/Button';

const UnassignButton = ({ id, refetch }) => {
  const [unassignScribeFromProvider, { loading }] = useUnassignScribeFromProvider({
    onCompleted: () => {
      toast.success('successfully unassigned');
      refetch();
    },
    onError: () => {
      toast.error('failed to unassigned');
    },
  });
  const onUnassign = () => {
    unassignScribeFromProvider({ variables: { assignmentId: id } });
  };
  return (
    <Button
      disabled={loading}
      size="sm"
      variant="link"
      onClick={(e) => {
        e.stopPropagation();
        confirm({
          title: 'Unassign',
          text: 'Do you really want to unassign?',
          confirmButtonText: 'Unassign',
          onConfirm: onUnassign,
        });
      }}
      spinnerVariant="primary"
      style={{ textDecoration: 'none', padding: '0px' }}
      loading={loading}
    >
      Unassign
    </Button>
  );
};

export default UnassignButton;
