import { gql } from '@apollo/client';

const ACTION_ITEMS_FRAGMENT = gql`
  fragment actionItems on Visit {
    id
    status
    provider {
      id
      displayName
    }
    patient {
      id
      fullName
    }
    updatedAt
  }
`;

export default ACTION_ITEMS_FRAGMENT;
