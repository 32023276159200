import {
  endOfWeek,
  format,
  formatDistanceStrict,
  formatRFC3339,
  getUnixTime,
  hoursToSeconds,
  isToday,
  isTomorrow,
  isYesterday,
  startOfWeek,
} from 'date-fns';
import secondsToHours from 'date-fns/secondsToHours';
import { DATE_TIME_FORMAT, TIME_FORMAT } from '../constants/date';

export function formatRFC3339Date(date) {
  return formatRFC3339(date).split('T')[0];
}

export function formatRFC3339Time(date) {
  return formatRFC3339(date).split('T')[1];
}

export function formatRFC3339DateTime(date) {
  return formatRFC3339(date);
}

export function dobToDate(date) {
  return new Date(`${date}T00:00:00`);
}

export function customDateFormat(date) {
  const dateInSeconds = getUnixTime(date);
  const nowDateInSeconds = getUnixTime(new Date());
  const differenceBetweenDatesInSecond = nowDateInSeconds - dateInSeconds;
  if (differenceBetweenDatesInSecond >= 0 && differenceBetweenDatesInSecond <= hoursToSeconds(12)) {
    if (differenceBetweenDatesInSecond < 60) {
      return '< 1 minute ago';
    }
    return formatDistanceStrict(date, new Date(), { addSuffix: true });
  }
  if (isToday(date)) {
    return `Today at ${format(date, TIME_FORMAT)}`;
  }
  if (isYesterday(date)) {
    return `Yesterday at ${format(date, TIME_FORMAT)}`;
  }
  if (isTomorrow(date)) {
    return `Tomorrow at ${format(date, TIME_FORMAT)}`;
  }
  return format(date, DATE_TIME_FORMAT);
}

export function customSecondsToHoursFormat(seconds) {
  if (seconds < hoursToSeconds(1) && seconds > 0) {
    return '< 1';
  }
  return secondsToHours(seconds);
}

export const customDateFormatForNotifications = (date) => {
  if (isToday(date)) {
    return 'today';
  }
  if (isYesterday(date)) {
    return 'yesterday';
  }
  return format(date, 'MMM d');
};

export const getTimeRangeThisWeek = () => ({
  startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
  endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
});
