import { confirmAlert } from '@uplab/react-confirm-alert';
import useEditVisit from '../../hooks/visits/useEditVisit';
import useVisitByIdForEditQuery from '../../hooks/visits/useVisitByIdForEditQuery';
import FormModal from '../common/FormModal';
import VisitForm from './VisitForm';
import { ReactComponent as EditVisitTitleIcon } from '../../assets/icons/edit-visit-title.svg';

const EditVisit = ({ onClose, show, providerId, visitId }) => {
  const [data, loading, error] = useVisitByIdForEditQuery({
    variables: { visitId, providerId },
  });
  const [editVisit, { error: editVisitError }] = useEditVisit({
    onCompleted: () => {
      onClose();
    },
  });
  const onSubmit = async (newData) => {
    await editVisit({
      variables: {
        data: newData,
        providerId,
        visitId,
      },
    });
  };
  return (
    <FormModal
      isEdit
      show={show}
      onClose={onClose}
      onSubmit={onSubmit}
      TitleIcon={EditVisitTitleIcon}
      Form={VisitForm}
      loading={loading}
      error={error || editVisitError}
      initialValues={data}
    />
  );
};
export function showEditVisitModal({ providerId, visitId }) {
  confirmAlert({
    customUI: ({ onClose }) => <EditVisit onClose={onClose} show providerId={providerId} visitId={visitId} />,
  });
}

export default EditVisit;
