import { range } from 'lodash';
import { Pagination } from 'react-bootstrap';

const getPageOptions = ({ page, totalPages, maxOptions = 3 }) => {
  const FIRST_PAGE = 1;
  const LAST_PAGE = totalPages;

  let start = Math.max(page - 1, FIRST_PAGE);
  const end = Math.min(start + (maxOptions - 1), LAST_PAGE);
  if (end === totalPages) start = Math.max(end - (maxOptions - 1), FIRST_PAGE);

  return range(start, end + 1);
};
export default function TablePagination({ totalPages, page, goToNPage }) {
  if (totalPages <= 1) return null;
  return (
    <Pagination>
      <Pagination.First disabled={page === 1} onClick={() => goToNPage(1)} />
      {getPageOptions({ page, totalPages }).map((n) => (
        <Pagination.Item key={n} active={n === page} onClick={() => goToNPage(n)}>
          {n}
        </Pagination.Item>
      ))}

      <Pagination.Last disabled={page === totalPages} onClick={() => goToNPage(totalPages)} />
    </Pagination>
  );
}
