import { useState } from 'react';
import useProviderById from './useProviderById';

export default function useCurrentProviderContextValue() {
  const [providerId, setProviderId] = useState(null);

  const { loading, error, data: provider } = useProviderById(providerId);

  return { provider, providerId, setProviderId, loading, error };
}
