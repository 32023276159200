import { gql } from '@apollo/client';
import USER_TABLE_ITEM_FRAGMENT from '../fragments/userTableItem';

const usersTable = gql`
  query users($limit: Int!, $skip: Int!, $roles: [Role]!, $searchQuery: String, $userStatus: UserStatuses) {
    users(limit: $limit, skip: $skip, roles: $roles, searchQuery: $searchQuery, userStatus: $userStatus) {
      count
      data {
        ...userTableItem
      }
    }
  }
  ${USER_TABLE_ITEM_FRAGMENT}
`;

export default usersTable;
