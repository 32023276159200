import { confirmAlert } from '@uplab/react-confirm-alert';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ErrorAlert from './ErrorAlert';
import Button from './Button';

const ConfirmAlert = ({
  show,
  onClose,
  title,
  text,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  onConfirm,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>{title}</Modal.Header>
      {error ? <ErrorAlert error={error} /> : null}
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} variant="secondary">
          {cancelButtonText}
        </Button>
        <Button
          onClick={async () => {
            try {
              setLoading(true);
              await onConfirm();
            } catch (e) {
              setError(e);
            } finally {
              setLoading(false);
            }
          }}
          variant="danger"
          loading={loading}
        >
          {confirmButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmAlert;

export function confirm({ title, text, confirmButtonText, cancelButtonText, onConfirm }) {
  confirmAlert({
    customUI: ({ onClose }) => (
      <ConfirmAlert
        show
        {...{
          onClose,
          title,
          text,
          confirmButtonText,
          cancelButtonText,
          onConfirm: async (...a) => {
            await onConfirm(...a);
            onClose();
          },
        }}
      />
    ),
  });
}
