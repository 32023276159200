import { Button, ButtonGroup } from 'react-bootstrap';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import useRemovePatient from '../../hooks/patiens/useRemovePatient';
import { showEditPatientModal } from './EditPatientModal';
import s from './PatientTableActionButtons.module.scss';

const ActionButton = ({ patientId, providerId }) => {
  const removePatient = useRemovePatient({ patientId, providerId });
  return (
    <ButtonGroup className={s.actionButtons}>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          removePatient();
        }}
        variant="outline-danger"
        size="sm"
      >
        <DeleteIcon />
      </Button>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          // setEditPatientId(patientId);
          showEditPatientModal({ patientId, providerId });
        }}
        variant="outline-secondary"
        size="sm"
      >
        <EditIcon />
      </Button>
    </ButtonGroup>
  );
};
export default ActionButton;
