import { Alert } from 'react-bootstrap';
import { getMessageFromGraphQLError } from '../../utils/helpers';

const guessTitleFromError = (error) => {
  const graphQLErrors = error?.graphQLErrors || [];
  if (graphQLErrors[0]?.extensions?.code === 'FORBIDDEN') return 'Forbidden';
  if (error.networkError?.result?.errors[0]?.extensions?.code === 'FORBIDDEN') return 'Forbidden';
  return null;
};
export default function ErrorAlert({ title: defaultTitle, error, children }) {
  if (!error) return null;

  const title = defaultTitle || guessTitleFromError(error) || 'An Error Occurred';
  return (
    <Alert variant="danger">
      <Alert.Heading>{title}</Alert.Heading>
      <p className="mb-0">{getMessageFromGraphQLError(error)}</p>
      <p className="mb-0">{children}</p>
    </Alert>
  );
}
