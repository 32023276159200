import { Button as BSButton, Spinner } from 'react-bootstrap';

const Button = ({
  children,
  className,
  loading,
  disabled,
  variant = 'primary',
  spinnerVariant = 'light',
  spinnerAnimation = 'border',
  onClick,
  ...rest
}) => (
  <BSButton className={className} disabled={disabled || loading} variant={variant} onClick={onClick} {...rest}>
    <>
      {loading ? (
        <>
          <Spinner size="sm" animation={spinnerAnimation} variant={spinnerVariant} />{' '}
        </>
      ) : null}
      {children}
    </>
  </BSButton>
);

export default Button;
