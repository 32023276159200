import { useRef } from 'react';
import { Card, Col } from 'react-bootstrap';
import Loading from '../common/Loading';

const StatCardsLoading = ({ numberOfCards = 3, xl = 2 }) => {
  const cards = useRef(new Array(numberOfCards).fill(0));
  return (
    <>
      {cards.current.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Col sm={6} md={4} lg={3} xl={xl} key={i} className="mb-2">
          <Card style={{ height: '100%' }}>
            <Card.Body>
              <Loading count={3} />
            </Card.Body>
          </Card>
        </Col>
      ))}
    </>
  );
};

export default StatCardsLoading;
