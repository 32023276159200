import { gql } from '@apollo/client';

const patientSelectOption = gql`
  query patientSelectOption($limit: Int!, $skip: Int!, $searchQuery: String, $providerId: ID!) {
    patientsByProviderId(limit: $limit, skip: $skip, searchQuery: $searchQuery, providerId: $providerId) {
      data {
        id
        fullName
      }
    }
  }
`;

export default patientSelectOption;
