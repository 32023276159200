import { Button, Card, Col } from 'react-bootstrap';
import cn from 'classnames';
import { toast } from 'react-toastify';
import { showEditNoteTemplateModal } from './EditNoteTemplateModal';
import c from './NoteTemplateCard.module.scss';
import useRemoveNoteTemplate from '../../hooks/noteTemplates/useRemoveNoteTemplate';

const NoteTemplateCard = ({ note, isModal, fill }) => {
  const removeNoteTemplate = useRemoveNoteTemplate(
    { id: note.id },
    {
      onCompleted: () => {
        toast.success('Note template successfully deleted');
      },
    }
  );
  return (
    <Col key={note.id} className="mt-3" md="4" sm="6" xs="12">
      <Card>
        <Card.Body>
          <Card.Title>
            <strong>{note.name}</strong>
          </Card.Title>
          <Card.Text className={cn('mt-4', c.cardText)}>
            <span className="text-muted">Subjective. </span>
            {note.subjective}
          </Card.Text>
          <Card.Text className={cn('mt-4', c.cardText)}>
            <span className="text-muted">Objective. </span>
            {note.objective}
          </Card.Text>
          <Card.Text className={cn('mt-4', c.cardText)}>
            <span className="text-muted">Assessment. </span>
            {note.assessment}
          </Card.Text>
          <Card.Text className={cn('mt-4', c.cardText)}>
            <span className="text-muted">Plan. </span>
            {note.plan}
          </Card.Text>
          {isModal ? (
            <Button
              onClick={() => {
                const { name, id, ...data } = note;
                fill(data);
              }}
              variant="link"
              className={c.actionButtons}
            >
              Fill
            </Button>
          ) : (
            <>
              <Button
                onClick={() => {
                  showEditNoteTemplateModal({ noteTemplateId: note.id });
                }}
                className={c.actionButtons}
                variant="link"
              >
                Edit
              </Button>
              <Button onClick={removeNoteTemplate} className={cn(c.deleteButton, c.actionButtons)} variant="link">
                Delete
              </Button>
            </>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default NoteTemplateCard;
