import { gql } from '@apollo/client';

const SOAP_FOR_PATIENT_NOTE_FRAGMENT = gql`
  fragment soapForPatientNote on SOAP {
    id
    subjective
    objective
    plan
    assessment
  }
`;

export default SOAP_FOR_PATIENT_NOTE_FRAGMENT;
