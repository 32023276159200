import { gql } from '@apollo/client';
import PATIENT_FOR_EDIT_FRAGMENT from '../fragments/patientForEdit';
import PATIENT_TABLE_ITEM_FRAGMENT from '../fragments/patientTableItem';

const editPatientMutation = gql`
  mutation updatePatient($patientId: ID!, $providerId: ID!, $data: UpdatePatientInput!) {
    updatePatient(data: $data, patientId: $patientId, providerId: $providerId) {
      ...patientTableItem
      ...patientForEdit
    }
  }
  ${PATIENT_FOR_EDIT_FRAGMENT}
  ${PATIENT_TABLE_ITEM_FRAGMENT}
`;

export default editPatientMutation;
