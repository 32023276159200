import { gql } from '@apollo/client';
import VISIT_FOR_EDIT_FRAGMENT from '../fragments/visitForEdit';

const visitByIdForEdit = gql`
  query visit($visitId: ID!, $providerId: ID) {
    visit(visitId: $visitId, providerId: $providerId) {
      ...visitForEdit
    }
  }
  ${VISIT_FOR_EDIT_FRAGMENT}
`;

export default visitByIdForEdit;
