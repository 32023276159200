import { useMutation } from '@apollo/client';
import { confirm } from '../../components/common/ConfirmAlert';
import removeVisitMutation from '../../graphql/mutations/removeVisit';

const useRemoveVisit = ({ visitId, providerId }, options) => {
  const [removeVisit] = useMutation(removeVisitMutation, {
    variables: { visitId, providerId },
    update: (cache) => {
      cache.evict({ id: `Visit:${visitId}` });
      cache.gc();
    },
    ...options,
  });
  const withConfirm = (...a) => {
    confirm({
      title: 'Confirm removal',
      text: 'Are you sure you want to delete this visit?',
      confirmButtonText: 'Delete',
      onConfirm: () => removeVisit(...a),
    });
  };
  return withConfirm;
};

export default useRemoveVisit;
