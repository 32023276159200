import useInfiniteScrollQuery from '../../hooks/common/useInfiniteScrollQuery';
import NoteTemplatesModalBody from './NoteTemplatesModalBody';
import noteTemplatesQuery from '../../graphql/queries/noteTemplates';

const FillWithNoteTemplates = ({ scrollableTarget, fill }) => {
  const [data, error, loading, , fetchMoreData] = useInfiniteScrollQuery(noteTemplatesQuery, {
    restOptions: {
      fetchPolicy: 'cache-and-network',
    },
  });
  return (
    <NoteTemplatesModalBody
      count={data?.count}
      data={data?.data}
      loading={loading}
      error={error}
      fetchMoreData={fetchMoreData}
      scrollableTarget={scrollableTarget}
      fill={fill}
    />
  );
};

export default FillWithNoteTemplates;
