import paths from '../../router/paths';
import c from './ProviderSelect.module.scss';
import MenuItem from './MenuItem';
import SidebarController from '../../services/SidebarController';

export default function ProviderSelect({ title }) {
  return (
    <>
      <span className={c.providerLabel}>Provider</span>
      <MenuItem
        onClick={() => {
          SidebarController.closeSidebar();
        }}
        className={c.providerMenuItem}
        title={title || 'Select provider...'}
        path={paths.providers}
        Icon={null}
        exact
        activeClassName={' '}
      />
      {/* <Dropdown
        disabled
        title="John Doe"
        Icon={null}
        items={[
          { key: 'provider-id-1', title: 'Recent Provider 1' },
          { key: 'provider-id-2', title: 'Recent Provider 2' },
          { key: 'provider-id-3', title: 'Recent Provider 3' },
          { key: 'all-providers', title: 'All providers', as: Link, className: 'text-primary', to: paths.providers },
        ]}
      /> */}
    </>
  );
}
