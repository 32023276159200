import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, from } from '@apollo/client';
import settings from '../constants/settings';
import acquireADAccessToken from '../utils/acquireADAccessToken';

const httpLink = new HttpLink({ uri: settings.graphqlServerUrl, credentials: 'same-origin' });

const authMiddleware = new ApolloLink(async (operation, forward) => {
  try {
    const token = await acquireADAccessToken();

    const authorization = `Bearer ${token}`;
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'x-client-app': 'web',
        'x-user-role': sessionStorage.getItem('x-user-role'),
        authorization,
      },
    }));

    return forward(operation);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('ApolloLink auth error', error);
    return forward(operation);
  }
});

const client = new ApolloClient({
  link: from([authMiddleware, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          notifications: {
            keyArgs: false,
            merge(existing, incoming, { args: { skip = 0 } }) {
              if (skip === 0 || !existing) {
                return incoming;
              }
              return { ...incoming, data: [...existing.data, ...incoming.data] };
            },
          },
        },
      },
    },
  }),
});

export default client;
