import { useMutation } from '@apollo/client';
import activateUser from '../../graphql/mutations/activateUser';
import deactivateUser from '../../graphql/mutations/deactivateUser';

const useDeactivateOrActivateUser = (deactivated, options) => {
  const [deactivateOrActivateUser, rest] = useMutation(deactivated ? activateUser : deactivateUser, options);
  return [deactivateOrActivateUser, rest];
};

export default useDeactivateOrActivateUser;
