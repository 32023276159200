import { gql } from '@apollo/client';

const VISITS_TABLE_ITEM = gql`
  fragment visitsTableItem on Visit {
    id
    providerId
    status
    reason
    complaint
    visitDate
    recordingsNumber
    recordingsDurationSeconds
    updatedAt

    patient {
      id
      fullName
    }

    provider {
      id
      displayName
    }
  }
`;

export default VISITS_TABLE_ITEM;
