import paths from '../../router/paths';
import { ReactComponent as AllDocumentsIcon } from '../../assets/icons/sidebar/all-documents.svg';
import { ReactComponent as DashboardIcon } from '../../assets/icons/sidebar/dashboard.svg';
import { ReactComponent as DocumentsIcon } from '../../assets/icons/sidebar/documents.svg';
import { ReactComponent as NoteTemplatesIcon } from '../../assets/icons/sidebar/note-templates.svg';
import { ReactComponent as PatientsIcon } from '../../assets/icons/sidebar/patients.svg';
import { ReactComponent as ProviderDashboardIcon } from '../../assets/icons/sidebar/provider-dashboard.svg';
import { ReactComponent as ProvidersIcon } from '../../assets/icons/sidebar/providers.svg';
import { ReactComponent as UsersIcon } from '../../assets/icons/sidebar/users.svg';

export const globalSidebarItems = [
  { title: 'Dashboard', path: paths.dashboard, Icon: DashboardIcon, exact: true },
  { title: 'Providers', path: paths.providers, Icon: ProvidersIcon, exact: true },
  { title: 'All Documents', path: paths.allDocuments, Icon: AllDocumentsIcon, exact: true },
  { title: 'Note Templates', path: paths.noteTemplates, Icon: NoteTemplatesIcon, exact: true },
];
export const providerSidebarItems = [
  { title: 'Provider Dashboard', path: paths.providerDashboard, Icon: ProviderDashboardIcon, exact: true },
  { title: 'Documents', path: paths.documents, Icon: DocumentsIcon, exact: true },
  { title: 'Patients', path: paths.patients, Icon: PatientsIcon, exact: true },
];

export const adminSidebarItems = [
  { title: 'Users', path: paths.users, Icon: UsersIcon, exact: true },
  { title: 'Statistics', path: paths.statistics, Icon: ProviderDashboardIcon, exact: true },
];
