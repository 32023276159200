import { Col, Modal, Row } from 'react-bootstrap';
import { confirmAlert } from '@uplab/react-confirm-alert';
import Select from 'react-select';
import { useState } from 'react';
import { toast } from 'react-toastify';
import useInfiniteScrollQuery from '../../hooks/common/useInfiniteScrollQuery';
import usersTable from '../../graphql/queries/usersTable';
import roles from '../../constants/roles';
import ErrorAlert from '../common/ErrorAlert';
import useDebouncedValue from '../../hooks/common/useDebouncedValue';
import useAssignScribeToProvider from '../../hooks/users/useAssignScribeToProvider';
import Button from '../common/Button';

const AssignScribeProviderModal = ({ show, onClose, isProviderPage, userId, refetch }) => {
  const [selectUsers, setSelectUsers] = useState([]);
  const [searchQuery, searchInputValue, setSearchInputValue] = useDebouncedValue();
  const [data, setData] = useState([]);
  const [isValid, setIsValid] = useState(true);

  const [, error, loading] = useInfiniteScrollQuery(usersTable, {
    variables: { roles: [isProviderPage ? roles.SCRIBE : roles.PROVIDER], searchQuery },
    restOptions: {
      onCompleted: ({ users }) => {
        setData(users.data.map((user) => ({ value: user.id, label: `${user.displayName}(${user.email})` })));
      },
    },
  });

  // eslint-disable-next-line operator-linebreak
  const [assignScribeToProvider, { error: assignScribeToProviderError, loading: assignScribeToProviderLoading }] =
    useAssignScribeToProvider(isProviderPage, {
      onCompleted: () => {
        toast.success('successfully assigned');
        onClose();
        refetch();
      },
    });

  const onSubmit = async () => {
    if (selectUsers.length === 0) {
      setIsValid(false);
    } else {
      const ids = selectUsers.map((user) => user.value);
      await assignScribeToProvider({
        variables: isProviderPage ? { providerId: userId, scribeIds: ids } : { scribeId: userId, providerIds: ids },
      });
    }
  };

  const onInputChange = (value) => {
    setSearchInputValue(value);
  };

  const onBlur = () => {
    if (selectUsers.length === 0) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: isValid ? '#ddd' : 'red',
    }),
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{isProviderPage ? 'Assign to Scribe' : 'Assign to Provider'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error || assignScribeToProviderError ? <ErrorAlert error={error || assignScribeToProviderError} /> : null}
        <Select
          styles={customStyles}
          onBlur={onBlur}
          formNoValidate={false}
          isMulti
          name={isProviderPage ? 'scribeSelect' : 'providerSelect'}
          options={data}
          onChange={setSelectUsers}
          isSearchable
          inputValue={searchInputValue}
          onInputChange={onInputChange}
          placeholder={loading && data.length === 0 ? 'Loading...' : 'Select...'}
        />
        {!isValid ? (
          <div>
            <small className="text-danger">Field is required</small>
          </div>
        ) : null}
        <Row className="mt-4">
          <Col className="d-flex justify-content-end">
            <Button loading={assignScribeToProviderLoading} disabled={!isValid} onClick={onSubmit}>
              Assign
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AssignScribeProviderModal;

export const showAssignScribeProviderModal = ({ isProviderPage, userId, refetch }) => {
  confirmAlert({
    customUI: ({ onClose }) => (
      <AssignScribeProviderModal
        onClose={onClose}
        show
        isProviderPage={isProviderPage}
        userId={userId}
        refetch={refetch}
      />
    ),
  });
};
