import { gql } from '@apollo/client';
import VISIT_FOR_EDIT_FRAGMENT from '../fragments/visitForEdit';

const editVisitMutation = gql`
  mutation updateVisit($data: UpdateVisitInput!, $providerId: ID, $visitId: ID!) {
    updateVisit(data: $data, providerId: $providerId, visitId: $visitId) {
      ...visitForEdit
    }
  }
  ${VISIT_FOR_EDIT_FRAGMENT}
`;

export default editVisitMutation;
