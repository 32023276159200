import { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import CustomToggle from './CustomToggle';

import SOAPCard from './SOAPCard';

const NoteOverview = ({ subjective, objective, assessment, plan, isStatusHistory }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={isStatusHistory ? '' : 'mb-4'}>
      <CustomToggle
        isSmallToggleButton={isStatusHistory}
        eventKey="Note Overview"
        onToggle={() => {
          setIsOpen((o) => !o);
        }}
        isOpen={isOpen}
      >
        Note Overview
      </CustomToggle>

      <Accordion.Collapse eventKey="Note Overview" in={isOpen}>
        {isOpen && (
          <SOAPCard
            subjective={subjective}
            objective={objective}
            assessment={assessment}
            plan={plan}
            isVisibleHeader={false}
          />
        )}
      </Accordion.Collapse>
    </div>
  );
};

export default NoteOverview;
