import { Badge } from 'react-bootstrap';
import userStatuses from '../../constants/userStatuses';
import c from './UsersStatus.module.scss';

const statuses = [
  {
    id: userStatuses.ACTIVE,
    title: 'Active',
    bg: 'success',
  },
  {
    id: userStatuses.Inactive,
    title: 'Inactive',
    bg: 'danger',
  },
];

const UsersStatus = ({ status }) => {
  const currentStatus = statuses.find(({ id }) => id === status);
  return currentStatus === undefined ? (
    <Badge className={c.badge} bg="secondary">
      {status}
    </Badge>
  ) : (
    <Badge className={c.badge} bg={currentStatus.bg}>
      {currentStatus.title}
    </Badge>
  );
};

export default UsersStatus;
