import { Dropdown } from 'react-bootstrap';
import MenuItem from './MenuItem';
import { ReactComponent as ChevronDownIcon } from '../../assets/icons/chevron-down.svg';
import c from './Dropdown.module.scss';

export default function DD({ items, title, Icon, RightIcon = ChevronDownIcon }) {
  return (
    <Dropdown>
      <Dropdown.Toggle title={title} as={MenuItem} Icon={Icon} RightIcon={RightIcon} clickable />
      <Dropdown.Menu className={c.dropdownMenu}>
        {items.map(({ title: itemTitle, key, ...rest }, i) => (
          <Dropdown.Item {...rest} key={key} eventKey={i + 1}>
            {itemTitle}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
