import { Button } from 'react-bootstrap';
import format from 'date-fns/format';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import paths from '../../router/paths';
import { DATE_FORMAT } from '../../constants/date';
import { formatDurationSeconds } from '../../utils/helpers';
import OverviewCard from '../common/OverviewCard';
import useRemovePatient from '../../hooks/patiens/useRemovePatient';
import { showEditPatientModal } from './EditPatientModal';
import s from './PatientOverviewCard.module.scss';
import { dobToDate, customDateFormat } from '../../utils/date';

const getPatientRows = (patient, brief) =>
  [
    {
      label: 'Patient Number',
      text: patient?.patientNumber,
      brief: true,
    },
    {
      label: 'DOB',
      text: patient?.dob ? format(dobToDate(patient?.dob), DATE_FORMAT) : 'N/A',
      brief: true,
    },
    {
      label: 'Gender',
      text: patient?.gender,
      margin: true,
      brief: true,
    },
    {
      label: 'Time Documented',
      text: formatDurationSeconds(patient?.totalTimeDocumentedSeconds),
      brief: false,
    },
    {
      label: 'Number of Visits',
      text: patient?.totalVisits,
      brief: false,
    },
    {
      label: 'Date of Latest Visit',
      text: patient?.latestVisit ? (
        <Link
          to={generatePath(paths.visitOverview, { providerId: patient.providerId, visitId: patient.latestVisit.id })}
        >
          {customDateFormat(new Date(patient?.latestVisit.visitDate))}
        </Link>
      ) : (
        'N/A'
      ),
      margin: true,
      brief: false,
    },
  ].filter((item) => (brief ? item.brief : true));

export default function PatientOverviewCard({
  patient,
  patientId,
  providerId,
  loading,
  hideDeleteButton,
  hideEditButton,
  brief,
}) {
  const history = useHistory();
  const removePatient = useRemovePatient(
    { patientId, providerId },
    // eslint-disable-next-line comma-dangle
    { onCompleted: () => history.push(generatePath(paths.patients, { providerId })) }
  );

  return (
    <OverviewCard
      loading={loading}
      title={
        <>
          <b>{patient?.fullName}</b>
          {!hideEditButton ? (
            <Button
              onClick={() => {
                showEditPatientModal({ patientId, providerId });
              }}
              type="button"
              variant="link"
              size="sm"
              className="pb-1 pt-0 pe-1 ps-1"
            >
              <EditIcon className={s.editIcon} />
            </Button>
          ) : null}
        </>
      }
      subtitle={patient?.age ? `${patient.age} y.o` : null}
      rows={getPatientRows(patient, brief)}
      buttonBottom={
        !hideDeleteButton ? (
          <Button
            onClick={() => {
              removePatient();
            }}
            style={{ textDecoration: 'none', padding: '0px' }}
            variant="link"
          >
            Delete patient
          </Button>
        ) : null
      }
    />
  );
}
