import { gql } from '@apollo/client';

const scribesByProviderId = gql`
  query scribesByProviderId($providerId: ID!, $limit: Int!, $skip: Int!) {
    scribesByProviderId(providerId: $providerId, limit: $limit, skip: $skip) {
      count
      data {
        createdAt
        id
        user {
          id
          email
          displayName
          createdAt
        }
      }
    }
  }
`;

export default scribesByProviderId;
