import { toast } from 'react-toastify';
import Button from '../common/Button';
import useRemoveInvite from '../../hooks/users/useRemoveInvite';
import { confirm } from '../common/ConfirmAlert';

const RemoveInviteButton = ({ refetch, id }) => {
  const [removeInvite, { loading }] = useRemoveInvite({
    onCompleted: () => {
      refetch();
      toast.success('Invite successfully removed');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
  const onRemove = () => {
    removeInvite({ variables: { id } });
  };
  return (
    <Button
      loading={loading}
      style={{ textDecoration: 'none', padding: '0px' }}
      onClick={() => {
        confirm({
          title: 'Remove invite',
          text: 'Do you really want to remove this invite?',
          confirmButtonText: 'Remove',
          onConfirm: onRemove,
        });
      }}
      variant="link"
      size="sm"
      spinnerVariant="primary"
    >
      Remove
    </Button>
  );
};

export default RemoveInviteButton;
