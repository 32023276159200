import { gql } from '@apollo/client';

const VISIT_DETAILS_FRAGMENT = gql`
  fragment visitDetails on Visit {
    id
    providerId
    status
    reason
    complaint
    recordingsDurationSeconds
    soap {
      id
      subjective
      objective
      assessment
      plan
      amendments {
        id
        path
        recordingDate
        status
        transcriptError
        downloadRequest {
          uri
          expiresAt
        }
      }
    }
    visitDate
    recordingsNumber
    createdAt
    updatedAt

    recordings {
      id
      path
      recordingDate
      status
      transcriptError
      downloadRequest {
        uri
        expiresAt
      }
    }

    patient {
      id
      patientNumber
      fullName
      age
      dob
      gender
    }
    history {
      id
    }
  }
`;

export default VISIT_DETAILS_FRAGMENT;
