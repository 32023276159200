import { gql } from '@apollo/client';

const PATIENT_FOR_EDIT_FRAGMENT = gql`
  fragment patientForEdit on Patient {
    id
    firstName
    lastName
    patientNumber
    gender
    dob
  }
`;

export default PATIENT_FOR_EDIT_FRAGMENT;
