import { useMutation } from '@apollo/client';
import { confirm } from '../../components/common/ConfirmAlert';
import removePatientMutation from '../../graphql/mutations/removePatient';

const useRemovePatient = ({ patientId, providerId }, options) => {
  const [removePatient] = useMutation(removePatientMutation, {
    variables: { patientId, providerId },
    update: (cache) => {
      cache.evict({ id: `Patient:${patientId}` });
      cache.gc();
    },
    ...options,
  });
  const withConfirm = (...a) => {
    confirm({
      title: 'Confirm removal',
      text: 'Are you sure you want to delete this patient?',
      confirmButtonText: 'Delete',
      onConfirm: () => removePatient(...a),
    });
  };
  return withConfirm;
};

export default useRemovePatient;
