/* eslint-disable react/jsx-wrap-multilines */
import { Col, Row } from 'react-bootstrap';
import { generatePath, useParams } from 'react-router-dom';
import Page from '../../components/common/Page';
import paths from '../../router/paths';
import usePatientById from '../../hooks/common/usePatientById';
import Breadcrumb from '../../components/common/Breadcrumb';
// import PageLoading from '../../components/common/PageLoading';
// import ErrorAlert from '../../components/common/ErrorAlert';
import PatientOverviewCard from '../../components/patients/PatientOverviewCard';
import PatientVisitsCard from '../../components/patients/PatientVisitsCard';
import ErrorAlert from '../../components/common/ErrorAlert';

const PatientOverview = () => {
  const { providerId, patientId } = useParams();

  const { patient, loading, error: fetchError } = usePatientById({ providerId, patientId });

  const links = [
    {
      path: paths.providers,
      title: 'Providers',
    },
    {
      path: generatePath(paths.providerDashboard, { providerId }),
      title: patient?.provider.displayName || 'Provider',
    },
    {
      path: generatePath(paths.patients, { providerId }),
      title: 'Patients',
    },
  ];

  const notFoundError = !loading && !patient ? { message: 'Patient not found' } : null;
  const error = fetchError || notFoundError;
  return (
    <>
      <div className="mb-4">
        <Breadcrumb links={links} activeLinkTitle={patient?.fullName} />
      </div>
      <Row className="mb-4">
        <Col lg="4" sm="12">
          {error ? <ErrorAlert error={error} /> : null}
          <PatientOverviewCard {...{ patient, patientId, providerId, loading }} />
        </Col>
        <Col className="ml-5 mt-4 mt-md-0" lg="8" sm="12">
          <PatientVisitsCard {...{ patientId, providerId }} />
        </Col>
      </Row>
    </>
  );
};

export default function PatientOverviewPage() {
  return (
    <Page title="Patient Overview">
      <PatientOverview />
    </Page>
  );
}
