import { gql } from '@apollo/client';
import USER_TABLE_ITEM_FRAGMENT from '../fragments/userTableItem';

const assignRoleMutation = gql`
  mutation assignRole($role: Role!, $userId: ID!) {
    assignRole(role: $role, userId: $userId) {
      ...userTableItem
    }
  }
  ${USER_TABLE_ITEM_FRAGMENT}
`;

export default assignRoleMutation;
