import { gql } from '@apollo/client';

const REFRESH_RECORDING_STATUS_FRAGMENT = gql`
  fragment refreshRecordingStatus on Recording {
    id
    status
  }
`;

export default REFRESH_RECORDING_STATUS_FRAGMENT;
