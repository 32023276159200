import { gql } from '@apollo/client';
import ACTION_ITEMS_FRAGMENT from '../fragments/actionItems';

const actionItems = gql`
  query visits(
    $limit: Int!
    $skip: Int!
    $patientId: ID
    $providerId: ID
    $statuses: [VisitStatus]
    $orderBy: VisitOrderFields
    $orderDirection: OrderDirection
  ) {
    visits(
      limit: $limit
      skip: $skip
      patientId: $patientId
      providerId: $providerId
      statuses: $statuses
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      data {
        ...actionItems
      }
    }
  }
  ${ACTION_ITEMS_FRAGMENT}
`;

export default actionItems;
