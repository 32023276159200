import { gql } from '@apollo/client';

const inviteUserMutation = gql`
  mutation inviteUser($email: String!, $role: Role!) {
    inviteUser(email: $email, role: $role) {
      id
    }
  }
`;

export default inviteUserMutation;
