import faker from 'faker';

const actionItemsData = [
  {
    id: 1,
    provider: 'Provider 1',
    patient: 'Andrew Reynolds',
    status: 'Amend',
    lastUpdatedAt: faker.date.past(),
  },
  {
    id: 2,
    provider: 'Provider 2',
    patient: 'Rodney Mullen',
    status: 'Document',
    lastUpdatedAt: faker.date.past(),
  },
  {
    id: 3,
    provider: 'Provider 1',
    patient: 'Tony Hawk',
    status: 'Document',
    lastUpdatedAt: faker.date.past(),
  },
  {
    id: 4,
    provider: 'Provider 2',
    patient: 'Richie Jackson',
    status: 'Amend',
    lastUpdatedAt: faker.date.past(),
  },
  {
    id: 5,
    provider: 'Provider 3',
    patient: 'Andrew Reynolds',
    status: 'Amend',
    lastUpdatedAt: faker.date.past(),
  },
];

export default actionItemsData;
