import { Alert } from 'react-bootstrap';
import ErrorAlert from '../common/ErrorAlert';
import LoadingModal from '../common/LoadingModal';
import NoteTemplatesInfiniteScroll from './NoteTemplatesInfiniteScroll';

const NoteTemplatesModalBody = ({ loading, data, count, error, scrollableTarget, fetchMoreData, fill }) => (
  <>
    {loading && !data ? <LoadingModal marginTop={-40} /> : null}
    {error ? <ErrorAlert error={error} /> : null}
    {data && !error && data.length === 0 ? (
      <Alert className="mt-5" variant="info">
        No notes found
      </Alert>
    ) : null}
    {Array.isArray(data) ? (
      <NoteTemplatesInfiniteScroll
        data={data}
        count={count}
        fetchMoreData={fetchMoreData}
        scrollableTarget={scrollableTarget}
        isModal
        fill={fill}
      />
    ) : null}
  </>
);

export default NoteTemplatesModalBody;
