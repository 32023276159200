import { useField, useFormikContext } from 'formik';
import Flatpickr from 'react-flatpickr';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar-add.svg';
import s from './FormikFormDate.module.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/themes/light.css';

const FormikFormDate = ({ name, required, isEnableTimeSelect, ...rest }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext(name);
  const [field, meta] = useField(name);
  const { value } = field;

  const { error, touched } = meta;
  return (
    <>
      <Flatpickr
        className={s.inputDateWrapper}
        options={{
          dateFormat: isEnableTimeSelect ? 'm/d/Y h:i K' : 'm/d/Y',
          wrap: true,
          enableTime: isEnableTimeSelect,
          disableMobile: true,
        }}
        value={value}
        onChange={([date]) => {
          setFieldValue(name, date);
        }}
        onClose={() => {
          setFieldTouched(name);
        }}
        required={required}
        name={name}
        {...rest}
      >
        <input name={name} className={s.dateInput} type="text" data-input />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className={s.iconInputButton} title="toggle" data-toggle>
          <i className="icon-calendar">
            <CalendarIcon className={s.calendarIcon} />
          </i>
        </a>
      </Flatpickr>
      {touched && error && (
        <div>
          <small className="text-danger">{error}</small>
        </div>
      )}
    </>
  );
};

export default FormikFormDate;
