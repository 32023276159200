import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import useMeQuery from '../hooks/auth/useMeQuery';
import Authenticator from '../pages/auth/Authenticator';
import paths from './paths';
import Logout from '../pages/auth/Logout';
import AuthLayout from '../components/layout/AuthLayout';
import usePersistUserRole from '../hooks/auth/usePersistUserRole';
import FullscreenLoading from '../components/common/FullscreenLoading';
import { CurrentProviderContextProvider } from '../modules/currentProvider/context';
import MsalAuthWrapper from '../components/auth/MsalAuthWrapper';

import roles from '../constants/roles';
import ScribeRouter from './ScribeRouter';
import AdminRouter from './AdminRouter';
import ResetPassword from '../pages/auth/ResetPassword';

const RootRouter = () => {
  const [currentUser, loading] = useMeQuery();
  usePersistUserRole();

  if (loading) {
    return <FullscreenLoading />;
  }
  return (
    <Router>
      <Switch>
        <Route path="/secure">
          <MsalAuthWrapper>
            {currentUser?.role === roles.SCRIBE ? (
              <CurrentProviderContextProvider>
                <ScribeRouter />
              </CurrentProviderContextProvider>
            ) : null}
            {currentUser?.role === roles.ADMIN ? <AdminRouter /> : null}
            {!currentUser ? <Redirect to={paths.logout} /> : null}
          </MsalAuthWrapper>
        </Route>
        <Route>
          <AuthLayout>
            <Switch>
              <Route path={paths.authenticator} exact component={Authenticator} />
              <Route path={paths.logout} exact component={Logout} />
              <Route path={paths.resetPassword} exact component={ResetPassword} />
              <Redirect from={paths.home} to={paths.authenticator} exact />
            </Switch>
          </AuthLayout>
        </Route>
      </Switch>
    </Router>
  );
};

export default RootRouter;
