import { useMutation } from '@apollo/client';
import { confirm } from '../../components/common/ConfirmAlert';
import removeNoteTemplateMutation from '../../graphql/mutations/removeNoteTemplate';

const useRemoveNoteTemplate = ({ id }, options) => {
  const [removeNoteTemplate] = useMutation(removeNoteTemplateMutation, {
    variables: { id },
    update: (cache) => {
      cache.evict({ id: `NoteTemplate:${id}` });
      cache.gc();
    },
    ...options,
  });
  const withConfirm = (...a) => {
    confirm({
      title: 'Confirm removal',
      text: 'Are you sure you want to delete this note template?',
      confirmButtonText: 'Delete',
      onConfirm: () => removeNoteTemplate(...a),
    });
  };
  return withConfirm;
};

export default useRemoveNoteTemplate;
