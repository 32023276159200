import { useEffect, useRef } from 'react';

const useCopyToClipboard = (textToCopy, onSuccess, onError) => {
  const clickRef = useRef();

  const propsRef = useRef({
    onSuccess,
    onError,
    textToCopy,
  });
  useEffect(() => {
    propsRef.current = { textToCopy, onSuccess, onError };
  }, [onError, onSuccess, textToCopy]);

  useEffect(() => {
    const copyHandler = () => {
      if (!navigator.clipboard) {
        return;
      }

      navigator.clipboard
        .writeText(propsRef.current.textToCopy)
        .then(() => {
          if (typeof propsRef.current.onSuccess === 'function') propsRef.current.onSuccess();
        })
        .catch(() => {
          if (typeof propsRef.current.onError === 'function') propsRef.current.onError();
        });
    };
    if (clickRef.current) {
      clickRef.current.addEventListener('click', copyHandler);
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clickRef.current?.removeEventListener('click', copyHandler);
    };
  }, []);

  return clickRef;
};

export default useCopyToClipboard;
